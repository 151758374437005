import React, {useState} from "react"
import {Row, Col, Card, Alert, Spinner, InputGroup, Form} from "react-bootstrap"

const SearchInput = (props) => {
  const [searchInput, setSearchInput] = useState("")

  return (
    <span className="d-flex align-items-center">
      <InputGroup
        style={{
          display: "flex",
          border: "1px solid #00000050",
          borderRadius: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Form.Control
          placeholder={props.placeholder || "Search...."}
          aria-label={props.placeholder || "Search...."}
          aria-describedby="basic-addon2"
          style={{border: 0}}
          onChange={(e) => {
            setSearchInput(e.target.value)
          }}
          id="search"
          value={searchInput}
          onKeyDown={(event) =>
            event.key === "Enter" && props.onClick(1, {search: searchInput})
          }
        ></Form.Control>
        <div
          style={{
            cursor: "pointer",
            marginRight: 10,
            paddingLeft: 10,
          }}
          onClick={() => {
            setSearchInput("")
            props.onClick(1, {search: ""})
          }}
        >
          <i className="mdi mr-1  dripicons-cross"></i>
        </div>
        <button
          className="btn btn-block"
          style={{backgroundColor: "#2A882A", color: "white"}}
          onClick={() => props.onClick(1, {search: searchInput})}
        >
          <i className="mdi mr-1"></i> Search
        </button>
      </InputGroup>
    </span>
  )
}

export default SearchInput
