// @flow
import React from "react"
import { Card, Table } from "react-bootstrap"
import classNames from "classnames"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import _ from "lodash"

const DoorstepRequestAcceptedTable = (props) => {
  const navigate = useNavigate()

  const StatusColumn = (status) => {
    return (
      <>
        <span
          className={
            status === "OPEN" ? "bg-success badge" : "bg-secondary badge"
          }
        >
          {status === "CLOSE" ? "Closed" : "Open"}
        </span>
      </>
    )
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="">{props.name}</h4>
          {!_.isEmpty(props?.data) && (
            <Table
              responsive
              classNames="table table-borderless table-nowrap mb-0"
            >
              <thead classNames="table-light">
                <tr>
                  <th>#</th>
                  <th>Partner Name</th>
                  <th>Phone</th>
                  <th>DoorStep Active</th>
                  <th>Seen</th>
                  <th>Distance</th>
                  {/* <th>Honc Price</th>
                  <th>Partner Price</th> */}
                  {<th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((item, index) => {
                  let km = item?.distance / 1000
                  return (
                    <tr key={index} style={item?.agencyId?.ownerId?._id == props?.activePartner?._id ? {
                      backgroundColor: '#4BB54320'
                    } : {}} onClick={() => { }}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/partners/partner-profile",
                            search: `?id=${item?.agencyId?.ownerId?._id}`,
                          }}
                          className="font-weight-bold"
                          target="_blank"
                        >
                          {item?.agencyId?.ownerId?.name}
                          {item?.agencyId?.ownerId?._id == props?.activePartner?._id ?
                            <span className="badge bg-success ms-1">
                              assigned
                            </span>
                            :
                            null
                          }
                        </Link>
                      </td>
                      <td>{item?.agencyId?.ownerId?.phone}</td>
                      <td>
                        {item?.agencyId?.isDoorStepOpen ?
                          <i className="mdi mdi-check-circle text-success font-16"></i>
                          :
                          <i className="mdi mdi-close-circle text-danger font-16"></i>
                        }
                      </td>
                      <td>
                        {item?.isSeen ? (
                          <i className="mdi mdi-check-circle text-success font-16"></i>
                        ) : (
                          <i className="mdi mdi-close-circle text-danger font-16"></i>
                        )}
                      </td>
                      <td>{km?`${km.toFixed(1) + "KM"}`:''}</td>
                      {/* <td>
                        <strong>₹{item?.cost}</strong>
                      </td>
                      <td>
                        <strong>₹{item?.partner_cost}</strong>
                      </td> */}
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            props.updateStatus(item._id, "completed")
                          }
                        >
                          Assign
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() =>
                              props.updateStatus(item._id, "rejected")
                            }
                          >
                            Reject
                          </button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default DoorstepRequestAcceptedTable
