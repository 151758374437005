// @flow
import {AuthActionTypes} from "./constants"

import {APICore} from "../../helpers/api/apiCore"

const api = new APICore()

let userInfo = sessionStorage.getItem(AuthActionTypes.LOGIN_USER_DETAILS)
userInfo = JSON.parse(userInfo)

const INIT_STATE = {
  user: userInfo,
  loading: false,
}

// type AuthAction = {
//   type: string,
//   payload: {actionType?: string, data?: any, error?: string},
// }
// type State = {user?: {} | null, loading?: boolean, +value?: boolean}

const Auth = (state = INIT_STATE, action: AuthAction): any => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        user: action.payload,
        loading: false,
      }
    case "logout":
      console.log("called logout")
      return {
        ...state,
        user: null,
        loading: false,
        userLogout: true,
      }
    default:
      return state
  }
  // switch (action.type) {
  //   case AuthActionTypes.API_RESPONSE_SUCCESS:
  //     switch (action.payload.actionType) {
  //       case AuthActionTypes.LOGIN_USER: {
  //         return {
  //           ...state,
  //           user: action.payload.data,
  //           userLoggedIn: true,
  //           loading: false,
  //         }
  //       }
  //       case AuthActionTypes.SIGNUP_USER: {
  //         return {
  //           ...state,
  //           loading: false,
  //           userSignUp: true,
  //         }
  //       }
  //       case AuthActionTypes.LOGOUT_USER: {
  //         return {
  //           ...state,
  //           user: null,
  //           loading: false,
  //           userLogout: true,
  //         }
  //       }
  //       case AuthActionTypes.FORGOT_PASSWORD: {
  //         return {
  //           ...state,
  //           resetPasswordSuccess: action.payload.data,
  //           loading: false,
  //           passwordReset: true,
  //         }
  //       }
  //       case AuthActionTypes.FORGOT_PASSWORD_CHANGE: {
  //         return {
  //           ...state,
  //           loading: false,
  //           passwordChange: true,
  //         }
  //       }
  //       case "login": {
  //         return {
  //           ...state,
  //           loading: false,
  //           userLoggedIn: true,
  //           user:action.payload
  //         }
  //       default:
  //         return {...state}
  //     }

  //   case AuthActionTypes.API_RESPONSE_ERROR:
  //     switch (action.payload.actionType) {
  //       case AuthActionTypes.LOGIN_USER: {
  //         return {
  //           ...state,
  //           error: action.payload.error,
  //           userLoggedIn: false,
  //           loading: false,
  //         }
  //       }
  //       case AuthActionTypes.SIGNUP_USER: {
  //         return {
  //           ...state,
  //           registerError: action.payload.error,
  //           userSignUp: false,
  //           loading: false,
  //         }
  //       }
  //       case AuthActionTypes.FORGOT_PASSWORD: {
  //         return {
  //           ...state,
  //           error: action.payload.error,
  //           loading: false,
  //           passwordReset: false,
  //         }
  //       }
  //       case AuthActionTypes.FORGOT_PASSWORD_CHANGE: {
  //         return {
  //           ...state,
  //           error: action.payload.error,
  //           loading: false,
  //           passwordChange: false,
  //         }
  //       }
  //       default:
  //         return {...state}
  //     }

  //   case AuthActionTypes.USER_LOGGED_IN_SUCCESS: {
  //     return {
  //       ...state,
  //       user: action.payload.data,
  //     }
  //   }

  //   case AuthActionTypes.LOGIN_USER:
  //     return {...state, loading: true, userLoggedIn: false}
  //   case AuthActionTypes.LOGOUT_USER:
  //     return {...state, loading: true, userLogout: false}
  //   case AuthActionTypes.SIGNUP_USER:
  //     return {...state, loading: true, userSignUp: false}
  //   case AuthActionTypes.FORGOT_PASSWORD:
  //     return {...state, loading: true, passwordReset: false}
  //   case AuthActionTypes.FORGOT_PASSWORD_CHANGE:
  //     return {...state, loading: true, passwordChange: false}
  //   case AuthActionTypes.RESET:
  //     return {
  //       ...state,
  //       loading: false,
  //       error: false,
  //       userSignUp: false,
  //       userLoggedIn: false,
  //       passwordReset: false,
  //       passwordChange: false,
  //       resetPasswordSuccess: null,
  //     }
  //   default:
  //     return {...state}
  // }
}

export const getUser = (state) => state.user

export default Auth
