import swal from "sweetalert"
import {AppStateConstants} from "./appConstants"

export const setClosePageCount = (closedTicketCount) => {
  return {
    type: AppStateConstants.CLOSED_TICKET_PAGE_COUNT,
    closedTicketCount: closedTicketCount,
  }
}

export const setOpenPageCount = (openTicketPageCount) => {
  return {
    type: AppStateConstants.OPEN_TICKET_PAGE_COUNT,
    openTicketPageCount: openTicketPageCount,
  }
}

export const setOndemandServicePackagePageSize = (
  onDemandServicePackagePageSize
) => {
  return {
    type: AppStateConstants.ON_DEMAND_SERVICE_PACKAGE_PAGE_SIZE,
    onDemandServicePackagePageSize: onDemandServicePackagePageSize,
  }
}
export const setAllUsersPageSize = (allUsersPageSize) => {
  return {
    type: AppStateConstants.ALL_USERS_PAGE_SIZE,
    allUsersPageSize: allUsersPageSize,
  }
}
export const setOpenTicketsPageSize = (openTicketPageSize) => {
  return {
    type: AppStateConstants.OPEN_TICKET_PAGE_SIZE,
    openTicketPageSize: openTicketPageSize,
  }
}
export const setHoldTicketsPageSize = (holdTicketPageSize) => {
  return {
    type: AppStateConstants.HOLD_TICKET_PAGE_SIZE,
    holdTicketPageSize: holdTicketPageSize,
  }
}
export const setClosedTicketPageSize = (closedTicketPageSize) => {
  return {
    type: AppStateConstants.CLOSED_TICKET_PAGE_SIZE,
    closedTicketPageSize: closedTicketPageSize,
  }
}

export const setDailyCleaningJobsPageSize = (dailyCleaningJobsPageSize) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_JOBS_PAGE_SIZE,
    dailyCleaningJobsPageSize: dailyCleaningJobsPageSize,
  }
}
export const setServicePlacesPageSize = (servicePlacesPageSize) => {
  return {
    type: AppStateConstants.SERVICE_PLACES_PAGE_SIZE,
    servicePlacesPageSize: servicePlacesPageSize,
  }
}
export const setServicePlacesLocationPageSize = (
  servicePlacesLocationPageSize
) => {
  return {
    type: AppStateConstants.SERVICE_PLACES_LOCATIONS_PAGE_SIZE,
    servicePlacesLocationPageSize: servicePlacesLocationPageSize,
  }
}
export const setOnDemandJobsPageSize = (onDemnadJobsPageSize) => {
  return {
    type: AppStateConstants.ON_DEMAND_JOBS_PAGE_SIZE,
    onDemnadJobsPageSize: onDemnadJobsPageSize,
  }
}
export const setPendingJobsPageSize = (pendingJobsPageSize) => {
  return {
    type: AppStateConstants.PENDING_JOBS_PAGE_SIZE,
    pendingJobsPageSize: pendingJobsPageSize,
  }
}
export const setReportedJobsPageSize = (reportedJobsPageSize) => {
  return {
    type: AppStateConstants.REPORTED_JOBS_PAGE_SIZE,
    reportedJobsPageSize: reportedJobsPageSize,
  }
}
export const setComplaintJobsPageSize = (complaintJobsPageSize) => {
  return {
    type: AppStateConstants.COMPLAINT_JOBS_PAGE_SIZE,
    complaintJobsPageSize: complaintJobsPageSize,
  }
}
export const setPartnerAttendancePageSize = (partnerAttendancePageSize) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_PAGE_SIZE,
    partnerAttendancePageSize: partnerAttendancePageSize,
  }
}
export const setAllpartnersPageSize = (allPartnersPageSize) => {
  return {
    type: AppStateConstants.ALL_PARTNERS_PAGE_SIZE,
    allPartnersPageSize: allPartnersPageSize,
  }
}

export const setAllpackagesPageSize = (allPackagesPageSize) => {
  return {
    type: AppStateConstants.ALL_PACKAGES_PAGE_SIZE,
    allPackagesPageSize: allPackagesPageSize,
  }
}
export const setOpenedCallBackRequestsTicketsPageNumber = (
  openedCallBackRequestTicketPageNumber
) => {
  return {
    type: AppStateConstants.OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER,
    openedCallBackRequestTicketPageNumber:
      openedCallBackRequestTicketPageNumber,
  }
}
export const setOpenedResolutionTicketsPageNumber = (
  openedResolutionTicketPageNumber
) => {
  return {
    type: AppStateConstants.OPENED_RESOLUTION_TICKET_PAGE_NUMBER,
    openedResolutionTicketPageNumber:
      openedResolutionTicketPageNumber,
  }
}
export const setOpenedReportedTicketsPageNumber = (
  openedReportedTicketsPageNumber
) => {
  return {
    type: AppStateConstants.OPENED_REPORTED_TICKET_PAGE_NUMBER,
    openedReportedTicketsPageNumber:
      openedReportedTicketsPageNumber,
  }
}
export const setFilterCallBackRequestTicketData = (
  filterCallBackRequestTicketData
) => {
  return {
    type: AppStateConstants.FILTER_CALL_BACK_REQUEST_TICKET_DATA,
    filterCallBackRequestTicketData:
      filterCallBackRequestTicketData,
  }
}
export const setFilterResolutionTicketData = (
  filterResolutionTicketData
) => {
  return {
    type: AppStateConstants.FILTER_RESOLUTION_TICKET_DATA,
    filterResolutionTicketData:
      filterResolutionTicketData,
  }
}
export const setFilterReportedTicketData = (
  filterReportedTicketData
) => {
  return {
    type: AppStateConstants.FILTER_REPORTED_TICKET_DATA,
    filterReportedTicketData:
      filterReportedTicketData,
  }
}
export const setCallBackRequestTicketSelectedTab = (
  callBackRequestTicketSelectedTab
) => {
  return {
    type: AppStateConstants.CALL_BACK_REQUEST_TICKET_SELECTED_TAB,
    callBackRequestTicketSelectedTab:
      callBackRequestTicketSelectedTab,
  }
}
export const setReportedTicketSelectedTab = (
  reportedTicketSelectedTab
) => {
  return {
    type: AppStateConstants.REPORTED_TICKET_SELECTED_TAB,
    reportedTicketSelectedTab:
      reportedTicketSelectedTab,
  }
}
export const setResolutionTicketSelectedTab = (
  resolutionTicketSelectedTab
) => {
  return {
    type: AppStateConstants.RESOLUTION_TICKET_SELECTED_TAB,
    resolutionTicketSelectedTab:
      resolutionTicketSelectedTab,
  }
}
export const setResolutionTicketPageSize = (
  resolutionTicketPageSize
) => {
  return {
    type: AppStateConstants.RESOLUTION_TICKET_PAGE_SIZE,
    resolutionTicketPageSize:
      resolutionTicketPageSize,
  }
}
export const setReportedTicketPageSize = (
  reportedTicketPageSize
) => {
  return {
    type: AppStateConstants.REPORTED_TICKET_PAGE_SIZE,
    reportedTicketPageSize:
      reportedTicketPageSize,
  }
}

export const setCallBackRequestTicketPageSize = (
  callBackRequestTicketPageSize
) => {
  return {
    type: AppStateConstants.CALL_BACK_REQUEST_TICKET_PAGE_SIZE,
    callBackRequestTicketPageSize:
      callBackRequestTicketPageSize,
  }
}

export const setClosedResolutionTicketsPageNumber = (
  closedResolutionTicketPageNumber
) => {
  return {
    type: AppStateConstants.CLOSED_RESOLUTION_TICKET_PAGE_NUMBER,
    closedResolutionTicketPageNumber:
      closedResolutionTicketPageNumber,
  }
}

export const setClosedReportedTicketsPageNumber = (
  closedReportedTicketPageNumber
) => {
  return {
    type: AppStateConstants.CLOSED_REPORTED_TICKET_PAGE_NUMBER,
    closedReportedTicketPageNumber:
      closedReportedTicketPageNumber,
  }
}
export const setClosedCallBackRequestsTicketsPageNumber = (
  closedCallBackRequestTicketPageNumber
) => {
  return {
    type: AppStateConstants.CLOSED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER,
    closedCallBackRequestTicketPageNumber:
      closedCallBackRequestTicketPageNumber,
  }
}
export const setHoldResolutionTicketPageNumber = (
  holdResolutionTicketPageNumber
) => {
  return {
    type: AppStateConstants.HOLD_RESOLUTION_TICKET_PAGE_NUMBER,
    holdResolutionTicketPageNumber:
      holdResolutionTicketPageNumber,
  }
}


export const setHoldCallBackRequestsTicketsPageNumber = (
  holdCallBackRequestTicketPageNumber
) => {
  return {
    type: AppStateConstants.HOLD_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER,
    holdCallBackRequestTicketPageNumber:
      holdCallBackRequestTicketPageNumber,
  }
}
export const setPartnerRatingsPageSize = (partnerRatingsPageSize) => {
  return {
    type: AppStateConstants.PARTNERS_RATING_PAGE_SIZE,
    partnerRatingsPageSize: partnerRatingsPageSize,
  }
}
export const setPartnerTipsPageSize = (partnerTipsPageSize) => {
  return {
    type: AppStateConstants.PARTNER_TIPS_PAGE_SIZE,
    partnerTipsPageSize: partnerTipsPageSize,
  }
}
export const setAssignedBookingsPageSize = (assignedBookingsPageSize) => {
  return {
    type: AppStateConstants.ASSIGNED_BOOKINGS_PAGE_SIZE,
    assignedBookingsPageSize: assignedBookingsPageSize,
  }
}

export const setDailyCleaningInactiveBookingsPageSize = (
  dailycleaningInactiveBookingsPageSize
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE,
    dailycleaningInactiveBookingsPageSize:
      dailycleaningInactiveBookingsPageSize,
  }
}
export const setDailyCleaningPaymentPendingBookingPageSize = (
  dailycleaningPaymentPendingBookingsPageSize
 ) => {
   return {
     type: AppStateConstants.DAILY_CLEANING_PAYMENT_PENDING_BOOKINGS_PAGE_SIZE,
    dailycleaningPaymentPendingBookingsPageSize:
      dailycleaningPaymentPendingBookingsPageSize,
   }
 }
export const setDailyCleaningCancelledBookingsPagesize = (
  dailyCleaningCancelledBookingsPageSize
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_CANCELLED_BOOKINGS_PAGE_SIZE,
    dailyCleaningCancelledBookingsPageSize:
      dailyCleaningCancelledBookingsPageSize,
  }
}
export const setOnDemandUnAssignedBookingPageSize = (
  onDemandUnAssignedBookingPageSize
) => {
  return {
    type: AppStateConstants.ON_DEMAND_UNASSIGNED_BOOKING_PAGE_SIZE,
    onDemandUnAssignedBookingPageSize: onDemandUnAssignedBookingPageSize,
  }
}
export const setDailyCleaningUnAssignedBookingPageSize = (
  dailyCleaningUnAssignedBookingPageSize
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_UNASSIGNED_BOOKING_PAGE_SIZE,
    dailyCleaningUnAssignedBookingPageSize: dailyCleaningUnAssignedBookingPageSize,
  }
}
export const setOnDemandAssignedBookingsPageSize = (
  onDemandAssignedBookingsPageSize
) => {
  return {
    type: AppStateConstants.ON_DEMNAD_ASSIGNED_BOOKINGS_PAGE_SIZE,
    onDemandAssignedBookingsPageSize: onDemandAssignedBookingsPageSize,
  }
}
export const setOnDemandCompletedBookingsPageSize = (
  onDemandCompletedBookingsPageSize
) => {
  return {
    type: AppStateConstants.ON_DEMAND_COMPLETED_BOOKINGS_PAGE_SIZE,
    onDemandCompletedBookingsPageSize: onDemandCompletedBookingsPageSize,
  }
}
export const setOnDemandCancelledBookingsPageSize = (
  onDemandCancelledBookingsPageSize
) => {
  return {
    type: AppStateConstants.ON_DEMAND_CANCELLED_BOOKINGS_PAGE_SIZE,
    onDemandCancelledBookingsPageSize: onDemandCancelledBookingsPageSize,
  }
}

export const setHoldPageCount = (holdTicketPageCount) => {
  return {
    type: AppStateConstants.HOLD_TICKET_PAGE_COUNT,
    holdTicketPageCount: holdTicketPageCount,
  }
}

export const setOnDemandNewBookingPageCount = (onDemandNewBookingPageCount) => {
  return {
    type: AppStateConstants.ON_DEMAND_NEW_BOOKING_PAGE_COUNT,
    onDemandNewBookingPageCount: onDemandNewBookingPageCount,
  }
}

export const setOnDemandUnAssignedBookingPageCount = (
  onDemandUnAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandUnAssignedBookingPageCount: onDemandUnAssignedBookingPageCount,
  }
}
export const setDailyCleaningUnAssignedBookingPageCount = (
  dailyCleaningUnAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_UN_ASSIGNED_BOOKING_PAGE_COUNT,
    dailyCleaningUnAssignedBookingPageCount:  dailyCleaningUnAssignedBookingPageCount,
  }
}

export const setOnDemandAssignedBookingPageCount = (
  onDemandAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandAssignedBookingPageCount: onDemandAssignedBookingPageCount,
  }
}

export const setOnDemandNotAssignedBookingPageCount = (
  onDemandNotAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandNotAssignedBookingPageCount: onDemandNotAssignedBookingPageCount,
  }
}

export const setOnDemandInactiveBookingPageCount = (
  onDemandInactiveBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT,
    onDemandInactiveBookingPageCount: onDemandInactiveBookingPageCount,
  }
}

export const setOnDemandCanceledBookingPageCount = (
  onDemandCanceledBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT,
    onDemandCanceledBookingPageCount: onDemandCanceledBookingPageCount,
  }
}

export const setDailyCleaningNewBookingPageCount = (
  dailyCleaningNewBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT,
    dailyCleaningNewBookingPageCount: dailyCleaningNewBookingPageCount,
  }
}

export const setDailyCleaningAssignedBookingPageCount = (
  dailyCleaningAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT,
    dailyCleaningAssignedBookingPageCount:
      dailyCleaningAssignedBookingPageCount,
  }
}

export const setDailyCleaningInactiveBookingPageCount = (
  dailyCleaningInactiveBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT,
    dailyCleaningInactiveBookingPageCount:
      dailyCleaningInactiveBookingPageCount,
  }
}

export const setAllSpotBookingsPageSize = (
  allSpotBookingsPageSize
) => {
  return {
    type: AppStateConstants.ALL_SPOT_BOOKINGS_PAGE_SIZE,
    allSpotBookingsPageSize:
      allSpotBookingsPageSize,
  }
}
export const setAllSpotBookingsPageNumber = (
  allSpotBookingsPageNumber
) => {
  return {
    type: AppStateConstants.ALL_SPOT_BOOKINGS_PAGE_NUMBER,
    allSpotBookingsPageNumber:
      allSpotBookingsPageNumber,
  }
}

export const setDailyCleaningPaymentPendingBookingPageCount = (
 dailyCleaningPaymentPendingBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_PAYMENT_PENDING_BOOKING_PAGE_COUNT,
   dailyCleaningPaymentPendingBookingPageCount:
     dailyCleaningPaymentPendingBookingPageCount,
  }
}

export const setAllUsersPageCount = (allUsersPageCount) => {
  return {
    type: AppStateConstants.ALL_USERS_PAGE_COUNT,
    allUsersPageCount: allUsersPageCount,
  }
}
export const setNotAttemptedJobsPageNumber = (notattemptedJobsPageNumber) => {
  return {
    type: AppStateConstants.NOT_ATTEMPTED_JOBS_PAGE_NUMBER,
    notattemptedJobsPageNumber: notattemptedJobsPageNumber,
  }
}
export const setUserdetailsBookingsPageSize = (userDetailsBookingsPageSize) => {
  return {
    type: AppStateConstants.USER_DETAILS_BOOKINGS_PAGE_SIZE,
    userDetailsBookingsPageSize: userDetailsBookingsPageSize,
  }
}
export const setUserdetailsPaymentsPageSize = (userDetailsPaymentsPageSize) => {
  return {
    type: AppStateConstants.USER_DETAILS_PAYMENTS_PAGE_SIZE,
    userDetailsPaymentsPageSize: userDetailsPaymentsPageSize,
  }
}
export const setUserWalletCardDetailsPageSize = (
  userWalletCardDetailsPageSize
) => {
  return {
    type: AppStateConstants.USER_WALLET_CARD_DETAILS_PAGE_SIZE,
    userWalletCardDetailsPageSize: userWalletCardDetailsPageSize,
  }
}
export const setRatingByUsersPageSize = (ratingByUsersPageSize) => {
  return {
    type: AppStateConstants.RATING_BY_USER_PAGE_SIZE,
    ratingByUsersPageSize: ratingByUsersPageSize,
  }
}
export const setReferralsPageSize = (referralsPageSize) => {
  return {
    type: AppStateConstants.REFERRALS_PAGE_SIZE,
    referralsPageSize: referralsPageSize,
  }
}
export const setAllPartnersPageCount = (allPartnersPageCount) => {
  return {
    type: AppStateConstants.ALL_PARTNERS_PAGE_COUNT,
    allPartnersPageCount: allPartnersPageCount,
  }
}

export const setAllPackagesPageCount = (allPackagesPageCount) => {
  return {
    type: AppStateConstants.ALL_PACKAGES_PAGE_COUNT,
    allPackagesPageCount: allPackagesPageCount,
  }
}
export const setAllUserReferralPageCount = (allUserReferralPageCount) => {
  return {
    type: AppStateConstants.ALL_USER_REFERRAL_PAGE_COUNT,
    allUserReferralPageCount: allUserReferralPageCount,
  }
}
export const setAllUserReferralPageNumber = (allUserReferralPageNumber) => {
  return {
    type: AppStateConstants.ALL_USER_REFERRAL_PAGE_NUMBER,
    allUserReferralPageNumber: allUserReferralPageNumber,
  }
}

export const setNotAttemtedDailyCleaningJobPageNumber = (
  notAttemtedDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER,
    notAttemtedDailyCleaningJobPageNumber:
      notAttemtedDailyCleaningJobPageNumber,
  }
}

export const setCompletedDailyCleaningJobPageNumber = (
  completedDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER,
    completedDailyCleaningJobPageNumber: completedDailyCleaningJobPageNumber,
  }
}

export const setApprovalPendingDailyCleaningJobPageNumber = (
  approvalPendingDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER,
    approvalPendingDailyCleaningJobPageNumber:
      approvalPendingDailyCleaningJobPageNumber,
  }
}

export const setNotCleanDailyCleaningJobPageNumber = (
  notCleanDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER,
    notCleanDailyCleaningJobPageNumber: notCleanDailyCleaningJobPageNumber,
  }
}

export const setNoVehicleDailyCleaningPageNumber = (
  noVehicleDailyCleaningPageNumber
) => {
  return {
    type: AppStateConstants.NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER,
    noVehicleDailyCleaningPageNumber: noVehicleDailyCleaningPageNumber,
  }
}

export const setScheduledOnDemandJobPageNumber = (
  scheduledOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER,
    scheduledOnDemandJobPageNumber: scheduledOnDemandJobPageNumber,
  }
}

export const setStartedOnDemandJobPageNumber = (
  startedOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.STARTED_ON_DEMAND_JOB_PAGE_NUMBER,
    startedOnDemandJobPageNumber: startedOnDemandJobPageNumber,
  }
}

export const setCompletedOnDemandJobPageNumber = (
  completedOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER,
    completedOnDemandJobPageNumber: completedOnDemandJobPageNumber,
  }
}

export const setApprovalPendingOnDemandJobPageNumber = (
  approvalPendingOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER,
    approvalPendingOnDemandJobPageNumber: approvalPendingOnDemandJobPageNumber,
  }
}

export const setNotCleanOnDemandJobPageNumber = (
  notCleanOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER,
    notCleanOnDemandJobPageNumber: notCleanOnDemandJobPageNumber,
  }
}

export const setNoVehicleOnDemandPageNumber = (noVehicleOnDemandPageNumber) => {
  return {
    type: AppStateConstants.NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER,
    noVehicleOnDemandPageNumber: noVehicleOnDemandPageNumber,
  }
}

export const setLoggedInUserDetails = (logged_in_user_details) => {
  return {
    type: AppStateConstants.LOGGED_IN_USER_DETAILS,
    logged_in_user_details: logged_in_user_details,
  }
}

export const LogoutUser = () => {
  localStorage.clear()
  swal(
    "Session Expired!",
    "your session has been expired, please re-login. ",
    "warning"
  )
  setTimeout(() => {
    window.location.href = "/account/login"
    return {
      type: AppStateConstants.LOGOUT_USER,
    }
  }, 3000)
}

export const setPartnerAttendenceStartedPageNumber = (
  partnerAttendenceStartedPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER,
    partnerAttendenceStartedPageNumber: partnerAttendenceStartedPageNumber,
  }
}

export const setPartnerAttendencePresentPageNumber = (
  partnerAttendencePresentPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER,
    partnerAttendencePresentPageNumber: partnerAttendencePresentPageNumber,
  }
}

export const setPartnerAttendenceAbsentPageNumber = (
  partnerAttendenceAbsentPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER,
    partnerAttendenceAbsentPageNumber: partnerAttendenceAbsentPageNumber,
  }
}
export const setPartnerInactivePageNumber = (PartnerInactivePageNumber) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_INACTIVE_PAGE_NUMBER,
    partnerInactivePageNumber: PartnerInactivePageNumber,
  }
}

export const setPartnerAttendenceSelectedTab = (
  partnerAttendenceSelectedTab
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDENCE_SELECTED_TAB,
    partnerAttendenceSelectedTab: partnerAttendenceSelectedTab,
  }
}

export const setApartmentDetailsSelectedTab = (apartmentDetailsSelectedTab) => {
  return {
    type: AppStateConstants.APARTMENT_DETAILS_SELECTED_TAB,
    apartmentDetailsSelectedTab: apartmentDetailsSelectedTab,
  }
}
export const setDailyCleaningJobSelectedTab = (dailyCleaningJobSelectedTab) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_JOB_SELECTED_TAB,
    dailyCleaningJobSelectedTab: dailyCleaningJobSelectedTab,
  }
}

export const setOnDemandJobSelectedTab = (onDemandJobSelectedTab) => {
  return {
    type: AppStateConstants.ON_DEMAND_JOB_SELECTED_TAB,
    onDemandJobSelectedTab: onDemandJobSelectedTab,
  }
}

export const setReportedDamagePageNumber = (reportedDamagePageNumber) => {
  return {
    type: AppStateConstants.REPORTED_DAMAGE_PAGE_NUMBER,
    reportedDamagePageNumber: reportedDamagePageNumber,
  }
}

export const setReportedJobsApprovedPageNumber = (
  reportedJobsApprovedPageNumber
) => {
  return {
    type: AppStateConstants.REPORTED_JOBS_APPROVED_PAGE_NUMBER,
    reportedJobsApprovedPageNumber: reportedJobsApprovedPageNumber,
  }
}

export const setReportedJobsNotApprovedPageNumber = (
  reportedJobsNotApprovedPageNumber
) => {
  return {
    type: AppStateConstants.REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER,
    reportedJobsNotApprovedPageNumber: reportedJobsNotApprovedPageNumber,
  }
}

export const setComplaintPageNumber = (complaintJobsPageNumber) => {
  return {
    type: AppStateConstants.COMPLAINT_JOBS_PAGE_NUMBER,
    complaintJobsPageNumber: complaintJobsPageNumber,
  }
}

export const setServicePlacesPageNumber = (servicePlacesPageNumber) => {
  return {
    type: AppStateConstants.SERVICE_PLACES_PAGE_NUMBER,
    servicePlacesPageNumber: servicePlacesPageNumber,
  }
}

export const setCarBrandsPageNumber = (carBrandsPageNumber) => {
  return {
    type: AppStateConstants.SET_CAR_BRANDS_PAGE_NUMBER,
    carBrandsPageNumber: carBrandsPageNumber,
  }
}

export const setAutodebitPageNumber = (autodebitPageNumber) => {
  return {
    type: AppStateConstants.SET_AUTO_DEBITS_PAGE_NUMBER,
    autodebitPageNumber: autodebitPageNumber,
  }
}
export const setAutodebitPageSize = (autodebitPageSize) => {
  return {
    type: AppStateConstants.SET_AUTO_DEBITS_PAGE_SIZE,
    autodebitPageSize: autodebitPageSize,
  }
}

export const setCarModelsPageNumber = (carModelsPageNumber) => {
  return {
    type: AppStateConstants.SET_CAR_MODELS_PAGE_NUMBER,
    carModelsPageNumber: carModelsPageNumber,
  }
}
export const setCarVariantsPageNumber = (carVariantsPageNumber) => {
  return {
    type: AppStateConstants.SET_CAR_VARIANTS_PAGE_NUMBER,
    carVariantsPageNumber: carVariantsPageNumber,
  }
}
export const setServicePackagePageNumber = (servicePackagePageNumber) => {
  return {
    type: AppStateConstants.SERVICE_PACKAGE_PAGE_NUMBER,
    servicePackagePageNumber: servicePackagePageNumber,
  }
}

export const setBikeBrandsPageNumber = (bikeBrandsPageNumber) => {
  return {
    type: AppStateConstants.SET_BIKE_BRANDS_PAGE_NUMBER,
    bikeBrandsPageNumber: bikeBrandsPageNumber,
  }
}

export const setBikeModelsPageNumber = (bikeModelsPageNumber) => {
  return {
    type: AppStateConstants.SET_BIKE_MODELS_PAGE_NUMBER,
    bikeModelsPageNumber: bikeModelsPageNumber,
  }
}

export const setBikeVariantsPageNumber = (bikeVariantsPageNumber) => {
  return {
    type: AppStateConstants.SET_BIKE_VARIANTS_PAGE_NUMBER,
    bikeVariantsPageNumber: bikeVariantsPageNumber,
  }
}

export const setBusinessPaginationNumber = (businessPagePaginationNumber) => {
  return {
    type: AppStateConstants.SET_BUSINESS_PAGE_PAGINATION_NUMBER,
    businessPagePaginationNumber: businessPagePaginationNumber,
  }
}

export const setFilterAtNewDailyCleaningBookings = (
  filterAtNewDailyCleaningBookings
) => {
  return {
    type: AppStateConstants.FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS,
    filterAtNewDailyCleaningBookings: filterAtNewDailyCleaningBookings,
  }
}

export const setOnDemandNewBookingCount = (onDemandNewBookingCount) => {
  return {
    type: AppStateConstants.ON_DEMAND_NEW_BOOKING_COUNT,
    onDemandNewBookingCount: onDemandNewBookingCount,
  }
}
export const setDailyCleaningNewBookingCount = (dailyCleaningNewBookingCount) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_NEW_BOOKING_COUNT,
    dailyCleaningNewBookingCount: dailyCleaningNewBookingCount,
  }
}
export const setDailyCleaningServicePackagesPageSize = (
  dailyCleaningServicePackagesPageSize
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE,
    dailyCleaningServicePackagesPageSize: dailyCleaningServicePackagesPageSize,
  }
}
export const setCarServicePackagesPageSize = (carServicePackagesPageSize) => {
  return {
    type: AppStateConstants.CAR_SERVICE_PACKAGES_PAGE_SIZE,
    carServicePackagesPageSize: carServicePackagesPageSize,
  }
}
export const setBikeServicePackagePageSize = (bikeServicePackagesPageSize) => {
  return {
    type: AppStateConstants.BIKE_SERVICE_PACKAGES_PAGE_SIZE,
    bikeServicePackagesPageSize: bikeServicePackagesPageSize,
  }
}
export const setBikeBrandsListPageSize = (bikesBrandsListPageSize) => {
  return {
    type: AppStateConstants.BIKES_BRANDS_LIST_PAGE_SIZE,
    bikesBrandsListPageSize: bikesBrandsListPageSize,
  }
}
export const setCarsBrandsListPageSize = (carsBrandsListPageSize) => {
  return {
    type: AppStateConstants.CARS_BRANDS_LIST_PAGE_SIZE,
    carsBrandsListPageSize: carsBrandsListPageSize,
  }
}
export const setPartnersPackageListPageSize = (
  partnersPackagaeListPageSize
) => {
  return {
    type: AppStateConstants.PARTNERS_PACKAGE_LIST_PAGE_SIZE,
    partnersPackagaeListPageSize: partnersPackagaeListPageSize,
  }
}
export const setPartnerBookingsListPageSize = (partnersBookingListPageSize) => {
  return {
    type: AppStateConstants.PARTNERS_BOOKING_LIST_PAGE_SIZE,
    partnersBookingListPageSize: partnersBookingListPageSize,
  }
}
export const setAddonsPartnersPackageListPageSize = (
  addonsPartnersPackageListPageSize
) => {
  return {
    type: AppStateConstants.ADDONS_PARTNERS_PACKAGE_LIST_PAGE_SIZE,
    addonsPartnersPackageListPageSize: addonsPartnersPackageListPageSize,
  }
}
export const setPartnerEveryDayRatingsPageSize = (
  partnerEveryDayRatingsPageSize
) => {
  return {
    type: AppStateConstants.PARTNER_EVERY_DAY_RATINGS_PAGE_SIZE,
    partnerEveryDayRatingsPageSize: partnerEveryDayRatingsPageSize,
  }
}
export const setPartnerCombinedPocketHistoryPageSize = (
  partnerCombinedPocketHistoryPageSize
) => {
  return {
    type: AppStateConstants.PARTNER_COMBINED_POCKET_HISTORY_PAGE_SIZE,
    partnerCombinedPocketHistoryPageSize: partnerCombinedPocketHistoryPageSize,
  }
}

export const setCustomerClaimedRefunds = (customerClaimedRefunds) => {
  return {
    type: AppStateConstants.CUSTOMER_CLAIMED_REFUNDS,
    customerClaimedRefunds: customerClaimedRefunds,
  }
}

export const setFreeTrialActiveBookingsPageNum = (pageNum) => {
  return {
    type: AppStateConstants.FREE_TRIAL_ACTIVE_BOOKINGS_PAGE_NUM,
    freeTrialActiveBookingsPageNum: pageNum,
  }
}

export const setFreeTrialNotConvertedBookingsPageNum = (pageNum) => {
  return {
    type: AppStateConstants.FREE_TRIAL_NOT_CONVERTED_BOOKINGS_PAGE_NUM,
    freeTrialNotConvertedBookingsPageNum: pageNum,
  }
}

export const setFreeTrialConvertedBookingsPageNum = (pageNum) => {
  return {
    type: AppStateConstants.FREE_TRIAL_CONVERTED_BOOKINGS_PAGE_NUM,
    freeTrialConvertedBookingsPageNum: pageNum,
  }
}
export const setFilterDailyCleaningData = (data) => {
  return {
    type: AppStateConstants.FILTER_DAILY_CLEANING_DATA,
    filterDailyCleaningData: data,
  }
}
export const setFilterServicePackages = (data) => {
  return {
    type: AppStateConstants.FILTER_SERVICE_PACKAGES,
    filterServicePackages: data,
  }
}

export const setFilterPartnerData = (data) => {
  return {
    type: AppStateConstants.FILTER_PARTNERS_DATA,
    filterPartnerData: data,
  }
}

export const setFilterAssignedBookings = (data) => {
  return {
    type: AppStateConstants.FILTER_ASSIGNED_BOOKINGS,
    filterAssignedBookings: data,
  }
}

export const setFilterDailyCleaningRefunds = (data) => {
  return {
    type: AppStateConstants.FILTER_DAILY_CLEANING_REFUNDS,
    filterDailyCleaningRefunds: data,
  }
}

export const setRefundDataSummary = (data) => {
  return {
    type: AppStateConstants.REFUND_DATA_SUMMARY,
    refundDataSummary: data,
  }
}

export const setNotificationPageNumber = (data) => {
  return {
    type: AppStateConstants.SET_NOTIFICATION_PAGE_NUMBER,
    notificationPageNumber: data,
  }
}

export const setNotificationFilterData = (data) => {
  return {
    type: AppStateConstants.FILTER_NOTIFICATION_DATA,
    filterNotificationData: data,
  }
}

export const setNotificationFilterPayload = (data) => {
  return {
    type: AppStateConstants.NOTIFICATION_FILTER_PAYLOAD,
    notificationFilterPayLoad: data,
  }
}

export const setGlobalTableData = (data) => {
  return {
    type: AppStateConstants.GLOBAL_TABLE_DATA,
    globalTableData: data,
  }
}

export const setUsersPageCount = (usersPageCount) => {
  return {
    type: AppStateConstants.USERS_PAGE_COUNT,
    usersPageCount: usersPageCount,
  }
}

export const setAutoDebitFilters = (autoDebitFilters) => {
  return {
    type: AppStateConstants.AUTO_DEBIT_FILTERS,
    autoDebitFilters: autoDebitFilters,
  }
}

export const setAutoDebitUsersCount = (autoDebitUsersCount) => {
  return {
    type: AppStateConstants.AUTO_DEBIT_USERS_COUNT,
    autoDebitUsersCount: autoDebitUsersCount,
  }
}
export const setFilterPocketHistory = (data) => {
  return {
    type: AppStateConstants.FILTER_POCKET_HISTORY,
    filterPocketHistory: data,
  }
}
