// @flow
import React, {useEffect, useState} from "react"
import {Row, Col, Modal, Button} from "react-bootstrap"
import Select from "react-select"
import callApi from "../../../utils/apiCaller"
import {FormInput} from "../../../components"
import _ from "lodash"
import {DateRangePicker} from "react-date-range"
import moment from "moment"
import {useDispatch, useSelector} from "react-redux"
import {setFilterAssignedBookings} from "../../../redux/appState/appActions"

const JobsFilterComponent = (props) => {
  const [cleanerDataFromApi, setCleanerDataFromApi] = React.useState([])
  const [selectedCleaner, setSelectedCleaner] = useState([])
  const [cities, setCity] = React.useState([])
  const [locationListByCity, setLocationListByCity] = React.useState([])
  const [location, setLocation] = React.useState([])
  const [selectedAgency, setSelectedAgency] = React.useState([])
  const [cityId, setCityId] = React.useState(null)
  const [apartments, setApartments] = React.useState([])
  const [apartmentsFromApi, setApartmentsFromApi] = React.useState([])
  const [apartmentByLocation, setApartmentsByLocation] = useState([])
  const [vehicleType, setVehicleType] = useState("")
  const [timeSlotsFromApi, setTimeSlotsFromApi] = useState([])
  const [timeSlot, setTimeSlot] = useState([])
  const [blocksFromApi, setBlocksFromApi] = useState([])
  const [block, setBlock] = useState([])
  const [agencyDataFromApi, setAgencyDataFromApi] = useState([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [selectedServiceType, setSelectedServiceType] = useState()

  const [showDateRangeModal, setShowDateRangeModal] = useState(false)
  const [datePickerFor, setDatePickerFor] = useState("")
  const [selectionRange, setSelectionRange] = useState({})
  const [serviceDateSelectionRange, setServiceDateSelectionRange] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: "All",
  })
  const [statusList, setStatusList] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "active",
      label: "Active",
    },
    {
      value: "expired",
      label: "Expired",
    },
  ])
  const filterAssignedBookings = useSelector(
    (state) => state?.AppState?.filterAssignedBookings
  )

  const dispatch = useDispatch()

  const fetchAgencies = (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("agency/getall", "POST", info).then((res) => {
      if (res.data) {
        setAgencyDataFromApi(res.data)
        const list = res?.data?.map((item) => {
          let role = item.role === "AGENCY_OWNER" ? "Agency" : "Independent"
          return {
            value: `${item.name} (${item?.owner_name}) - ${item.owner_phone}`,
            label: `${item.name} (${item?.owner_name}) - ${item.owner_phone}`,
            _id: item._id,
            ownerId: item.ownerId,
          }
        })
        setAgencyDataFromApi(list)
      }
    })
  }

  useEffect(() => {
    // getAllStaff()
    getCities()
    fetchAgencies(1)
    // getTimeSlots()
    getServiceTypes()
    if (filterAssignedBookings?.cityId) {
      getLocationListByCity(filterAssignedBookings?.cityId)
    }
    if (filterAssignedBookings?.location?.length > 0) {
      getApartments(filterAssignedBookings?.location)
    } else {
      getAllApartments()
    }
  }, [])

  const getServiceTypes = (id) => {
    callApi(`sp/ondemand/allservicetype`, "GET").then((res) => {
      if (res.success) {
        const list = res?.data?.map((item) => {
          return {
            value: item?.categoryName,
            label: item?.categoryName,
            _id: item?._id,
          }
        })
        setServiceTypes(list)
      }
    })
  }

  const getCities = async () => {
    callApi(`city`, `get`).then((res) => {
      if (res.success) {
        setCity(res.data)
      }
    })
  }

  const getAllStaff = async (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("partner/globalsearch", "POST", info).then((res) => {
      if (res.data) {
        const list = res?.data?.map((item) => {
          return {
            value: `${item.name} - ${item.phone}`,
            label: `${item.name} - ${item.phone}`,
            _id: item._id,
          }
        })
        setCleanerDataFromApi(list)
      }
    })
  }
  const getStaffByAgency = async (value) => {
    const data = {}
    if (value?._id) {
      data.agencyId = value.ownerId
    }
    callApi("partner/getStaff", "POST", data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((partners) => {
          return {
            value: `${partners.name} - ${partners.phone}`,
            label: `${partners.name} - ${partners.phone}`,
            _id: partners._id,
          }
        })
        setCleanerDataFromApi(list_b)
      }
    })
  }
  const getApartments = async (value) => {
    const location = value.map((i) => {
      return i._id
    })

    callApi(`apartment`, `POST`, {locationId: location}).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsByLocation(list_b)
      }
    })
  }
  const getAllApartments = async (value) => {
    const data = {}
    if (value) {
      data.search = value
    }
    callApi(`apartments/getall`, `POST`, data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }

  const getLocationListByCity = async (value) => {
    callApi(`location/${value}`, `get`).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setLocationListByCity(list_b)
      }
    })
  }

  const getBlocks = async (value) => {
    callApi(`blocks/getall`, `POST`, {
      apartmentId: value,
    }).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setBlocksFromApi(list_b)
      }
    })
  }

  // const getTimeSlots = async () => {
  //   callApi(`dailytimeslot`, `get`).then((res) => {
  //     if (res.success) {
  //       setTimeSlotsFromApi(res.data)
  //     }
  //   })
  // }

  const handlePartnerChange = (inputValue) => {
    fetchAgencies(1, inputValue)
  }
  const handleAgencies = (inputValue) => {
    if (inputValue && inputValue.length > 0) {
      fetchAgencies(1, inputValue)
    }
  }

  const applyFilters = (e) => {
    let filterObject = {}
    if (filterAssignedBookings?.cityId) {
      filterObject.cityId = [filterAssignedBookings?.cityId]
    }
    if (!_.isEmpty(filterAssignedBookings?.location)) {
      filterObject.locationId = filterAssignedBookings?.location.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(filterAssignedBookings?.apartments)) {
      filterObject.servicePlaceNameId = filterAssignedBookings?.apartments?.map(
        (i) => {
          return i._id
        }
      )
    }
    if (!_.isEmpty(filterAssignedBookings?.vehicleType)) {
      filterObject.vehicle_type = filterAssignedBookings?.vehicleType
    }
    if (!_.isEmpty(timeSlot)) {
      filterObject.timeSlot = [timeSlot]
    }
    if (!_.isEmpty(block)) {
      filterObject.block_id = block.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(filterAssignedBookings?.selectedCleaner?._id)) {
      filterObject.cleanerId = filterAssignedBookings?.selectedCleaner?._id
    }
    if (!_.isEmpty(filterAssignedBookings?.selectedAgency)) {
      if (props?.isFrom === "completedOnDemandBookings") {
        filterObject.agencyId = [filterAssignedBookings?.selectedAgency._id]
      } else {
        filterObject.agencyId = filterAssignedBookings?.selectedAgency._id
      }
    }
    if (!_.isEmpty(filterAssignedBookings?.selectedServiceType)) {
      filterObject.serviceTypeId = [
        filterAssignedBookings?.selectedServiceType?._id,
      ]
    }
    if (!_.isEmpty(filterAssignedBookings?.selectedStatus)) {
      if (filterAssignedBookings?.selectedStatus.value == "active") {
        filterObject.isActive = true
        filterObject.isExpired = false
      }
      if (filterAssignedBookings?.selectedStatus.value == "expired") {
        filterObject.isExpired = true
        filterObject.isActive = false
      }
    }
    if (!_.isEmpty(filterAssignedBookings?.joinedDate)) {
      filterObject.joinedStartDate = moment(
        filterAssignedBookings?.joinedDate?.startDate
      )
      filterObject.joinedEndDate = moment(
        filterAssignedBookings?.joinedDate?.endDate
      )
    }
    if (!_.isEmpty(filterAssignedBookings?.expireDate)) {
      filterObject.expiryFilter = {}
      filterObject.expiryFilter.fromDate = moment(
        filterAssignedBookings?.expireDate?.startDate
      )
      filterObject.expiryFilter.toDate = moment(
        filterAssignedBookings?.expireDate?.endDate
      )
    }
    if (!_.isEmpty(filterAssignedBookings?.reactiveDate)) {
      filterObject.reactivationFilter = {}
      filterObject.reactivationFilter.fromDate = moment(
        filterAssignedBookings?.reactiveDate?.startDate
      )
      filterObject.reactivationFilter.toDate = moment(
        filterAssignedBookings?.reactiveDate?.endDate
      )
    }
    if (!_.isEmpty(filterAssignedBookings?.cancelledDate)) {
      filterObject.cancellationFilter = {}
      filterObject.cancellationFilter.fromDate = moment(
        filterAssignedBookings?.cancelledDate?.startDate
      )
      filterObject.cancellationFilter.toDate = moment(
        filterAssignedBookings?.cancelledDate?.endDate
      )
    }
    if (!_.isEmpty(filterAssignedBookings?.bookingDate)) {
      filterObject.service_booking_date_start = moment(
        filterAssignedBookings?.bookingDate?.startDate
      ).utc().utcOffset(240).format("YYYY-MM-DD")
      filterObject.service_booking_date_end = moment(
        filterAssignedBookings?.bookingDate?.endDate
      ).utc().utcOffset(240).format("YYYY-MM-DD")
    }
    if (!_.isEmpty(filterAssignedBookings?.serviceDate)) {
      filterObject.service_start_date = moment(
        filterAssignedBookings?.serviceDate?.startDate
      ).utc().utcOffset(240).format("YYYY-MM-DD")
      filterObject.service_end_date = moment(
        filterAssignedBookings?.serviceDate?.endDate
      ).utc().utcOffset(240).format("YYYY-MM-DD")
    }
    props.applyAllFilters(filterObject)
  }

  const handleApartmentChange = (inputValue) => {
    if (inputValue && inputValue.length > 0) {
      getAllApartments(inputValue)
    }
  }

  const handleSelect = (ranges, type) => {
    if (type == "joinedDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          joinedDate: ranges[Object.keys(ranges)[0]],
          joinedStartDate: moment(ranges[Object.keys(ranges)[0]]?.startDate),
          joinedEndDate: moment(ranges[Object.keys(ranges)[0]]?.endDate),
        })
      )
    } else if (type == "expireDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          expireDate: ranges[Object.keys(ranges)[0]],
        })
      )
    } else if (type == "reactiveDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          reactiveDate: ranges[Object.keys(ranges)[0]],
        })
      )
    } else if (type == "cancelledDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          cancelledDate: ranges[Object.keys(ranges)[0]],
        })
      )
    } else if (type == "bookingDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          bookingDate: ranges[Object.keys(ranges)[0]],
        })
      )
    } else if (type == "serviceDate") {
      dispatch(
        setFilterAssignedBookings({
          ...filterAssignedBookings,
          serviceDate: ranges[Object.keys(ranges)[0]],
        })
      )
    }
  }

  return (
    <>
      <>
        <Col md={6} className="mb-2 mt-2">
          {!props.showPartner && (
            <>
              <p className="fw-bold ">Partner</p>
              <Select
                isClearable
                options={agencyDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={filterAssignedBookings?.selectedAgency || ""}
                onChange={(e) => {
                  // setSelectedAgency(e)
                  dispatch(
                    setFilterAssignedBookings({
                      ...filterAssignedBookings,
                      selectedAgency: e,
                    })
                  )
                  setSelectedCleaner([])
                  // getStaffByAgency(e)
                }}
                onInputChange={handleAgencies}
                placeholder="--select partner--"
              ></Select>
            </>
          )}
        </Col>
        {!_.isEmpty(cleanerDataFromApi) && (
          <Col md={6} className="mb-2 mt-2">
            <>
              <p className="fw-bold ">Select or search partner</p>
              <Select
                isClearable
                options={cleanerDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={filterAssignedBookings?.selectedCleaner || ""}
                onChange={(e) => {
                  // setSelectedCleaner(e)
                  dispatch({
                    ...filterAssignedBookings,
                    selectedCleaner: e,
                  })
                }}
                onInputChange={handlePartnerChange}
                placeholder="Select or search cleaner"
              ></Select>
            </>
          </Col>
        )}
        <Row>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              labelClassName="fw-bold"
              label="City"
              type="select"
              name="city"
              placeholder="Enter password"
              key="city"
              value={filterAssignedBookings?.cityId || ""}
              defaultValue={"select city"}
              onChange={(e) => {
                getLocationListByCity(e.target.value)
                // setCityId(e.target.value)

                dispatch(
                  setFilterAssignedBookings({
                    ...filterAssignedBookings,
                    selectedAgency: filterAssignedBookings?.selectedAgency,
                    selectedCleaner: filterAssignedBookings?.selectedCleaner,
                    vehicleType: filterAssignedBookings?.vehicleType,
                    cityId: e.target.value,
                    location: [],
                    locationId: [],
                    apartments: [],
                    apartmentId: [],
                  })
                )
                setLocation([])
              }}
            >
              <option key="City" value="City">
                --select city--
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city._id} _id={city._id}>
                  {city.name}
                </option>
              ))}
            </FormInput>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Location</p>
            <Select
              isMulti={true}
              options={locationListByCity}
              className="react-select"
              classNamePrefix="react-select"
              value={filterAssignedBookings?.location || ""}
              onChange={(e) => {
                // setLocation(e)
                dispatch(
                  setFilterAssignedBookings({
                    ...filterAssignedBookings,
                    location: e,
                    locationId: e.map((i) => {
                      return i._id
                    }),
                  })
                )
                setApartments([])
                getApartments(e)
              }}
              placeholder="Select Location"
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Apartments</p>
            <Select
              isMulti={true}
              options={
                apartmentByLocation.length > 0
                  ? apartmentByLocation
                  : apartmentsFromApi
              }
              className="react-select"
              classNamePrefix="react-select"
              value={filterAssignedBookings?.apartments || ""}
              onChange={(e) => {
                if (!_.isEmpty(e[0]?._id)) {
                  // getBlocks(e[0]._id)
                }
                setApartments(e)
                dispatch(
                  setFilterAssignedBookings({
                    ...filterAssignedBookings,
                    apartments: e,
                    apartmentId: e.map((i) => {
                      return i._id
                    }),
                  })
                )
              }}
              placeholder="Select Apartments"
              onInputChange={handleApartmentChange}
            ></Select>
          </Col>
          {props?.noVehicleTypeFilter ? null : (
            <Col md={4} className="mb-2 mt-1">
              <FormInput
                style={{
                  marginTop: 2,
                }}
                isClearable
                labelClassName="fw-bold"
                label="Vehicle type"
                type="select"
                name="vehicle_type"
                placeholder="Select vehicle"
                key="vehicle_type"
                value={filterAssignedBookings?.vehicleType || ""}
                defaultValue={"vehicle type"}
                onChange={(e) => {
                  // setVehicleType(e.target.value)
                  dispatch(
                    setFilterAssignedBookings({
                      ...filterAssignedBookings,
                      vehicleType: e.target.value,
                    })
                  )
                }}
              >
                <option key="" value="">
                  --Select vehicle type --
                </option>
                <option key="car" value="car">
                  Car
                </option>
                <option key="bike" value="bike">
                  Bike
                </option>
              </FormInput>
            </Col>
          )}
          {props?.formOnDemand ? (
            <>
              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Booking Date</p>
                <Row>
                  <div md={12}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        ...styles.dateText,
                        backgroundColor: filterAssignedBookings?.bookingDate
                          ?.startDate
                          ? "#EEF2F6"
                          : "#fff",
                      }}
                    >
                      <div
                        style={styles.dateContainer}
                        onClick={() => {
                          if (_.isEmpty(filterAssignedBookings?.bookingDate)) {
                            dispatch(
                              setFilterAssignedBookings({
                                ...filterAssignedBookings,
                                bookingDate: {
                                  startDate: new Date(moment()),
                                  endDate: new Date(moment()),
                                },
                              })
                            )
                          }
                          setDatePickerFor("bookingDate")
                          setShowDateRangeModal(true)
                        }}
                      >
                        <span className="mr-2">
                          {filterAssignedBookings?.bookingDate?.startDate
                            ? moment(
                                filterAssignedBookings?.bookingDate?.startDate
                              ).utc().utcOffset(240).format("DD MMM, YYYY") +
                              " - " +
                              moment(
                                filterAssignedBookings?.bookingDate?.endDate
                              ).utc().utcOffset(240).format("DD MMM, YYYY")
                            : "--select booking date--"}
                        </span>
                      </div>
                      {!_.isEmpty(filterAssignedBookings?.bookingDate) ? (
                        <div
                          style={styles.closeButton}
                          onClick={() => {
                            dispatch(
                              setFilterAssignedBookings({
                                ...filterAssignedBookings,
                                bookingDate: {},
                              })
                            )
                          }}
                        >
                          <i className="mdi mr-1 dripicons-cross"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Row>
              </Col>
              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Service Date</p>
                <Row>
                  <div md={12}>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        ...styles.dateText,
                        backgroundColor:
                          filterAssignedBookings?.serviceDate?.startDate ||
                          filterAssignedBookings?.serviceDate?.endDate
                            ? "#EEF2F6"
                            : "#fff",
                      }}
                    >
                      <div
                        style={styles.dateContainer}
                        onClick={() => {
                          if (_.isEmpty(filterAssignedBookings?.serviceDate)) {
                            dispatch(
                              setFilterAssignedBookings({
                                ...filterAssignedBookings,
                                serviceDate: {
                                  startDate: new Date(moment()),
                                  endDate: new Date(moment()),
                                },
                              })
                            )
                          }
                          setDatePickerFor("serviceDate")
                          setShowDateRangeModal(true)
                        }}
                      >
                        <span className="mr-2">
                          {filterAssignedBookings?.serviceDate?.startDate
                            ? moment(
                                filterAssignedBookings?.serviceDate?.startDate
                              ).utc().utcOffset(240).format("DD MMM, YYYY") +
                              " - " +
                              moment(
                                filterAssignedBookings?.serviceDate?.endDate
                              ).utc().utcOffset(240).format("DD MMM, YYYY")
                            : "--select service date--"}
                        </span>
                      </div>
                      {!_.isEmpty(filterAssignedBookings?.serviceDate) ? (
                        <div
                          style={styles.closeButton}
                          onClick={() => {
                            dispatch(
                              setFilterAssignedBookings({
                                ...filterAssignedBookings,
                                serviceDate: {},
                              })
                            )
                          }}
                        >
                          <i className="mdi mr-1 dripicons-cross"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Row>
              </Col>

              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Services</p>
                <Select
                  placeholder="Select or search service"
                  isClearable
                  options={serviceTypes}
                  className="react-select"
                  classNamePrefix="react-select"
                  value={filterAssignedBookings?.selectedServiceType || ""}
                  onChange={(e) => {
                    dispatch(
                      setFilterAssignedBookings({
                        ...filterAssignedBookings,
                        selectedServiceType: e,
                      })
                    )
                    // setSelectedServiceType(e)
                  }}
                ></Select>
              </Col>
              {props?.showStatusFilter ? (
                <Col md={4} className="mb-2 mt-1">
                  <p className="fw-bold ">Status</p>
                  <Select
                    isClearable
                    options={statusList}
                    className="react-select"
                    classNamePrefix="react-select"
                    value={filterAssignedBookings?.selectedStatus || ""}
                    onChange={(e) => {
                      dispatch(
                        setFilterAssignedBookings({
                          ...filterAssignedBookings,
                          selectedStatus: e,
                        })
                      )
                      // setSelectedStatus(e)
                    }}
                    placeholder="Select Status"
                  ></Select>
                </Col>
              ) : null}
            </>
          ) : null}
          {props?.showDateSelector ? (
            <Col md={4} className="mb-2 mt-1">
              <p className="fw-bold ">Joined Date</p>
              <Row>
                <div md={12}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      ...styles.dateText,
                      backgroundColor: filterAssignedBookings?.joinedDate
                        ?.startDate
                        ? "#EEF2F6"
                        : "#fff",
                    }}
                  >
                    <div
                      style={styles.dateContainer}
                      onClick={() => {
                        if (_.isEmpty(filterAssignedBookings?.joinedDate)) {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              joinedDate: {
                                startDate: new Date(moment()),
                                endDate: new Date(moment()),
                              },
                              joinedStartDate: moment().utc().utcOffset(240).format("YYYY-MM-DD"),
                              joinedEndDate: moment().utc().utcOffset(240).format("YYYY-MM-DD"),
                            })
                          )
                        }
                        setDatePickerFor("joinedDate")
                        setShowDateRangeModal(true)
                      }}
                    >
                      <span className="mr-2">
                        {filterAssignedBookings?.joinedDate?.startDate
                          ? moment(
                              filterAssignedBookings?.joinedDate?.startDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY") +
                            " - " +
                            moment(
                              filterAssignedBookings?.joinedDate?.endDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY")
                          : "--select joined date--"}
                      </span>
                    </div>
                    {!_.isEmpty(filterAssignedBookings?.joinedDate) ? (
                      <div
                        style={styles.closeButton}
                        onClick={() => {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              joinedDate: {},
                              joinedStartDate: "",
                              joinedEndDate: "",
                            })
                          )
                        }}
                      >
                        <i className="mdi mr-1 dripicons-cross"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
            </Col>
          ) : null}
          {props?.showReactiveDate ? (
            <Col md={4} className="mb-2 mt-0">
              <p className="fw-bold ">Start/Reactivation Date</p>
              <Row>
                <div md={12}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      ...styles.dateText,
                      backgroundColor: filterAssignedBookings?.reactiveDate
                        ?.startDate
                        ? "#EEF2F6"
                        : "#fff",
                    }}
                  >
                    <div
                      style={styles.dateContainer}
                      onClick={() => {
                        if (_.isEmpty(filterAssignedBookings?.reactiveDate)) {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              reactiveDate: {
                                startDate: new Date(moment()),
                                endDate: new Date(moment()),
                              },
                            })
                          )
                        }
                        setDatePickerFor("reactiveDate")
                        setShowDateRangeModal(true)
                      }}
                    >
                      <span className="mr-2">
                        {filterAssignedBookings?.reactiveDate?.startDate
                          ? moment(
                              filterAssignedBookings?.reactiveDate?.startDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY") +
                            " - " +
                            moment(
                              filterAssignedBookings?.reactiveDate?.endDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY")
                          : "--select reactive date--"}
                      </span>
                    </div>
                    {!_.isEmpty(filterAssignedBookings?.reactiveDate) ? (
                      <div
                        style={styles.closeButton}
                        onClick={() => {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              reactiveDate: {},
                            })
                          )
                        }}
                      >
                        <i className="mdi mr-1 dripicons-cross"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
            </Col>
          ) : null}
          {props?.showExpireDate ? (
            <Col md={4} className="mb-2 mt-0">
              <p className="fw-bold ">Expire Date</p>
              <Row>
                <div md={12}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      ...styles.dateText,
                      backgroundColor: filterAssignedBookings?.expireDate
                        ?.startDate
                        ? "#EEF2F6"
                        : "#fff",
                    }}
                  >
                    <div
                      style={styles.dateContainer}
                      onClick={() => {
                        if (_.isEmpty(filterAssignedBookings?.expireDate)) {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              expireDate: {
                                startDate: new Date(moment()),
                                endDate: new Date(moment()),
                              },
                            })
                          )
                        }
                        setDatePickerFor("expireDate")
                        setShowDateRangeModal(true)
                      }}
                    >
                      <span className="mr-2">
                        {filterAssignedBookings?.expireDate?.startDate
                          ? moment(
                              filterAssignedBookings?.expireDate?.startDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY") +
                            " - " +
                            moment(
                              filterAssignedBookings?.expireDate?.endDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY")
                          : "--select expire date--"}
                      </span>
                    </div>
                    {!_.isEmpty(filterAssignedBookings?.expireDate) ? (
                      <div
                        style={styles.closeButton}
                        onClick={() => {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              expireDate: {},
                            })
                          )
                        }}
                      >
                        <i className="mdi mr-1 dripicons-cross"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
            </Col>
          ) : null}
          {props?.showCancelledDate ? (
            <Col md={4} className="mb-2 mt-0">
              <p className="fw-bold ">Cancelled Date</p>
              <Row>
                <div md={12}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      ...styles.dateText,
                      backgroundColor: filterAssignedBookings?.cancelledDate
                        ?.startDate
                        ? "#EEF2F6"
                        : "#fff",
                    }}
                  >
                    <div
                      style={styles.dateContainer}
                      onClick={() => {
                        if (_.isEmpty(filterAssignedBookings?.cancelledDate)) {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              cancelledDate: {
                                startDate: new Date(moment()),
                                endDate: new Date(moment()),
                              },
                            })
                          )
                        }
                        setDatePickerFor("cancelledDate")
                        setShowDateRangeModal(true)
                      }}
                    >
                      <span className="mr-2">
                        {filterAssignedBookings?.cancelledDate?.startDate
                          ? moment(
                              filterAssignedBookings?.cancelledDate?.startDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY") +
                            " - " +
                            moment(
                              filterAssignedBookings?.cancelledDate?.endDate
                            ).utc().utcOffset(240).format("DD MMM, YYYY")
                          : "--select cancelled date--"}
                      </span>
                    </div>
                    {!_.isEmpty(filterAssignedBookings?.cancelledDate) ? (
                      <div
                        style={styles.closeButton}
                        onClick={() => {
                          dispatch(
                            setFilterAssignedBookings({
                              ...filterAssignedBookings,
                              cancelledDate: {},
                            })
                          )
                        }}
                      >
                        <i className="mdi mr-1 dripicons-cross"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
            </Col>
          ) : null}
        </Row>
      </>
      <div className="text-end mt-2">
        <button
          className="btn btn-bloc "
          style={{backgroundColor: "#2A882A", color: "white"}}
          onClick={() => applyFilters()}
        >
          <i className="mdi dripicons-checkmark mr-1"></i> Apply selected
          filters
        </button>
      </div>
      <Modal
        show={showDateRangeModal}
        onHide={() => {
          setShowDateRangeModal(false)
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DateRangePicker
              ranges={
                datePickerFor == "joinedDate"
                  ? [filterAssignedBookings?.joinedDate]
                  : datePickerFor == "expireDate"
                  ? [filterAssignedBookings?.expireDate]
                  : datePickerFor == "reactiveDate"
                  ? [filterAssignedBookings?.reactiveDate]
                  : datePickerFor == "cancelledDate"
                  ? [filterAssignedBookings?.cancelledDate]
                  : datePickerFor == "bookingDate"
                  ? [filterAssignedBookings?.bookingDate]
                  : datePickerFor == "serviceDate"
                  ? [filterAssignedBookings?.serviceDate]
                  : [filterAssignedBookings?.serviceDateSelectionRange]
              }
              onChange={(ranges) => handleSelect(ranges, datePickerFor)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowDateRangeModal(false)
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default JobsFilterComponent

const styles = {
  dateText: {
    border: "1px solid #E5E5E5",
    borderRadius: 5,
    // cursor: "pointer",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    padding: 8,
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    cursor: "pointer",
    padding: 8,
  },
}
