const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2)
}

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

function convertH2M(timeInHour) {
  var timeParts = timeInHour.split(":")
  return Number(timeParts[0]) * 60 + Number(timeParts[1])
}

function convertM2H(timeInMin) {
  var hours = Math.trunc(timeInMin / 60)
  var minutes = timeInMin % 60
  hours = hours < 10 ? "0" + hours : hours
  minutes = minutes < 10 ? "0" + minutes : minutes
  return hours + ":" + minutes
}

function convertM2HAM(timeInMin) {
  var hours = Math.trunc(timeInMin / 60)
  var minutes = timeInMin % 60
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  return strTime
}
export {uid, formatBytes, convertH2M, convertM2H, convertM2HAM}
