// @flow
import React, {useState} from "react"
import {Card, Col, Table, InputGroup, Form} from "react-bootstrap"
import {Link} from "react-router-dom"
import moment from "moment"
import _ from "lodash"
import swal from "sweetalert"
import callApi from "../../../utils/apiCaller"
import AssignApartmentComponentOndemand from "./AssignApartmentComponentOndemand"
import SearchInput from "../../../components/SearchInput"

const PackageApartmentsOnDemand = (props) => {
  const [showAssignModal, setShowAssignModal] = useState(false)
  const data = props.data
  const [apartmentList, setApartmentList] = useState(props.apartments || [])
  const [searchInput, setSearchInput] = useState("")

  const updateStatus = (value) => {
    const text = value.status === "Active" ? "Deactivate" : "Activate"
    swal({
      title: "Are you sure ?",
      text: `You want to ${text} apartment`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (value._id) {
          callApi(`sp/daily/updatestatus`, "POST", {
            status: value.status === "Active" ? "Inactive" : "Active",
            spmId: value._id,
          }).then((res) => {
            if (res.success) {
              swal("Success", "Updated location successfully", "success")
              props.getData(props.data._id)
              props.getApartments(props.data._id)
            } else {
              swal("Error", res.message, "error")
            }
          })
        }
      }
    })
  }

  const filter = (text) => {
    const newList = props.apartments.filter((s) => {
      return s.locationId?.name.toLowerCase().includes(text.toLowerCase())
    })
    setApartmentList([...newList])
  }

  return (
    <Card>
      <Card.Body>
        <div>
          <h4 className="font-15">Locations :</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="d-flex align-items-center">
              <InputGroup
                style={{
                  display: "flex",
                  border: "1px solid #00000050",
                  borderRadius: 4,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Control
                  placeholder={props.placeholder || "Search...."}
                  aria-label={props.placeholder || "Search...."}
                  aria-describedby="basic-addon2"
                  style={{border: 0}}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                  }}
                  id="search"
                  value={searchInput}
                  onKeyDown={(event) =>
                    event.key === "Enter" && filter(searchInput)
                  }
                ></Form.Control>
                <div
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    paddingLeft: 10,
                  }}
                  onClick={() => {
                    setSearchInput("")
                    filter(searchInput)
                  }}
                >
                  <i className="mdi mr-1  dripicons-cross"></i>
                </div>
                <button
                  className="btn btn-block"
                  style={{backgroundColor: "#2A882A", color: "white"}}
                  onClick={() => {
                    filter(searchInput)
                  }}
                >
                  <i className="mdi mr-1"></i> Search
                </button>
              </InputGroup>
            </span>
            <button
              className="btn btn-block"
              style={{backgroundColor: "green", color: "white"}}
              onClick={() => setShowAssignModal(true)}
            >
              Add location
            </button>
          </div>
          <Col xl={8}>
            {!_.isEmpty(apartmentList) && (
              <div>
                <Table
                  responsive
                  className="table table-borderless table-nowrap mb-0"
                >
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apartmentList?.map((project, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{project?.locationId?.name}</td>
                          <td>
                            <span
                              className="ms-2 badge"
                              style={{
                                fontSize: 12,
                                backgroundColor:
                                  project.status === "Active" ? "green" : "red",
                              }}
                            >
                              {project.status}
                            </span>
                          </td>
                          <td>
                            <div
                              className="action-icon"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => updateStatus(project)}
                            >
                              {" "}
                              <i className="mdi mdi-square-edit-outline"></i>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>
        </div>
        {showAssignModal && (
          <AssignApartmentComponentOndemand
            data={data}
            onHide={() => {
              setShowAssignModal(false)
            }}
            getData={props.getData}
            getApartments={props.getApartments}
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default PackageApartmentsOnDemand
