import React from "react"
import Spinner from "../components/Spinner"

const LoadingButton = (props) => {
  return (
    <button
      className="btn btn-success mt-2"
      {...props}
      disabled={props.disabled}
    >
      {props.disabled ? (
        <>
          <Spinner className="spinner-border-sm" tag="span" color="white" />{" "}
          Loading...
        </>
      ) : (
        <>{props.children}</>
      )}
    </button>
  )
}

export default LoadingButton
