import moment from "moment"
import React, {memo, useState} from "react"
import {Button, Dropdown, Modal} from "react-bootstrap"
import {DateRangePicker} from "react-date-range"
import {Link} from "react-router-dom"

const DaysSelector = ({dateSelected, startDate, endDate}) => {
  var isCurrentDate = moment(startDate).isSame(new Date(), "day")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedRange, setSelectedRange] = useState("Today")
  const [showDateRangeModal, setShowDateRangeModal] = useState(false)
  const [currentDataSelected, setCurrentDataSelected] = useState({
    startDate: new Date(moment(startDate)),
    endDate: new Date(moment(endDate)),
  })

  const toggleDropdown = (info) => {
    setDropdownOpen(!dropdownOpen)
    setSelectedRange(info.target.innerText)
    if (info.target.innerText === "Today") {
      dateSelected({
        startDate: new Date(moment()),
        endDate: new Date(moment()),
      })
      setCurrentDataSelected({
        startDate: new Date(moment()),
        endDate: new Date(moment()),
      })
    } else if (info.target.innerText === "Yesterday") {
      dateSelected({
        startDate: new Date(moment().subtract(1, "days")),
        endDate: new Date(moment().subtract(1, "days")),
      })
      setCurrentDataSelected({
        startDate: new Date(moment().subtract(1, "days")),
        endDate: new Date(moment().subtract(1, "days")),
      })
    } else if (info.target.innerText === "Last 7 days") {
      dateSelected({
        startDate: new Date(moment().subtract(7, "days")),
        endDate: new Date(moment()),
      })
      setCurrentDataSelected({
        startDate: new Date(moment().subtract(7, "days")),
        endDate: new Date(moment()),
      })
    } else if (info.target.innerText === "Select Date Range") {
      setShowDateRangeModal(true)
    } else {
      dateSelected({
        startDate: new Date(moment()),
        endDate: new Date(moment()),
      })
    }
  }

  const Languages = [
    {
      name: "Today",
    },
    {
      name: "Yesterday",
    },
    {
      name: "Last 7 days",
    },
    {
      name: "Select Date Range",
    },
  ]

  return (
    <div
      className="d-flex align-items-center"
      style={{
        alignItems: "center",
        // justifyContent: "end",
      }}
    >
      <span className="">Select Date: </span>
      <Dropdown
        show={dropdownOpen}
        align={"middle"}
        style={{zIndex: 999}}
        onToggle={() => {
          setDropdownOpen(!dropdownOpen)
        }}
      >
        <Dropdown.Toggle
          variant="link"
          id="dropdown-languages"
          as={Link}
          to="#"
          onClick={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          className="nav-link dropdown-toggle arrow-none ms-1 center"
          style={{
            color: "#7239EA",
            display: "flex",
            alignItems: "center",
            background: "#0000000d",
            padding: "2px 10px",
            borderRadius: 5,
          }}
        >
          <span className="fw-bold font-14" style={{}}>
            {moment(currentDataSelected.startDate).utc().utcOffset(240).format("DD MMM")}
            {` - ${moment(currentDataSelected.endDate).utc().utcOffset(240).format("DD MMM")}`}
          </span>
          <span>
            <i
              className="mdi mdi-calendar-range font-15"
              style={{marginLeft: 3}}
            />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align={"middle"}
          className="dropdown-menu-animated topbar-dropdown-menu"
        >
          <div onClick={toggleDropdown}>
            {Languages.map((lang, i) => {
              return (
                <Link
                  to="#"
                  className="dropdown-item notify-item"
                  key={i + "-lang"}
                  style={{
                    background: selectedRange === lang.name && "#ebeff2",
                  }}
                >
                  <span className="align-middle">{lang.name}</span>
                </Link>
              )
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={showDateRangeModal}
        onHide={() => {
          setShowDateRangeModal(false)
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DateRangePicker
              ranges={
                [
                  {
                    startDate: currentDataSelected.startDate,
                    endDate: currentDataSelected.endDate,
                    key: "selection",
                  },
                ] || []
              }
              date={currentDataSelected}
              onChange={(ranges) => {
                setCurrentDataSelected(ranges.selection)
                dateSelected({
                  startDate: ranges.selection.startDate,
                  endDate: ranges.selection.endDate,
                })
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowDateRangeModal(false)
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DaysSelector

const styles = {}
