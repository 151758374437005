// @flow
import React from "react"
import {Card, Dropdown} from "react-bootstrap"
import {Link, useNavigate} from "react-router-dom"

import moment from "moment"

// images

const PackageAddOnDescription = (props) => {
  const data = props.data
  const navigator = useNavigate()

  return (
      <div className="border-bottom">
        <h4 className="font-13 mt-3">Working days :</h4>
        {data.workingDays.map((i) => (
          <span key={i} className="badge bg-info" style={{marginLeft: 6,marginBottom:10}}>
            {i === 0
              ? "Sunday"
              : i === 1
              ? "Monday"
              : i === 2
              ? "Tuesday"
              : i === 3
              ? "Wednesday"
              : i === 4
              ? "Thursday"
              : i === 5
              ? "Friday"
              : "Saturday"}
          </span>
        ))}
      </div>
  )
}

export default PackageAddOnDescription
