import {Navigate, useLocation} from "react-router-dom"
import {APICore} from "../helpers/api/apiCore"
import {useSelector, connect} from "react-redux"

type PrivateRouteProps = {
  component: React.ComponentType,
  roles?: string,
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: RouteComponent,
  roles,
  ...rest
}: PrivateRouteProps) => {
  // const {loading, userLoggedIn, user} = useSelector((state) => ({
  //   user: state.Auth.user,
  // }))

  const {logged_in_user_details} = useSelector((state) => ({
    logged_in_user_details: state.AppState.logged_in_user_details,
  }))

  let location = useLocation()
  const api = new APICore()
  const loggedInUser = api.getLoggedInUser()

  /**
   * not logged in so redirect to login page with the return url
   */
  if (logged_in_user_details === null) {
    return <Navigate to={"/account/login"} state={{from: location}} replace />
  }

  return <RouteComponent />
}

export default PrivateRoute
