// @flow
import {combineReducers} from "redux"

import Auth from "./auth/reducers"
import Layout from "./layout/reducers"
import AppState from "./appState/appReducers"

export default combineReducers({
  Auth,
  Layout,
  AppState,
})
