import React, {useState, useEffect} from "react"
import {Row, Col, Card, OverlayTrigger, Modal} from "react-bootstrap"
import {Link} from "react-router-dom"
import moment from "moment"
import callApi from "../utils/apiCaller"
import swal from "sweetalert"

const StopSubscription = (props) => {
  const data = props.data ? props.data : {}
  const [isModalVisible, setModal] = useState(true)
  const [reason, setReason] = useState(data.reason)

  const updateBooking = () => {
    data.isHold ? activateService() : deactivateService()
  }

  const deactivateService = () => {
    swal({
      title: "Deactivate this booking?",
      // text: "You want to deactivate this booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const info = {
          servicebookingId: data._id,
          reason: reason,
        }
        console.log("info_____", info)
        callApi("servicebooking/stopsubscription", "POST", info).then((res) => {
          if (res.success) {
            setModal(false)
            props.getData(props.data._id)
            setReason("")
            swal("Success", "Booking updated successfully", "success")
          } else {
            setModal(false)
            swal("Error", res.message, "error")
          }
        })
      }
    })
  }

  const activateService = () => {
    // ask for confirmation with sweet alert
    swal({
      title: "Activate this booking?",
      // text: "You want to activate this booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const info = {
          servicebookingId: data._id,
          reason: reason,
        }
        console.log("info_____", info)
        callApi("servicebooking/reactivatesubscription", "POST", info).then(
          (res) => {
            if (res.success) {
              setModal(false)
              setReason("")
              swal("Success", "Booking Activated successfully", "success")
              props.getData(props.data._id)
            } else {
              setModal(false)
              swal("Error", res.message, "error")
              props.getData(props.data._id)
            }
          }
        )
      }
    })
  }

  return (
    <Modal
      show={isModalVisible}
      onHide={() => {
        setModal(!isModalVisible)
        props.onHide()
      }}
      size={"lg"}
    >
      <Modal.Body>
        <div className="text-center mt-2 mb-4">
          <h4>{data.isHold ? "Activate Reason" : "Deactivate Reason"}</h4>
          <div className="text-center">
            <input
              type="text"
              className="form-control"
              placeholder="Reason"
              onChange={(e) => {
                setReason(e.target.value)
              }}
              value={reason}
            />
          </div>
          <div className="mb-3 text-center mt-4">
            <button
              onClick={() => {
                updateBooking()
              }}
              className="btn btn-rounded btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default StopSubscription