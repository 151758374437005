import {IMAGE_UPLOAD_URL} from "../apiCaller"

const setImagesToS3 = async (e) => {
  if (e.target.files && e.target.files[0]) {
    console.log("images_____    ", e.target.files[0])
    var formdata = new FormData()
    formdata.append("file", e.target.files[0], e.target.files[0].name)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    const images = await fetch(IMAGE_UPLOAD_URL, requestOptions)
      .then((response) => response.json().then((json) => ({json, response})))
      .then(({json, response}) => {
        if (!response.ok) {
          return Promise.reject(json)
        }
        return json
      })
      .then(
        (response) => response,
        (error) => error
      )

    return images.link
  }
}

const uploadToS3 = async (e) => {
  console.log("im____", e)
  if (e && e) {
    var formdata = new FormData()
    formdata.append("file", e, e.name)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    const images = await fetch(IMAGE_UPLOAD_URL, requestOptions)
      .then((response) => response.json().then((json) => ({json, response})))
      .then(({json, response}) => {
        if (!response.ok) {
          return Promise.reject(json)
        }
        return json
      })
      .then(
        (response) => response,
        (error) => error
      )

    return images.link
  }
}

export {setImagesToS3, uploadToS3}
