import React, {useState, useEffect} from "react"
import {Row, Col, Card, OverlayTrigger, Modal} from "react-bootstrap"
import {Link} from "react-router-dom"
import moment from "moment"
import callApi from "../utils/apiCaller"
import swal from "sweetalert"
import HyperDatepicker from "./Datepicker"

const ExtendSubscription = (props) => {
  const data = props.data ? props.data : {}
  const [isModalVisible, setModal] = useState(true)
  const [reason, setReason] = useState(data.reason)
  const [extendDate, setExtendDate] = React.useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const extendBooking = () => {
    setIsLoading(true)
    swal({
      title: `Do you want to extent the booking till ${moment(
        extendDate
      ).utc().utcOffset(240).format("DD-MM-YYYY")}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const reqBody = {
          serviceBookingId: data._id,
          end_date: extendDate,
          reason: reason,
        }

        callApi("servicebooking/extendfreetrial", "POST", reqBody).then(
          (res) => {
            if (res.success) {
              setModal(false)
              props.getData(props.data._id)
              setExtendDate(null)
              setReason("")
              swal("Success", res.message, "success")
            } else {
              setModal(false)
              swal("Error", res.message, "error")
            }
            setIsLoading(false)
          }
        )
      }
    })
  }

  return (
    <Modal
      show={isModalVisible}
      onHide={() => {
        setModal(!isModalVisible)
        props.onHide()
      }}
      size={"lg"}
    >
      <Modal.Body>
        <div className="mt-2 mb-3">
          <h4 className="mb-3">Extend Booking</h4>
          <label>Select Date</label>
          <HyperDatepicker
            hideAddon={true}
            value={extendDate}
            minDate={new Date(moment(data?.expiry_date).utc().utcOffset(240).format("YYYY-MM-DD"))}
            onChange={(date) => {
              setExtendDate(date)
            }}
          />
          {/* {new Date(moment(data?.expiry_date).format("YYYY-MM-DD"))} */}
          <label className="mt-3">Reason</label>
          <input
            type="text"
            className="form-control"
            placeholder="Reason"
            onChange={(e) => {
              setReason(e.target.value)
            }}
            value={reason}
          />
          <div className="mb-3 text-center mt-4">
            <button
              onClick={() => {
                extendBooking()
              }}
              disabled={isLoading}
              className="btn btn-rounded btn-primary"
            >
              {isLoading?'Updating...':'Update'}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ExtendSubscription
