// @flow
import {all, fork, put, takeEvery, call} from "redux-saga/effects"

import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  forgotPasswordConfirm,
} from "../../helpers/"

import {APICore, setAuthorization} from "../../helpers/api/apiCore"
import callApi from "../../utils/apiCaller"
import {setLoggedInUserDetails} from "../actions"
import {
  authApiResponseSuccess,
  authApiResponseError,
  userLoggedInSuccess,
  logoutUser,
} from "./actions"
import {AuthActionTypes} from "./constants"

const api = new APICore()

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({payload: {email, password}}) {
  try {
    const response = yield callApi("login", "POST", {email, password})
    const user = response.data
    console.log("response_______ logins", response)
    if (response.success) {
      sessionStorage.setItem(
        AuthActionTypes.LOGIN_USER_DETAILS,
        JSON.stringify(response.data)
      )
      // yield put(userLoggedInSuccess(user))
      console.log("redux stored -----")
      // yield put(setLoggedInUserDetails(user))

      // window.location.reload()
    } else {
      throw response.message
    }
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error))
    api.setLoggedInUser(null)
    setAuthorization(null)
  }
}

/**
 * Logout the user
 */
function* logout() {
  console.log("logout called in saga")
  sessionStorage.removeItem(AuthActionTypes.LOGIN_USER_DETAILS)
  yield put(logoutUser())
  api.setLoggedInUser(null)
  setAuthorization(null)
  try {
    yield call(logoutApi)
    console.log("logout success user removed ")

    api.setLoggedInUser(null)
    setAuthorization(null)
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}))
  } catch (error) {
    console.log("logout -errorrrrr", error)
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error))
  }
}

function* signup({payload: {fullname, email, password}}) {
  try {
    const response = yield call(signupApi, {fullname, email, password})
    const user = response.data
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error))
    api.setLoggedInUser(null)
    setAuthorization(null)
  }
}

function* forgotPassword({payload: {username}}) {
  try {
    const response = yield call(forgotPasswordApi, {username})
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    )
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error))
  }
}

function* forgotPasswordChange({payload: {data}}) {
  try {
    const response = yield call(forgotPasswordConfirm, data)
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.FORGOT_PASSWORD_CHANGE,
        response.data
      )
    )
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error)
    )
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login)
}

export function* watchLogout(): any {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout)
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup)
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword)
}

export function* watchForgotPasswordChange(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange)
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
  ])
}

export default authSaga
