import React, {useEffect, useState} from "react"
import {Col, Row, Card} from "react-bootstrap"
import {uploadToS3} from "../../utils/helpers/imageUploadHelper"
import Dropzone from "react-dropzone"
import {formatBytes} from "../../utils/helpers"

const ImageUploadSingle = (props) => {
  const [startJobImages, setStartJobImages] = useState(props.images || [])
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    if (props.images) {
      console.log("props.images", props.images)
      props.uploadedImagesUrl(startJobImages)
    }
  }, [])

  const uploadImages = async (e) => {
    let ima = await uploadToS3(e)
    let list = [...startJobImages]
    let obj = {
      url: ima,
      name: e.name,
      size: formatBytes(e.size),
    }
    list.push(obj)
    setStartJobImages(list)
    props.uploadedImagesUrl(list)
  }

  const startUrl = (i) => {
    let i_list = [...startJobImages]
    i_list.splice(i, 1)
    setStartJobImages(i_list)
    props.uploadedImagesUrl(i_list)
  }

  const onFileUpload = (e) => {
    uploadImages(e[0])
  }

  return (
    <div className="">
      <Col className=" ">
        <>
          {startJobImages && startJobImages.length > 0 ? (
            <div className="dropzone-previews" id="uploadPreviewTemplate">
              {(startJobImages || []).map((f, i) => {
                return (
                  <Col xl={12}>
                    <Card className="mt-1 mb-0 shadow-none border" key={i}>
                      <div className="p-2">
                        <Row className="align-items-center">
                          {(f?.url || f) && (
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                className="avatar-sm rounded bg-light"
                                alt={f?.name || "image"}
                                src={f?.url || f}
                              />
                            </Col>
                          )}
                          <Col className="ps-0">
                            <span to="#" className="text-muted fw-bold">
                              {f?.name || `image-${i + 1}`}
                            </span>
                            <p className="mb-0">
                              <strong>{f?.size}</strong>
                            </p>
                          </Col>
                          <Col className="text-end">
                            <div
                              to="#"
                              className="btn btn-link btn-lg text-muted shadow-none"
                            >
                              <i
                                className="dripicons-cross"
                                onClick={() => startUrl(i)}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                )
              })}
            </div>
          ) : (
            <Dropzone
              {...props}
              onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)}
            >
              {({getRootProps, getInputProps}) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <i className="h3 text-muted dripicons-cloud-upload"></i>
                    <h5>Drop files here or click here to upload.</h5>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
        </>
      </Col>
    </div>
  )
}

export default ImageUploadSingle
