import _ from "lodash"
import React from "react"
import {Card, Col, Offcanvas, Row} from "react-bootstrap"
import CalendarFull from "./CalendarFull"

const CalendarFullModal = (props) => {
  return (
    <div>
      <Offcanvas
        show={true}
        onHide={() => props.setShow(false)}
        placement="bottom"
        style={{
          marginTop: 30,
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
          width: "100%",
          height: "95%",
          backgroundColor: "#FAFBFE",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: -20,
            right: "50%",
            padding: 0,
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <div
            style={{
              backgroundColor: "#3b3c36",
              borderRadius: 300,
              height: 40,
              width: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="dripicons-cross text-white"
              style={{fontSize: 20}}
              onClick={() => props.setShow(false)}
            ></i>
          </div>
        </span>
        <Offcanvas.Body style={{paddingTop: 0}}>
          <Row className="mt-4">
            <Col xs={2}></Col>
            <Col xs={8}>
              <Card>
                <Card.Body>
                  <CalendarFull id={props.id} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default CalendarFullModal
