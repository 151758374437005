import logo from "./honc.png"
import "./App.css"
import "./assets/scss/Saas.scss"
import Routes from "./routes/Routes"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file

// function App() {
//   return (

//   )
// }

// export default App

type AppProps = {}

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
  return <Routes></Routes>
}

export default App
