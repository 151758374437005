import React, {useState, useEffect} from "react"
import {
  Col,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Button,
  Badge,
} from "react-bootstrap"
import callApi from "../../../utils/apiCaller"
import Table from "../../../components/Table"
import swal from "sweetalert"
import _ from "lodash"

function AssignCleanerComponent(props) {
  const data = props.data ? props.data : {}
  const [isModalVisible, setModal] = useState(true)
  const [partners, setPartners] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [showTimeSlot, setShowTimeSlot] = useState(false)
  const [timeSlotsFromApi, setTimeSlotsFromApi] = useState([])
  const [selectedPartner, setSelectedPartner] = useState()

  useEffect(() => {
    getLiftOfPartner()
  }, [])

  const NameColumnNew = ({row}) => {
    return (
      <>
        <div
          className="table-user"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {row?.original?.cleaner?.ownerId?.profileImage ? (
            <a
              href={row?.original?.cleaner?.ownerId?.profileImage}
              target="_blank"
            >
              <img
                src={row?.original?.cleaner?.ownerId?.profileImage}
                alt={
                  <i className="mdi mdi-account-circle font-size-24 text-primary"></i>
                }
                className="rounded-circle"
              />
            </a>
          ) : (
            <i
              style={{
                height: "30px",
                width: "30px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontSize: 35,
              }}
              className="mdi mdi-account-circle"
            ></i>
          )}
          <div className="ms-2">{row?.original?.name}</div>
        </div>
      </>
    )
  }

  const NameColumn = ({row}) => {
    return (
      <div className="table-user">
        <span className="text-body fw-semibold">
          {row.original.name} {}
          {row?.original?.role === "INDEPENDENT" ? (
            <span className="fw-bold me-6 badge badge-outline-primary">
              Independent
            </span>
          ) : null}
          {row?.original?.role === "AGENCY" ? (
            <span className="fw-bold me-2 d-block badge badge-outline-primary">
              Agency
            </span>
          ) : null}
        </span>
      </div>
    )
  }

  const LocationColumn = ({row}) => {
    return (
      <>
        {row?.original?.location?.map((i) => {
          return <span>➣ {i.name}</span>
        })}
      </>
    )
  }

  const ApartmentColumn = ({row}) => {
    return (
      <>
        {row?.original?.servicePlaceNameIds?.map((i, j) => {
          return <span key={j}>➣ {i.name}</span>
        })}
      </>
    )
  }

  const ActionColumn = ({row}) => {
    return (
      <>
        {row?.original?.isAssignedToCurrentBooking ? (
          <Badge
            className=" bg-success"
            style={{
              padding: "8px 18px",
              fontSize: "15px",
              borderRadius: "20px",
              opacity: 0.9,
            }}
          >
            <i>Assigned</i>
          </Badge>
        ) : (
          <div
            style={{
              cursor: "pointer",
            }}
            className="action-icon"
            onClick={() => {
              if (data.isSubscriptionModel) {
                let filterTimeSlots = row?.original?.timeSlot?.map((i) => {
                  return {
                    value: i.name,
                    label: i.name,
                    _id: i._id,
                    isSelected: false,
                  }
                })
                setSelectedPartner(row.original)
                setTimeSlotsFromApi(filterTimeSlots)
                setShowTimeSlot(true)
              } else {
                assignPartner(row)
              }
            }}
          >
            {" "}
            <i
              className="mdi mdi-square-edit-outline btn btn-primary"
              style={{fontSize: "16px"}}
            >
              Assign
            </i>
          </div>
        )}
      </>
    )
  }

  const assignPartner = (row) => {
    swal({
      title: "Are you sure ?",
      text: "You want to assign a new partner",
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.data._id) {
          let url = data.isSubscriptionModel
            ? "servicebooking/updatecleaner"
            : "ondemand/assign"

          const info = {}
          if (data.isSubscriptionModel) {
            info.servicebookingId = props.data._id
            info.servicepackageId = row.original._id
            info.timeSlotId = row.original.timeSlotId
          } else {
            info.servicebookingId = props.data._id
            info.assigned_cleaner_id = row.original._id
          }
          callApi(url, "POST", info).then((res) => {
            if (res.success) {
              swal("Success", "Partner assigned successfully", "success")
              setModal(false)
              props.getData(props.data._id)
              props.reload()
            } else {
              swal("Error", res.message, "error")
            }
          })
        }
      }
    })
  }

  const getLiftOfPartner = () => {
    setIsLoading(true)
    if (props.data._id) {
      let url = data.isSubscriptionModel
        ? "servicebooking/getcleaner"
        : "ondemand/getcleaners"

      callApi(url, "POST", {
        servicebookingId: props.data._id,
      }).then((res) => {
        setPartners(res.data)
        setIsLoading(false)
      })
    }
  }

  const packageCol = ({row}) => {
    return (
      <>
        <span className="d-flex">{row?.original?.service_package_name}</span>
        <span>{row?.original?.code && row?.original?.code}</span>
      </>
    )
  }

  const sizePerPageList = [
    {
      text: "50",
      value: 50,
    },
  ]
  const Sno = ({row}) => {
    return <span>{row?.index + 1}</span>
  }

  const columns = [
    {
      Header: "Partner name",
      accessor: "name",
      sort: true,
      Cell: NameColumnNew,
      classes: "table-user",
    },
    {
      Header: "Package name",
      Cell: packageCol,
    },
    {
      Header: "Apartments",
      Cell: ApartmentColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ]

  const columnsOnDemand = [
    {
      Header: "Partner name",
      accessor: "name",
      sort: true,
      Cell: NameColumn,
      classes: "table-user",
    },
    {
      Header: "Location",
      Cell: LocationColumn,
    },
    {
      Header: "Apartments",
      Cell: ApartmentColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ]

  const getSearchedPartners = (searchText) => {
    if (searchText) {
      let url = data.isSubscriptionModel
        ? "servicebooking/getcleaner"
        : "ondemand/getcleaners"

      callApi(url, "POST", {
        pageNum: 1,
        pageSize: 20,
        search: searchText,
      }).then((res) => {
        if (res.data) {
          setPartners(res.data)
        }
      })
    } else {
      getLiftOfPartner()
    }
  }

  const slotClicked = (slot) => {
    let filterTimeSlots = timeSlotsFromApi.map((i) => {
      if (i.value === slot.value) {
        i.isSelected = true
      } else {
        i.isSelected = false
      }
      return i
    })
    setTimeSlotsFromApi(filterTimeSlots)
  }

  const updatePartner = () => {
    let selectedTimeSlot = timeSlotsFromApi.filter((i) => i.isSelected)
    if (selectedTimeSlot.length === 0) {
      swal("Error", "Please select a time slot", "error")
      return
    } else {
      swal({
        title: "Are you sure ?",
        text: "You want to assign a new partner",
        icon: "warning",
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (props.data._id) {
            const info = {}
            info.servicebookingId = props.data._id
            info.servicepackageId = selectedPartner._id
            info.timeSlotId = selectedTimeSlot[0]._id

            callApi(`servicebooking/updatecleaner`, "POST", info).then(
              (res) => {
                if (res.success) {
                  swal("Success", "Partner assigned successfully", "success")
                  setModal(false)
                  props.getData(props.data._id)
                  props.reload()
                } else {
                  swal("Error", res.message, "error")
                }
              }
            )
          }
        }
      })
    }
  }

  return (
    <>
      <Modal
        show={isModalVisible}
        onHide={() => {
          setModal(!isModalVisible)
          props.onHide()
        }}
        size={"xl"}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="ps-3 ">
            {props.name || "Assign partner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ps-3 pe-3">
            {showTimeSlot ? (
              <>
                <button
                  class="btn"
                  type="button"
                  style={{marginLeft: 30}}
                  onClick={() => setShowTimeSlot(false)}
                >
                  <i class="dripicons-arrow-thin-left" size="10x"></i>
                  {"   "} Back
                </button>
                <div className="" style={styles.select}>
                  <h5>Selected partner</h5>
                  <div className="d-flex ms-2">
                    <div className="d-flex align-items-center">
                      <img
                        src={selectedPartner?.cleaner?.ownerId?.profileImage}
                        alt="profile"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 40,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ms-2">
                      <h5>{selectedPartner?.name}</h5>
                    </div>
                  </div>

                  <h5></h5>
                  <h5>Select time slot</h5>
                  <div className="ms-2">
                    {!_.isEmpty(timeSlotsFromApi) &&
                      timeSlotsFromApi.map((i) => (
                        <>
                          <button
                            key={i._id}
                            style={{
                              backgroundColor: i.isSelected
                                ? "#3944bc"
                                : "#EEEEEE",
                              color: i.isSelected ? "white" : "#858585",
                              marginRight: 12,
                              marginBottom: 8,
                              paddingRight: 24,
                              paddingLeft: 24,
                              borderRadius: 30,
                              paddingTop: 8,
                              paddingBottom: 8,
                              borderWidth: 0,
                              fontSize: 14,
                            }}
                            onClick={() => slotClicked(i)}
                          >
                            {i.isSelected && (
                              <i
                                className="mdi mdi-check-circle me-2"
                                style={{}}
                              ></i>
                            )}

                            {i.label}
                          </button>
                        </>
                      ))}
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <Button
                    variant="primary"
                    onClick={() => {
                      // setShowTimeSlot(false)
                      updatePartner()
                    }}
                  >
                    Reassign Partner
                  </Button>
                  {/* <LoadingButton>Reassign Partner</LoadingButton> */}
                </div>
              </>
            ) : (
              <div className="">
                <Col md={4} className="mb-2">
                  <span className="d-flex align-items-center">
                    <InputGroup
                      style={{
                        display: "flex",
                        border: "1px solid #00000050",
                        borderRadius: 4,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Form.Control
                        placeholder="Search...."
                        aria-label="Search...."
                        aria-describedby="basic-addon2"
                        style={{border: 0}}
                        onChange={(e) => {
                          setSearchInput(e.target.value)
                        }}
                        id="search"
                        value={searchInput}
                        onKeyDown={(event) =>
                          event.key === "Enter" &&
                          getSearchedPartners(searchInput)
                        }
                      ></Form.Control>
                      <div
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                          paddingLeft: 10,
                        }}
                        onClick={() => {
                          setSearchInput("")
                          getSearchedPartners(searchInput)
                        }}
                      >
                        <i className="mdi mr-1  dripicons-cross"></i>
                      </div>
                      <button
                        className="btn btn-block"
                        style={{
                          backgroundColor: "#2A882A",
                          color: "white",
                        }}
                        onClick={() => getSearchedPartners(searchInput)}
                      >
                        <i className="mdi mr-1"></i> Search
                      </button>
                    </InputGroup>
                  </span>
                </Col>
                {isLoading ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="d-block mx-auto"
                      size="lg"
                    />
                  </>
                ) : (
                  <>
                    {partners && (
                      <Table
                        columns={
                          data.isSubscriptionModel ? columns : columnsOnDemand
                        }
                        data={partners}
                        pageSize={50}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        tableClass="table-striped"
                        searchBoxClass="mt-2 mb-3"
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const styles = {
  select: {
    // justifyContent: "center",
    marginLeft: 50,
  },
}

export default AssignCleanerComponent
