import moment from "moment"
import {AppStateConstants} from "./appConstants"

const user_details = localStorage.getItem(
  AppStateConstants.LOGGED_IN_USER_DETAILS
)

const initialState = {
  closedTicketCount: 1,
  openTicketPageCount: 1,
  holdTicketPageCount: 1,
  onDemandNewBookingPageCount: 1,
  onDemandNotAssignedBookingPageCount: 1,
  onDemandAssignedBookingPageCount: 1,
  onDemandUnAssignedBookingPageCount: 1,
  onDemandInactiveBookingPageCount: 1,
  allUsersPageCount: 1,
  allPartnersPageCount: 1,
  allPackagesPageCount:1,
  onDemandCanceledBookingPageCount: 1,
  dailyCleaningJobsPageSize: 10,
  openedCallBackRequestTicketPageNumber:1,
closedCallBackRequestTicketPageNumber:1,
holdCallBackRequestTicketPageNumber:1,
openedResolutionTicketPageNumber:1,
holdResolutionTicketPageNumber:1,
closedResolutionTicketPageNumber:1,
callBackRequestTicketPageSize:10,
resolutionTicketPageSize:10,
reportedTicketPageSize:10,
openedReportedTicketsPageNumber:1,
closedReportedTicketPageNumber:1,
  onDemnadJobsPageSize: 10,
  pendingJobsPageSize: 10,
  reportedJobsPageSize: 10,
  complaintJobsPageSize: 10,
  partnerAttendancePageSize: 10,
  allPartnersPageSize: 10,
  partnerRatingsPageSize: 10,
  partnerTipsPageSize: 10,
  assignedBookingsPageSize: 10,
  allUsersPageSize: 10,
  openTicketPageSize: 10,
  holdTicketPageSize: 10,
  closedTicketPageSize: 10,
  servicePlacesPageSize: 10,
  servicePlacesLocationPageSize: 10,
  dailyCleaningServicePackagesPageSize: 10,
  partnersPackagaeListPageSize: 10,
  dailyCleaningServicePackagesPageSize: 10,
  carServicePackagesPageSize: 10,
  dailycleaningInactiveBookingsPageSize: 10,
  dailycleaningPaymentPendingBookingsPageSize: 10,
  dailyCleaningCancelledBookingsPageSize: 10,
  dailyCleaningUnAssignedBookingPageSize:10,
  dailyCleaningUnAssignedBookingPageCount:1,
  onDemandUnAssignedBookingPageSize: 10,
  onDemandAssignedBookingsPageSize: 10,
  onDemandCompletedBookingsPageSize: 10,
  onDemandCancelledBookingsPageSize: 10,
  bikesBrandsListPageSize: 10,
  carsBrandsListPageSize: 10,
  onDemandServicePackagePageSize: 10,
allPackagesPageSize:10,
allUserReferralPageCount:10,
  bikeServicePackagesPageSize: 10,
  partnersBookingListPageSize: 10,
  addonsPartnersPackageListPageSize: 10,
  partnerEveryDayRatingsPageSize: 10,
  partnerCombinedPocketHistoryPageSize: 10,
  userDetailsBookingsPageSize: 10,
  userDetailsPaymentsPageSize: 10,
  userWalletCardDetailsPageSize: 10,
  ratingByUsersPageSize: 10,
  referralsPageSize: 10,
  dailyCleaningAssignedBookingPageCount: 1,
  dailyCleaningNewBookingPageCount: 1,
  dailyCleaningInactiveBookingPageCount: 1,
  dailyCleaningPaymentPendingBookingPageCount: 1,
  notAttemtedDailyCleaningJobPageNumber: 1,
  completedDailyCleaningJobPageNumber: 1,
  approvalPendingDailyCleaningJobPageNumber: 1,
  notCleanDailyCleaningJobPageNumber: 1,
  noVehicleDailyCleaningPageNumber: 1,
  notattemptedJobsPageNumber: 1,
  scheduledOnDemandJobPageNumber: 1,
  startedOnDemandJobPageNumber: 1,
  completedOnDemandJobPageNumber: 1,
  approvalPendingOnDemandJobPageNumber: 1,
  notCleanOnDemandJobPageNumber: 1,
  noVehicleOnDemandPageNumber: 1,
  logged_in_user_details: user_details,
  partnerAttendenceStartedPageNumber: 1,
  partnerAttendencePresentPageNumber: 1,
  partnerAttendenceAbsentPageNumber: 1,
  reportedDamagePageNumber: 1,
  complaintJobsPageNumber: 1,
  servicePlacesPageNumber: 1,
  partnerAttendenceSelectedTab: "On-job",
  dailyCleaningJobSelectedTab: "Not Attempted",
  onDemandJobSelectedTab: "Not Attempted",
  apartmentDetailsSelectedTab: "Apartment Details",
  reportedTicketSelectedTab:"Opened",
  resolutionTicketSelectedTab: "Opened",
  callBackRequestTicketSelectedTab:"Opened",
  carBrandsPageNumber: 1,
  autodebitPageNumber: 1,
  autodebitPageSize: 10,
  carModelsPageNumber: 1,
  carVariantsPageNumber: 1,
  businessPagePaginationNumber: 1,
  servicePackagePageNumber: 1,
  bikeBrandsPageNumber: 1,
  bikeModelsPageNumber: 1,
  bikeVariantsPageNumber: 1,
  allUserReferralPageNumber:1,
  filterAtNewDailyCleaningBookings: null,
  onDemandNewBookingCount: 0,
  allSpotBookingsPageNumber:1,
  allSpotBookingsPageSize:10,

  dailyCleaningNewBookingPageCount:0,
  reportedJobsApprovedPageNumber: 1,
  reportedJobsNotApprovedPageNumber: 1,
  customerClaimedRefunds: null,
  freeTrialActiveBookingsPageNum: 1,
  freeTrialNotConvertedBookingsPageNum: 1,
  freeTrialConvertedBookingsPageNum: 1,
  filterDailyCleaningData: {},
  filterServicePackages: {},
  filterPartnerData: {},
  filterAssignedBookings: {},
  filterCallBackRequestTicketData:{},
  filterReportedTicketData: {},
  filterResolutionTicketData: {},
  filterDailyCleaningRefunds: {
    startDate: moment().utc().utcOffset(240).format("YYYY-MM-DD"),
    endDate: moment().utc().utcOffset(240).format("YYYY-MM-DD"),
  },
  refundDataSummary: null,
  notificationPageNumber: 1,
  filterNotificationData: {},
  notificationFilterPayLoad: {},
  globalTableData: [],
  usersPageCount: 1,
  autoDebitFilters: {
    status: "Active",
  },
  autoDebitUsersCount: {
    active_count: 0,
    inactive_count: 0,
  },
}

const AppState = (state = initialState, action) => {
  switch (action.type) {
    case AppStateConstants.CLOSED_TICKET_PAGE_COUNT:
      return {
        ...state,
        closedTicketCount: action.closedTicketCount,
      }
    case AppStateConstants.OPEN_TICKET_PAGE_COUNT:
      return {
        ...state,
        openTicketPageCount: action.openTicketPageCount,
      }
    case AppStateConstants.OPEN_TICKET_PAGE_SIZE:
      return {
        ...state,
        openTicketPageSize: action.openTicketPageSize,
      }
    case AppStateConstants.HOLD_TICKET_PAGE_SIZE:
      return {
        ...state,
        holdTicketPageSize: action.holdTicketPageSize,
      }
    case AppStateConstants.HOLD_TICKET_PAGE_COUNT:
      return {
        ...state,
        holdTicketPageCount: action.holdTicketPageCount,
      }
    case AppStateConstants.ON_DEMAND_NEW_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandNewBookingPageCount: action.onDemandNewBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandAssignedBookingPageCount:
          action.onDemandAssignedBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandUnAssignedBookingPageCount:
          action.onDemandUnAssignedBookingPageCount,
      }
      case AppStateConstants.DAILY_CLEANING_UN_ASSIGNED_BOOKING_PAGE_COUNT:
        return {
          ...state,
          dailyCleaningUnAssignedBookingPageCount:
            action.dailyCleaningUnAssignedBookingPageCount,
        }

        case AppStateConstants.DAILY_CLEANING_UNASSIGNED_BOOKING_PAGE_SIZE:
          return {
            ...state,
            dailyCleaningUnAssignedBookingPageSize:
              action.dailyCleaningUnAssignedBookingPageSize,
          }
    case AppStateConstants.ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandNotAssignedBookingPageCount:
          action.onDemandNotAssignedBookingPageCount,
      }
    case AppStateConstants.PARTNERS_RATING_PAGE_SIZE:
      return {
        ...state,
        partnerRatingsPageSize: action.partnerRatingsPageSize,
      }
    case AppStateConstants.PARTNER_TIPS_PAGE_SIZE:
      return {
        ...state,
        partnerTipsPageSize: action.partnerTipsPageSize,
      }
    case AppStateConstants.SERVICE_PLACES_PAGE_SIZE:
      return {
        ...state,
        servicePlacesPageSize: action.servicePlacesPageSize,
      }
    case AppStateConstants.SERVICE_PLACES_LOCATIONS_PAGE_SIZE:
      return {
        ...state,
        servicePlacesLocationPageSize: action.servicePlacesLocationPageSize,
      }
    case AppStateConstants.USER_DETAILS_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        userDetailsBookingsPageSize: action.userDetailsBookingsPageSize,
      }
    case AppStateConstants.USER_DETAILS_PAYMENTS_PAGE_SIZE:
      return {
        ...state,
        userDetailsPaymentsPageSize: action.userDetailsPaymentsPageSize,
      }
    case AppStateConstants.ON_DEMAND_SERVICE_PACKAGE_PAGE_SIZE:
      return {
        ...state,
        onDemandServicePackagePageSize: action.onDemandServicePackagePageSize,
      }
    case AppStateConstants.FILTER_SERVICE_PACKAGES:
      return {
        ...state,
        filterServicePackages: action.filterServicePackages,
      }
    case AppStateConstants.USER_WALLET_CARD_DETAILS_PAGE_SIZE:
      return {
        ...state,
        userWalletCardDetailsPageSize: action.userWalletCardDetailsPageSize,
      }
    case AppStateConstants.RATING_BY_USER_PAGE_SIZE:
      return {
        ...state,
        ratingByUsersPageSize: action.ratingByUsersPageSize,
      }
    case AppStateConstants.ASSIGNED_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        assignedBookingsPageSize: action.assignedBookingsPageSize,
      }
    case AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        dailycleaningInactiveBookingsPageSize:
          action.dailycleaningInactiveBookingsPageSize,
      }
      case AppStateConstants.DAILY_CLEANING_PAYMENT_PENDING_BOOKINGS_PAGE_SIZE:
        return {
          ...state,
          dailycleaningPaymentPendingBookingsPageSize:
            action.dailycleaningPaymentPendingBookingsPageSize,
        }
    case AppStateConstants.DAILY_CLEANING_CANCELLED_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        dailyCleaningCancelledBookingsPageSize:
          action.dailyCleaningCancelledBookingsPageSize,
      }
    case AppStateConstants.CAR_SERVICE_PACKAGES_PAGE_SIZE:
      return {
        ...state,
        carServicePackagesPageSize: action.carServicePackagesPageSize,
      }
    case AppStateConstants.BIKE_SERVICE_PACKAGES_PAGE_SIZE:
      return {
        ...state,
        bikeServicePackagesPageSize: action.bikeServicePackagesPageSize,
      }
    case AppStateConstants.BIKES_BRANDS_LIST_PAGE_SIZE:
      return {
        ...state,
        bikesBrandsListPageSize: action.bikesBrandsListPageSize,
      }
    case AppStateConstants.CARS_BRANDS_LIST_PAGE_SIZE:
      return {
        ...state,
        carsBrandsListPageSize: action.carsBrandsListPageSize,
      }
    case AppStateConstants.PARTNERS_PACKAGE_LIST_PAGE_SIZE:
      return {
        ...state,
        partnersPackagaeListPageSize: action.partnersPackagaeListPageSize,
      }
    case AppStateConstants.PARTNERS_BOOKING_LIST_PAGE_SIZE:
      return {
        ...state,
        partnersBookingListPageSize: action.partnersBookingListPageSize,
      }
      case AppStateConstants.ALL_USER_REFERRAL_PAGE_NUMBER:
        return {
          ...state,
          allUserReferralPageNumber: action.allUserReferralPageNumber,
        }
        case AppStateConstants.ALL_USER_REFERRAL_PAGE_COUNT:
          return {
            ...state,
            allUserReferralPageCount: action.allUserReferralPageCount,
          }
    case AppStateConstants.PARTNER_EVERY_DAY_RATINGS_PAGE_SIZE:
      return {
        ...state,
        partnerEveryDayRatingsPageSize: action.partnerEveryDayRatingsPageSize,
      }
    case AppStateConstants.PARTNER_COMBINED_POCKET_HISTORY_PAGE_SIZE:
      return {
        ...state,
        partnerCombinedPocketHistoryPageSize:
          action.partnerCombinedPocketHistoryPageSize,
      }
    case AppStateConstants.NOT_ATTEMPTED_JOBS_PAGE_NUMBER:
      return {
        ...state,
        notattemptedJobsPageNumber: action.notattemptedJobsPageNumber,
      }
    case AppStateConstants.ADDONS_PARTNERS_PACKAGE_LIST_PAGE_SIZE:
      return {
        ...state,
        addonsPartnersPackageListPageSize:
          action.addonsPartnersPackageListPageSize,
      }
    case AppStateConstants.ON_DEMAND_UNASSIGNED_BOOKING_PAGE_SIZE:
      return {
        ...state,
        onDemandUnAssignedBookingPageSize:
          action.onDemandUnAssignedBookingPageSize,
      }
    case AppStateConstants.DAILY_CLEANING_SERVICE_PACKAGES_PAGE_SIZE:
      return {
        ...state,
        dailyCleaningServicePackagesPageSize:
          action.dailyCleaningServicePackagesPageSize,
      }
    case AppStateConstants.ON_DEMNAD_ASSIGNED_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        onDemandAssignedBookingsPageSize:
          action.onDemandAssignedBookingsPageSize,
      }
    case AppStateConstants.ON_DEMAND_COMPLETED_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        onDemandCompletedBookingsPageSize:
          action.onDemandCompletedBookingsPageSize,
      }
    case AppStateConstants.ON_DEMAND_CANCELLED_BOOKINGS_PAGE_SIZE:
      return {
        ...state,
        onDemandCancelledBookingsPageSize:
          action.onDemandCancelledBookingsPageSize,
      }
    case AppStateConstants.ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandInactiveBookingPageCount:
          action.onDemandInactiveBookingPageCount,
      }
    case AppStateConstants.REPORTED_JOBS_PAGE_SIZE:
      return {
        ...state,
        reportedJobsPageSize: action.reportedJobsPageSize,
      }
    case AppStateConstants.COMPLAINT_JOBS_PAGE_SIZE:
      return {
        ...state,
        complaintJobsPageSize: action.complaintJobsPageSize,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_PAGE_SIZE:
      return {
        ...state,
        partnerAttendancePageSize: action.partnerAttendancePageSize,
      }
    case AppStateConstants.ALL_PARTNERS_PAGE_SIZE:
      return {
        ...state,
        allPartnersPageSize: action.allPartnersPageSize,
      }
    case AppStateConstants.ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandCanceledBookingPageCount:
          action.onDemandCanceledBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningNewBookingPageCount:
          action.dailyCleaningNewBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningAssignedBookingPageCount:
          action.dailyCleaningAssignedBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningInactiveBookingPageCount:
          action.dailyCleaningInactiveBookingPageCount,
      }
      case AppStateConstants.DAILY_CLEANING_PAYMENT_PENDING_BOOKING_PAGE_COUNT:
        return {
          ...state,
          dailyCleaningPaymentPendingBookingPageCount:
            action.dailyCleaningPaymentPendingBookingPageCount,
        }
        case AppStateConstants.FILTER_CALL_BACK_REQUEST_TICKET_DATA:
          return {
            ...state,
            filterCallBackRequestTicketData:
              action.filterCallBackRequestTicketData,
          }
          case AppStateConstants.FILTER_RESOLUTION_TICKET_DATA:
            return {
              ...state,
              filterResolutionTicketData:
                action.filterResolutionTicketData,
            }
            case AppStateConstants.FILTER_REPORTED_TICKET_DATA:
              return {
                ...state,
                filterReportedTicketData:
                  action.filterReportedTicketData,
              }
          case AppStateConstants.CALL_BACK_REQUEST_TICKET_SELECTED_TAB:
            return {
              ...state,
              callBackRequestTicketSelectedTab:
                action.callBackRequestTicketSelectedTab,
            }
            case AppStateConstants.RESOLUTION_TICKET_SELECTED_TAB:
              return {
                ...state,
                resolutionTicketSelectedTab:
                  action.resolutionTicketSelectedTab,
              }
              case AppStateConstants.REPORTED_TICKET_SELECTED_TAB:
                return {
                  ...state,
                  reportedTicketSelectedTab:
                    action.reportedTicketSelectedTab,
                }
  
            case AppStateConstants.CALL_BACK_REQUEST_TICKET_PAGE_SIZE:
              return {
                ...state,
                callBackRequestTicketPageSize:
                  action.callBackRequestTicketPageSize,
              }
  
              case AppStateConstants.RESOLUTION_TICKET_PAGE_SIZE:
              return {
                ...state,
                resolutionTicketPageSize:
                  action.resolutionTicketPageSize,
              }
              case AppStateConstants.REPORTED_TICKET_PAGE_SIZE:
                return {
                  ...state,
                  reportedTicketPageSize:
                    action.reportedTicketPageSize,
                }
              case AppStateConstants.CLOSED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:
                return {
                  ...state,
                  closedCallBackRequestTicketPageNumber:
                    action.closedCallBackRequestTicketPageNumber,
                }
                case AppStateConstants.CLOSED_RESOLUTION_TICKET_PAGE_NUMBER:
                  return {
                    ...state,
                    closedResolutionTicketPageNumber:
                      action.closedResolutionTicketPageNumber,
                  }
                  case AppStateConstants.CLOSED_REPORTED_TICKET_PAGE_NUMBER:
                    return {
                      ...state,
                      closedReportedTicketPageNumber:
                        action.closedReportedTicketPageNumber,
                    }
                case AppStateConstants.HOLD_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:
                  return {
                    ...state,
                    holdCallBackRequestTicketPageNumber:
                      action.holdCallBackRequestTicketPageNumber,
                  }
                  case AppStateConstants.HOLD_RESOLUTION_TICKET_PAGE_NUMBER:
                    return {
                      ...state,
                      holdResolutionTicketPageNumber:
                        action.holdResolutionTicketPageNumber,
                    }
                  case AppStateConstants.OPENED_CALL_BACK_REQUEST_TICKET_PAGE_NUMBER:
                    return {
                      ...state,
                      openedCallBackRequestTicketPageNumber:
                        action.openedCallBackRequestTicketPageNumber,
                    }
                    case AppStateConstants.OPENED_RESOLUTION_TICKET_PAGE_NUMBER:
                      return {
                        ...state,
                         openedResolutionTicketPageNumber:
                          action. openedResolutionTicketPageNumber,
                      }
                      case AppStateConstants.OPENED_REPORTED_TICKET_PAGE_NUMBER:
                        return {
                          ...state,
                           openedReportedTicketsPageNumber:
                            action. openedReportedTicketsPageNumber,
                        }
    case AppStateConstants.ALL_USERS_PAGE_COUNT:
      return {
        ...state,
        allUsersPageCount: action.allUsersPageCount,
      }
      case AppStateConstants.ALL_SPOT_BOOKINGS_PAGE_SIZE:
        return {
          ...state,
          allSpotBookingsPageSize:
            action.allSpotBookingsPageSize,
        }
        case AppStateConstants.ALL_SPOT_BOOKINGS_PAGE_NUMBER:
          return {
            ...state,
            allSpotBookingsPageNumber:
              action.allSpotBookingsPageNumber,
          }
    case AppStateConstants.ALL_USERS_PAGE_SIZE:
      return {
        ...state,
        allUsersPageSize: action.allUsersPageSize,
      }

      case AppStateConstants.ALL_PACKAGES_PAGE_COUNT:
        return {
          ...state,
          allPackagesPageCount: action.allPackagesPageCount,
        }
      case AppStateConstants.ALL_PACKAGES_PAGE_SIZE:
        return {
          ...state,
          allPackagesPageSize: action.allPackagesPageSize,
        }
    case AppStateConstants.ALL_PARTNERS_PAGE_COUNT:
      return {
        ...state,
        allPartnersPageCount: action.allPartnersPageCount,
      }
    case AppStateConstants.NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        notAttemtedDailyCleaningJobPageNumber:
          action.notAttemtedDailyCleaningJobPageNumber,
      }
    case AppStateConstants.COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        completedDailyCleaningJobPageNumber:
          action.completedDailyCleaningJobPageNumber,
      }
    case AppStateConstants.APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        approvalPendingDailyCleaningJobPageNumber:
          action.approvalPendingDailyCleaningJobPageNumber,
      }
    case AppStateConstants.NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        notCleanDailyCleaningJobPageNumber:
          action.notCleanDailyCleaningJobPageNumber,
      }
    case AppStateConstants.NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        noVehicleDailyCleaningPageNumber:
          action.noVehicleDailyCleaningPageNumber,
      }

    case AppStateConstants.SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        scheduledOnDemandJobPageNumber: action.scheduledOnDemandJobPageNumber,
      }
    case AppStateConstants.STARTED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        startedOnDemandJobPageNumber: action.startedOnDemandJobPageNumber,
      }
    case AppStateConstants.DAILY_CLEANING_JOBS_PAGE_SIZE:
      return {
        ...state,
        dailyCleaningJobsPageSize: action.dailyCleaningJobsPageSize,
      }
    case AppStateConstants.ON_DEMAND_JOBS_PAGE_SIZE:
      return {
        ...state,
        onDemnadJobsPageSize: action.onDemnadJobsPageSize,
      }
    case AppStateConstants.PENDING_JOBS_PAGE_SIZE:
      return {
        ...state,
        pendingJobsPageSize: action.pendingJobsPageSize,
      }
    case AppStateConstants.CLOSED_TICKET_PAGE_SIZE:
      return {
        ...state,
        closedTicketPageSize: action.closedTicketPageSize,
      }
    case AppStateConstants.PARTNER_TIPS_PAGE_SIZE:
      return {
        ...state,
        closedTicketPageSize: action.closedTicketPageSize,
      }
    case AppStateConstants.COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        completedOnDemandJobPageNumber: action.completedOnDemandJobPageNumber,
      }
    case AppStateConstants.APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        approvalPendingOnDemandJobPageNumber:
          action.approvalPendingOnDemandJobPageNumber,
      }
    case AppStateConstants.NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        notCleanOnDemandJobPageNumber: action.notCleanOnDemandJobPageNumber,
      }
    case AppStateConstants.NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        noVehicleOnDemandPageNumber: action.noVehicleOnDemandPageNumber,
      }

    case AppStateConstants.LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        logged_in_user_details: action.logged_in_user_details,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendenceStartedPageNumber:
          action.partnerAttendenceStartedPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendencePresentPageNumber:
          action.partnerAttendencePresentPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendenceAbsentPageNumber:
          action.partnerAttendenceAbsentPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDENCE_SELECTED_TAB:
      return {
        ...state,
        partnerAttendenceSelectedTab: action.partnerAttendenceSelectedTab,
      }
    case AppStateConstants.APARTMENT_DETAILS_SELECTED_TAB:
      return {
        ...state,
        apartmentDetailsSelectedTab: action.apartmentDetailsSelectedTab,
      }
    case AppStateConstants.DAILY_CLEANING_JOB_SELECTED_TAB:
      return {
        ...state,
        dailyCleaningJobSelectedTab: action.dailyCleaningJobSelectedTab,
      }
    case AppStateConstants.ON_DEMAND_JOB_SELECTED_TAB:
      return {
        ...state,
        onDemandJobSelectedTab: action.onDemandJobSelectedTab,
      }

    case AppStateConstants.REPORTED_DAMAGE_PAGE_NUMBER:
      return {
        ...state,
        reportedDamagePageNumber: action.reportedDamagePageNumber,
      }
    case AppStateConstants.COMPLAINT_JOBS_PAGE_NUMBER:
      return {
        ...state,
        complaintJobsPageNumber: action.complaintJobsPageNumber,
      }
    case AppStateConstants.SERVICE_PLACES_PAGE_NUMBER:
      return {
        ...state,
        servicePlacesPageNumber: action.servicePlacesPageNumber,
      }
    case AppStateConstants.SET_CAR_BRANDS_PAGE_NUMBER:
      return {
        ...state,
        carBrandsPageNumber: action.carBrandsPageNumber,
      }
    case AppStateConstants.SET_AUTO_DEBITS_PAGE_NUMBER:
      return {
        ...state,
        autodebitPageNumber: action.autodebitPageNumber,
      }
    case AppStateConstants.SET_AUTO_DEBITS_PAGE_SIZE:
      return {
        ...state,
        autodebitPageSize: action.autodebitPageSize,
      }
    case AppStateConstants.REFERRALS_PAGE_SIZE:
      return {
        ...state,
        referralsPageSize: action.referralsPageSize,
      }
    case AppStateConstants.SET_CAR_VARIANTS_PAGE_NUMBER:
      return {
        ...state,
        carVariantsPageNumber: action.carVariantsPageNumber,
      }
    case AppStateConstants.SET_CAR_MODELS_PAGE_NUMBER:
      return {
        ...state,
        carModelsPageNumber: action.carModelsPageNumber,
      }
    case AppStateConstants.SERVICE_PACKAGE_PAGE_NUMBER:
      return {
        ...state,
        servicePackagePageNumber: action.servicePackagePageNumber,
      }
    case AppStateConstants.SET_BIKE_BRANDS_PAGE_NUMBER:
      return {
        ...state,
        bikeBrandsPageNumber: action.bikeBrandsPageNumber,
      }
    case AppStateConstants.SET_BIKE_MODELS_PAGE_NUMBER:
      return {
        ...state,
        bikeModelsPageNumber: action.bikeModelsPageNumber,
      }
    case AppStateConstants.SET_BIKE_VARIANTS_PAGE_NUMBER:
      return {
        ...state,
        bikeVariantsPageNumber: action.bikeVariantsPageNumber,
      }
    case AppStateConstants.SET_BUSINESS_PAGE_PAGINATION_NUMBER:
      return {
        ...state,
        businessPagePaginationNumber: action.businessPagePaginationNumber,
      }
    case AppStateConstants.FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS:
      return {
        ...state,
        filterAtNewDailyCleaningBookings:
          action.filterAtNewDailyCleaningBookings,
      }
    case AppStateConstants.ON_DEMAND_NEW_BOOKING_COUNT:
      return {
        ...state,
        onDemandNewBookingCount: action.onDemandNewBookingCount,
      }
      case AppStateConstants.DAILY_CLEANING_NEW_BOOKING_COUNT:
        return {
          ...state,
          dailyCleaningNewBookingCount: action.dailyCleaningNewBookingCount,
        }
    case AppStateConstants.REPORTED_JOBS_APPROVED_PAGE_NUMBER:
      return {
        ...state,
        reportedJobsApprovedPageNumber: action.reportedJobsApprovedPageNumber,
      }
    case AppStateConstants.REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER:
      return {
        ...state,
        reportedJobsNotApprovedPageNumber:
          action.reportedJobsNotApprovedPageNumber,
      }
    case AppStateConstants.CUSTOMER_CLAIMED_REFUNDS:
      return {
        ...state,
        customerClaimedRefunds: action.customerClaimedRefunds,
      }
    case AppStateConstants.FREE_TRIAL_ACTIVE_BOOKINGS_PAGE_NUM:
      return {
        ...state,
        freeTrialActiveBookingsPageNum: action.freeTrialActiveBookingsPageNum,
      }
    case AppStateConstants.FREE_TRIAL_NOT_CONVERTED_BOOKINGS_PAGE_NUM:
      return {
        ...state,
        freeTrialNotConvertedBookingsPageNum:
          action.freeTrialNotConvertedBookingsPageNum,
      }
    case AppStateConstants.FREE_TRIAL_CONVERTED_BOOKINGS_PAGE_NUM:
      return {
        ...state,
        freeTrialConvertedBookingsPageNum:
          action.freeTrialConvertedBookingsPageNum,
      }
    case AppStateConstants.FILTER_DAILY_CLEANING_DATA:
      return {
        ...state,
        filterDailyCleaningData: action.filterDailyCleaningData,
      }
    case AppStateConstants.FILTER_PARTNERS_DATA:
      return {
        ...state,
        filterPartnerData: action.filterPartnerData,
      }

    case AppStateConstants.FILTER_ASSIGNED_BOOKINGS:
      return {
        ...state,
        filterAssignedBookings: action.filterAssignedBookings,
      }
    case AppStateConstants.FILTER_POCKET_HISTORY:
      return {
        ...state,
        filterPocketHistory: action.filterPocketHistory,
      }
    case AppStateConstants.FILTER_DAILY_CLEANING_REFUNDS:
      return {
        ...state,
        filterDailyCleaningRefunds: action.filterDailyCleaningRefunds,
      }
    case AppStateConstants.REFUND_DATA_SUMMARY:
      return {
        ...state,
        refundDataSummary: action.refundDataSummary,
      }
    case AppStateConstants.SET_NOTIFICATION_PAGE_NUMBER:
      return {
        ...state,
        notificationPageNumber: action.notificationPageNumber,
      }
    case AppStateConstants.FILTER_NOTIFICATION_DATA:
      return {
        ...state,
        filterNotificationData: action.filterNotificationData,
      }
    case AppStateConstants.NOTIFICATION_FILTER_PAYLOAD:
      return {
        ...state,
        notificationFilterPayLoad: action.notificationFilterPayLoad,
      }
    case AppStateConstants.GLOBAL_TABLE_DATA:
      return {
        ...state,
        globalTableData: action.globalTableData,
      }
    case AppStateConstants.USERS_PAGE_COUNT:
      return {
        ...state,
        usersPageCount: action.usersPageCount,
      }
    case AppStateConstants.AUTO_DEBIT_FILTERS:
      return {
        ...state,
        autoDebitFilters: action.autoDebitFilters,
      }
    case AppStateConstants.AUTO_DEBIT_USERS_COUNT:
      return {
        ...state,
        autoDebitUsersCount: action.autoDebitUsersCount,
      }
    case AppStateConstants.LOGOUT_USER:
      return {
        initialState,
      }
    default:
      return state
  }
}
export default AppState
