import React, { useState } from "react";
import callApi from "../../../utils/apiCaller";
import { useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import Table from "../../../components/Table";
import moment from "moment";
import { currencyConversion } from "../../../helpers/currency";

const TransactionDetails = ({ id,customerId,bookingNum }) => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransactionsDetails();
  }, []);
  const getTransactionsDetails = async () => {
    setLoading(true);
    const res = await callApi("pocket/getalltransactions", "post", {
      bookingId: id,
      customerId:customerId,
      bookingNum:bookingNum
    });
    try {
      if (res.success) {
        setTransactionData(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const transactionDate = ({ row }) => {
    return (
      <span>
        <strong>
          {moment(row?.original?.transaction_date).utc().utcOffset(240).format("MMM DD YYYY")}
        </strong>
        <small>
          {moment(row?.original?.transaction_date).utc().utcOffset(240).format(" hh:mm a")}
        </small>
      </span>
    );
  };

  const Amount = ({ row }) => {
    return (
      <span>
        <strong
          className=""
          style={{
            color: row?.original?.type === "credit" ? "green" : "red",
          }}
        >
          {row?.original?.type === "credit"
            ? `+ OMR ${currencyConversion(row?.original?.amount)}`
            : `- OMR ${currencyConversion(row?.original?.amount)}`}
          


        </strong>
      </span>
    );
  };

  const Balance = ({ row }) => {
    return <strong className="">
       {`OMR ${currencyConversion(row?.original?.new_balance)}`}
   
 
   

    </strong>;
  };
  const transactionTitle = ({ row }) => {
    return <strong>{row?.original?.tittle}</strong>;
  };

  const columns = [
    {
      Header: "S.NO",
      Cell: ({ row }) => {
        return <strong>{row.index + 1}.</strong>;
      },
    },
    {
      Header: "Transactions Date",
      Cell: transactionDate,
    },
    {
      Header: "Amount",
      Cell: Amount,
    },
    {
      Header: "Balance",
      Cell: Balance,
    },
    {
      Header: "Transaction Title",
      Cell: transactionTitle,
    },
  ];

  return (
    <Card>
      <Card.Body>
        <Card.Title>Transaction History</Card.Title>
        {loading ? (
          <Spinner
            animation="border"
            variant="primary"
            className="d-block mx-auto"
            size="lg"
          />
        ) : transactionData.length > 0 ? (
          <Table
            columns={columns}
            data={transactionData || []}
            // pageSize={100}
            // sizePerPageList={[{ text: "20", value: 20 }]}
            isSortable
            theadClass="table-light"
            searchBoxClass="mt-2 mb-3"
          />
        ) : (
          <strong
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            No transaction history
          </strong>
        )}
      </Card.Body>
    </Card>
  );
};

export default TransactionDetails;
