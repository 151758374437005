// @flow
import React, {useState, useEffect} from "react"
import {Row, Col, Card, Spinner, Form} from "react-bootstrap"
import moment from "moment"
import PageTitle from "../../../components/PageTitle"
import ReactPaginate from "react-paginate"
import callApi from "../../../utils/apiCaller"
import Table from "../../../components/Table"
import RefundCard from "./components/RefundCard"
import DaysSelector from "../../components/dropdown/DaysSelector"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import RefundCardSmall from "./components/RefundCardSmall"
import {
  setCustomerClaimedRefunds,
  setFilterDailyCleaningRefunds,
  setRefundDataSummary,
} from "../../../redux/actions"
import { currencyConversion } from "../../../helpers/currency"

const DailyCleaningRefunds = () => {
  const dispatch = useDispatch()
  const customerClaimedRefunds = useSelector(
    (state) => state.AppState.customerClaimedRefunds
  )
  const filterDailyCleaningRefunds = useSelector(
    (state) => state.AppState.filterDailyCleaningRefunds
  )
  const refundDataSummary = useSelector(
    (state) => state.AppState.refundDataSummary
  )
  const [refundData, setRefundData] = React.useState(null)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageCount, setpageCount] = useState(
    customerClaimedRefunds?.pageCount || 1
  )
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({
    startDate: filterDailyCleaningRefunds.startDate,
    endDate: filterDailyCleaningRefunds.endDate,
  })
  const [bookingsList, setBookingList] = React.useState(null)
  const [pageNumber, setPageNumber] = useState(
    filterDailyCleaningRefunds?.pageNumber || 1
  )

  useEffect(() => {
    getData({
      startDate: filterDailyCleaningRefunds.startDate,
      endDate: filterDailyCleaningRefunds.endDate,
    })

    fetchData(filterDailyCleaningRefunds?.pageNumber, {
      startDate: filterDailyCleaningRefunds.startDate,
      endDate: filterDailyCleaningRefunds.endDate,
    })
  }, [])

  const getData = ({startDate, endDate}) => {
    setDataLoading(true)
    callApi(`pocketrefund/totalrefund`, "POST", {
      startDate,
      endDate,
    }).then((res) => {
      setDataLoading(false)
      setRefundData(res.data)
      dispatch(setRefundDataSummary(res.data))
    })
  }

  const NameColumn = ({row}) => {
    return (
      <Link
        to={`/users/user-details?id=${row?.original?.customerId}&tab=wallet`}
      >
        <span className="table-user" style={{}}>
          {row?.original?.customerName}
        </span>
      </Link>
    )
  }

  const autoColumn = [
    {
      Header: "Date",
      Cell: ({row}) => {
        return (
          <span>
            {row?.original?.actionDate && (
              <span className="fw-bold">
                {moment(row?.original?.actionDate).utc().utcOffset(240).format("DD MMM, YY")}
              </span>
            )}
          </span>
        )
      },
    },
    {
      Header: "Customer",
      Cell: NameColumn,
    },
    {
      Header: "Vehicle Number",
      accessor: "license_number",
    },
    {
      Header: "Apartment",
      accessor: "apartment_name",
    },
    {
      Header: "Refund Claimed",
      Cell: ({row}) => {
        return (
          <span className="font-16 fw-bold">
            OMR {currencyConversion(row.original.refundAmount)}
       
            

            
            </span>
        )
      },
    },
  ]

  let newColumns = [...autoColumn]
  newColumns.unshift({
    Header: "#",
    accessor: "serialNo",
    Cell: ({row}) => {
      return <span>{limit * (pageNumber - 1) + row.index + 1}</span>
    },
  })

  const optionHandler = (e) => {
    setLimit(e.target.value)
  }

  const handlePageClick = async (data) => {
    setBookingList([])
    let locCurrentPage = data.selected
    locCurrentPage = locCurrentPage + 1
    setPageNumber(locCurrentPage)

    dispatch(
      setFilterDailyCleaningRefunds({
        ...filterDailyCleaningRefunds,
        pageNumber: locCurrentPage,
      })
    )
    setIsLoading(true)
    filters ? fetchData(locCurrentPage, filters) : fetchData(locCurrentPage)
  }

  const fetchData = (num, filterType) => {
    let data = {
      pageNum: num,
      pageSize: limit,
      claimStatus: "claimed",
    }
    if (filterType) {
      data = {
        ...data,
        ...filterType,
      }
    }
    if (filterType?.search) {
      data.search = filterType.search.trim()
    }
    // setIsLoading(true)
    callApi("pocketrefund/refundlist", "POST", data).then((res) => {
      if (res.data) {
        window.scrollTo(0, 0)
        setBookingList(res.data.task)
        dispatch(
          setCustomerClaimedRefunds({
            ...customerClaimedRefunds,
            data: res.data.task,
            pageCount: res.data?.totalPages || 1,
          })
        )
        setpageCount(res.data?.totalPages || 1)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }

  return (
    <div>
      <div style={{marginLeft: 4}}>
        <PageTitle
          breadCrumbItems={[
            {
              label: "Daily Cleaning Refunds",
              active: true,
            },
          ]}
          title={"Daily Cleaning Refunds"}
        />
      </div>
      <Card>
        <Card.Body>
          <DaysSelector
            startDate={filterDailyCleaningRefunds.startDate}
            endDate={filterDailyCleaningRefunds.endDate}
            dateSelected={(info) => {
              setIsLoading(true)
              dispatch(
                setCustomerClaimedRefunds({
                  ...customerClaimedRefunds,
                  data: [],
                  pageCount: 1,
                })
              )
              getData({
                startDate: moment(info.startDate).utc().utcOffset(240).format("YYYY-MM-DD"),
                endDate: moment(info.endDate).utc().utcOffset(240).format("YYYY-MM-DD"),
              })
              fetchData(1, {
                startDate: moment(info.startDate).utc().utcOffset(240).format("YYYY-MM-DD"),
                endDate: moment(info.endDate).utc().utcOffset(240).format("YYYY-MM-DD"),
              })
              setFilters({
                startDate: moment(info.startDate).utc().utcOffset(240).format("YYYY-MM-DD"),
                endDate: moment(info.endDate).utc().utcOffset(240).format("YYYY-MM-DD"),
              })
              dispatch(
                setFilterDailyCleaningRefunds({
                  ...filterDailyCleaningRefunds,
                  startDate: moment(info.startDate).utc().utcOffset(240).format("YYYY-MM-DD"),
                  endDate: moment(info.endDate).utc().utcOffset(240).format("YYYY-MM-DD"),
                  pageNumber: 1,
                })
              )
              setPageNumber(1)
            }}
          />
          <Row
            style={{
              padding: "0px 10px",
              marginTop: 14,
            }}
          >
            <Col md={12} style={{...styles.card}}>
              <RefundCard data={refundDataSummary} dataLoading={dataLoading} />
            </Col>
            {/* <div style={{width: 10}}></div> */}
            {/* <Col md={6} style={{...styles.card}}>
              <RefundSplitByCategory
                data={refundDataSummary}
                dataLoading={dataLoading}
              />
            </Col> */}
          </Row>
          <RefundCardSmall data={refundDataSummary} dataLoading={dataLoading} />
          <div className="mt-4 border-top pt-1">
            {isLoading ? (
              <>
                <Spinner
                  animation="border"
                  variant="primary"
                  className="d-block mx-auto"
                  size="lg"
                />
              </>
            ) : (
              <>
                {/* <ul className="nav nav-tabs nav-bordered flex-1 d-flex mb-2">
                  {tabs.map((group, index) => {
                    return (
                      <li
                        key={index}
                        className="nav-item d-flex align-items-center flex-1"
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                          display: "flex",
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => setselectedTab(group)}
                      >
                        <Link
                          to="#"
                          className={classNames("nav-link", "py-2", {
                            active: selectedTab === group,
                          })}
                          style={{
                            display: "flex",
                            flex: 1,
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {group}
                        </Link>
                      </li>
                    )
                  })}
                </ul> */}
                {customerClaimedRefunds?.data &&
                  customerClaimedRefunds?.data.length > 0 && (
                    <>
                      <div className="font-16 fw-bold mt-2">
                        Refund claimed customers list
                      </div>
                      <div className="mt-2">
                        <Table
                          columns={newColumns}
                          data={customerClaimedRefunds?.data || []}
                          pageSize={limit}
                          sizePerPageList={[{text: limit, value: limit}]}
                          isSortable
                          theadClass="table-light"
                        />
                      </div>
                      <Row>
                        <Col lg={12} className="mt-3">
                          <Row>
                            <Col lg={6}>
                              <div className="d-lg-flex align-items-center text-center pb-1">
                                <label className="me-1">Display :</label>
                                <select
                                  onChange={(e) => {
                                    optionHandler(e)
                                  }}
                                  className="form-select d-inline-block w-auto"
                                >
                                  <option value={limit}>{limit}</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <ReactPaginate
                                previousLabel="previous"
                                nextLabel="next"
                                breakLabel="..."
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                onPageChange={(customerClaimedRefunds) => {
                                  handlePageClick(customerClaimedRefunds)
                                }}
                                initialPage={pageNumber - 1}
                                disableInitialCallback
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default DailyCleaningRefunds

const styles = {
  card: {
    padding: 20,
    border: "2px solid #00000026",
    borderRadius: 5,
    borderRadius: 6,
    flex: 1,
  },
}
