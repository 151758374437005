import React, {useState, useEffect} from "react"
import {Row, Col, Card, Spinner, Modal} from "react-bootstrap"
import moment from "moment"
import callApi from "../../utils/apiCaller"
import swal from "sweetalert"
import _ from "lodash"
import {useSearchParams, Link, useNavigate} from "react-router-dom"
import classNames from "classnames"
import {setApartmentDetailsSelectedTab} from "../../redux/actions"
import {useDispatch, useSelector} from "react-redux"
import {FormInput} from "../../components"
import LoadingButton from "../../components/LoadingButton"
const LocationDetails = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [locationId, setLocationId] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState(null)
  const [isModalVisible, setModal] = useState(false)
  const [isModalVisible2, setModal2] = useState(false)
  const [tabs, setTabs] = useState(["Location Details"])
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [polygon_coordinates, setPolygonCoordinates] = useState(null)
  const [doingWork, setDoingWork] = useState(false)
  const [selectedTab, setselectedTab] = React.useState(
    useSelector((state) => state.AppState.apartmentDetailsSelectedTab)
  )

  const handleOnSelectTab = (tabName) => {
    dispatch(setApartmentDetailsSelectedTab(tabName))
    setselectedTab(tabName)
  }
  const handleLocationDetails = () => {
    callApi(`locations/${searchParams.get("id") || locationId}`, "get").then(
      (res) => {
        console.log("res_______", res)
        if (res.success) {
          setLat(res?.data?.location?.coordinates[0])
          setLng(res?.data?.location?.coordinates[1])

          setData(res.data)
        } else {
        }
      }
    )
  }

  useEffect(() => {
    if (searchParams.get("id")) {
      setLocationId(searchParams.get("id"))
    }
    handleLocationDetails()
  }, [])

  const checkAllFields = () => {
    if (polygon_coordinates) {
      if (!lat || !lng) {
        return false
      } else {
        return true
      }
    }
  }

  const updateLocation = () => {
    console.log('hi')
    if (checkAllFields()) {
      const info = {
        locationId: locationId,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        polygon_coordinates: polygon_coordinates,
      }

      console.log("info_____", info)
      setDoingWork(true)
      callApi(`locations/update`, "POST", info).then((res) => {
        console.log("res.data", res)
        if (res.success) {
          setDoingWork(false)
          setModal2(false)
          swal("Success", "Updated Successfully", "success")
          handleLocationDetails()
        } else {
          setModal2(false)
          setDoingWork(false)
          swal("Error", res.message, "error")
          handleLocationDetails()
        }
      })
    }
  }

  return (
    <Card>
      <Card.Body>
        <ul className="nav nav-tabs nav-bordered">
          {tabs.map((tab, index) => {
            return (
              <li
                key={index}
                className="nav-item"
                onClick={() => handleOnSelectTab(tab)}
              >
                <Link to="#" className={classNames("nav-link", "py-2", {})}>
                  {tab}
                </Link>
              </li>
            )
          })}
        </ul>
        <Col>
          <Row>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 className="mt-2">Service details</h4>
              <button
                className="btn btn-block bg-primary"
                style={{color: "white", marginTop: 20}}
                onClick={() => setModal2(true)}
              >
                Edit Location
              </button>
            </div>
          </Row>

          <Row>
            <Col lg={4}>
              <ul className="list-unstyled mb-0">
                <li>
                  <p className="mb-2">
                    <span className="d-block" style={{flex: 0.4}}>
                      Name
                    </span>
                    <span
                      className="fw-bold me-2 d-block"
                      style={{flex: 0.6, fontSize: "18px"}}
                    >
                      {data?.name}
                    </span>
                  </p>

                  <p className="mb-2">
                    <span className="d-block" style={{flex: 0.4}}>
                      Location Latitude
                    </span>
                    <span
                      className="fw-bold me-2 d-block"
                      style={{flex: 0.6, fontSize: "18px"}}
                    >
                      {data?.location?.coordinates[0]}
                    </span>
                  </p>
                  <p className="mb-2">
                    <span className="d-block" style={{flex: 0.4}}>
                      Location Longitude
                    </span>
                    <span
                      className="fw-bold me-2 d-block"
                      style={{flex: 0.6, fontSize: "18px"}}
                    >
                      {data?.location?.coordinates[1]}
                    </span>
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Card.Body>
      <Modal
        show={isModalVisible2}
        onHide={() => setModal2(!isModalVisible2)}
        // size={"lg"}
      >
        <Modal.Body>
          <div className="text-center mt-2 mb-4">
            <div className="text-center">
              <h5> Enter Latitude Longitude</h5>
            </div>
            <div className="text-center ">
              <input
                type="number"
                style={{
                  marginBottom: 10,
                }}
                className="form-control"
                placeholder={"Latitude"}
                defaultValue={data?.location?.coordinates[0]}
                value={lat}
                onChange={(e) => {
                  setLat(e.target.value)
                }}
              />
            </div>
            <div className="text-center ">
              <input
                type="number"
                style={{
                  marginBottom: 10,
                }}
                className="form-control"
                placeholder={"Longitude"}
                defaultValue={data?.location?.coordinates[1]}
                value={lng}
                onChange={(e) => {
                  setLng(e.target.value)
                }}
              />
            </div>
            {/* <Row>
              <Col>
                <FormInput
                  label=" Enter polygons"
                  type="textarea"
                  name="polygons"
                  placeholder="Polygons..."
                  defaultValue={data?.polygon?.coordinates}
                  rows="20"
                  containerClass={"mb-3"}
                  value={polygon_coordinates}
                  onChange={(e) => {
                    setPolygonCoordinates(e.target.value)
                  }}
                />
              </Col>
            </Row> */}
            <LoadingButton
                          disabled={doingWork ? true : false}

              className="btn btn-block bg-primary"
              style={{color: "white", marginTop: 20}}
              onClick={() => updateLocation()}
            >
              Update Location Details
            </LoadingButton>
          </div>
        </Modal.Body>
      </Modal>
    </Card>
  )
}

const styles = {
  list: {
    display: "flex",
    alignItems: "center",
  },
  row: {display: "flex", flexDirection: "row", marginTop: 10},
}

export default LocationDetails
