import React, {useState} from "react"
import _ from "lodash"
import moment from "moment"
import {Col, Card, Table, Offcanvas} from "react-bootstrap"
import classNames from "classnames"
import {Link} from "react-router-dom"
import OnDemandSchedule from "../../../../components/OnDemandSchedule"

const AddonsCardForBooking = (props) => {
  const data = props.data || {}
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [selectedData, setSelectedData] = useState({})

  return (
    <Card>
      <Card.Body>
        <Col>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
           <h4 className="">Free Internal Cleanings</h4>
              <button
                className="btn btn-success rounded"
                disabled={!data.isActive}
                onClick={() => {
                  setShowScheduleModal(true)
                  setSelectedData({})
                }}
              >
                + Schedule New
              </button>
            </div>
            {!_.isEmpty(data?.addons) && data?.isSubscriptionModel ? (
              <Table className="table-center mb-0 mt-0" size="sm" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Status</th>
                    <th>Vehicle number</th>
                    <th>Partner</th>
                    <th>Expire date</th>
                    <th>Scheduled date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.addons?.map((project, index) => {
                    return (
                      <tr key={index}>
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <span
                              className={classNames("badge badge-outline-dark")}
                            >
                              {project?.status}
                            </span>
                          </td>
                          <td>{project?.vehicleId?.license_number}</td>
                          <td>{project?.assigned_cleaner_id?.name}</td>
                          <td>
                            {moment(project?.expiry_date).utc().utcOffset(240).format(
                              "DD MMM, YYYY"
                            )}
                            {/* {project?.addonsdaysLeft >= 0 && (
                              <strong
                                style={{fontSize: 10}}
                                className="badge badge-outline-primary ms-1"
                              >
                                {project?.addonsdaysLeft} days left
                              </strong>
                            )} */}
                          </td>
                          <td>
                            <div
                              style={{alignItems: "center", display: "flex"}}
                            >
                              {project?.isScheduled ? (
                                moment(project?.service_start_date).utc().utcOffset(240).format(
                                  "DD MMM, YYYY"
                                )
                              ) : (
                                <span>Not Scheduled</span>
                              )}
                              {!project?.isCancelled &&
                                project?.status == "scheduled" && (
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: "green",
                                      color: "white",
                                      borderRadius: 20,
                                      height: 28,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      marginLeft: 6,
                                    }}
                                    onClick={() => {
                                      setShowScheduleModal(true)
                                      setSelectedData(project)
                                    }}
                                  >
                                    Reschedule
                                  </button>
                                )}
                            </div>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/bookings/booking-details",
                                search: `?id=${project._id}`,
                              }}
                              className="action-icon"
                              target={"_blank"}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Link>
                          </td>
                        </>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            ):
            <h5>No Free Internal Cleanings</h5>
            }
          </>
        </Col>
        {showScheduleModal && (
          <>
            <OnDemandSchedule
              data={selectedData}
              bookingData={props?.data}
              onHide={() => setShowScheduleModal(false)}
              getData={() => {
                setShowScheduleModal(false)
                props.getData(data._id)
              }}
            />
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default AddonsCardForBooking
