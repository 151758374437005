// @flow
import React, {useState, useEffect} from "react"
import {Link, useNavigate} from "react-router-dom"
import {Row, Col, Spinner, Card, InputGroup, Form} from "react-bootstrap"
import PageTitle from "../../components/PageTitle"
import ReactPaginate from "react-paginate"
import callApi from "../../utils/apiCaller"
import moment from "moment"
import classNames from "classnames"
import {useDispatch, useSelector} from "react-redux"
import Swal from "sweetalert2"

import {
  setOpenPageCount,
  setOpenTicketsPageSize,
} from "../../redux/appState/appActions"
import TicketDetails from "./TicketDetails"
import NewTable from "../../components/NewTable"
import Table from "../../components/Table"
import swal from "sweetalert"
import CreateTicket from "./components/CreateTicket"
import CreateTicketInternal from "./components/CreateTicketInternal"

const InternalTickets = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [partners, setPartners] = React.useState(null)
  const [pageCount, setpageCount] = useState(0)
  const [items, setItems] = useState([])
  const [limit, setLimit] = useState(50)
  const [searchInput, setSearchInput] = useState(
    sessionStorage.getItem("OPEN_TICKET_SEARCH_TEXT") || ""
  )
  const [loading, setLoading] = useState(false)
  const {openTicketPageCount} = useSelector((state) => ({
    openTicketPageCount: state.AppState.openTicketPageCount,
  }))
  const {openTicketPageSize} = useSelector((state) => ({
    openTicketPageSize: state.AppState.openTicketPageSize,
  }))
  const [totalCount, setTotalCount] = useState(0)
  const [state, setState] = useState({
    showTicketDetails: false,
    ticketDetailsId: null,
    showCreateTicket: false,
  })

  useEffect(() => {
    let sessionData = sessionStorage.getItem("OPEN_TICKET_SEARCH_TEXT")
    if (sessionData) {
      getSearchedList(1, {search: sessionData})
    } else {
      console.log("else")
      getData(openTicketPageCount)
    }
  }, [openTicketPageSize])

  const getData = async (num) => {
    const info = {
      // status: "OPEN",
      pageSize: openTicketPageSize,
      pageNum: num,
      ticketType: "internal",
    }

    let sessionData = sessionStorage.getItem("OPEN_TICKET_SEARCH_TEXT")
    if (sessionData) {
      getSearchedList(1, {search: sessionData})
    } else {
      callApi("admin/tickets-by-status", "POST", info).then((response) => {
        if (response.success) {
          const total = response.count
          setTotalCount(total)
          setpageCount(Math.ceil(total / openTicketPageSize))
          setPartners(response.data)
          setIsLoading(false)
        }
        else{
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response?.message || 'Something went wrong!',
              });
        }
      })
    }
  }

  const handlePageClick = async (data) => {
    setPartners([])
    let locCurrentPage = data.selected
    locCurrentPage = locCurrentPage + 1
    dispatch(setOpenPageCount(locCurrentPage))
    getData(locCurrentPage)
  }

  // const optionHandler = (e) => {
  //   setLimit(e.target.value)
  // }
  const optionHandler = (e) => {
    const selectedLimit = parseInt(e.target.value, 10)
    dispatch(setOpenTicketsPageSize(selectedLimit))
    dispatch(setOpenPageCount(1))
  }

  const getSearchedList = (searchText, filterType) => {
    let data = {
      // status: "OPEN",
      pageSize: openTicketPageSize,
      pageNum: 1,
    }
    if (filterType) {
      data = {
        ...data,
        ...filterType,
      }
    }
    if (filterType?.search) {
      data.search = filterType.search.trim()
    }
    setIsLoading(true)
    if (searchText) {
      sessionStorage.setItem("OPEN_TICKET_SEARCH_TEXT", filterType.search)
      callApi("admin/tickets-search", "POST", data).then((response) => {
        if (response.success) {
          const total = response.count
          setTotalCount(total || 1)
          setPartners(response.data)
          setIsLoading(false)
        } else {
          setIsLoading(true)
        }
      })
    } else {
      getData(1)
    }
  }

  const ticketId = ({row}) => {
    return (
      <div>
        <Link
          to={{
            pathname: "/support/ticket-details",
            search: `${row.original._id}`,
          }}
        >
          <p className="m-0">
            ID:{" "}
            {row.original._id?.slice(row.original._id.length - 8).toUpperCase()}
          </p>
          {moment(row.original.date).utc().utcOffset(240).format("DD-MMM-YYYY")}{" "}
          <small className="text-muted">
            {moment(row.original.date).utc().utcOffset(240).format("h:mm A")}
          </small>
        </Link>
      </div>
    )
  }

  /* name column render */
  const NameColumn = ({row}) => {
    return (
      <Link
        to={`/users/user-details?id=${row?.original?.customerId}`}
        target="_blank"
      >
        <div className="table-user">
          <p className="m-0">
            {row.original.name}
            <i className="mdi mdi-arrow-top-right-thin-circle-outline ms-1 text-primary"></i>
            {row.original.isResolutionSatisfied ? (
              <span
                className={`badge rounded-pill ${
                  row.original.isResolutionSatisfied == "YES"
                    ? "bg-success"
                    : "bg-danger"
                } ms-1`}
              >
                {row.original.isResolutionSatisfied}
              </span>
            ) : null}
          </p>
          <p className="m-0">{row.original.phone}</p>
        </div>
      </Link>
    )
  }

  const AssigneePerson = ({row}) => {
    return (
      <>
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {row.original.assigneeId ? row.original.assigneeId.name : "Customer"}
        </span>
      </>
    )
  }

  const AssignedToPerson = ({row}) => {
    return (
      <>
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {row.original.assignedToId ? row.original.assignedToId.name : ""}
        </span>
      </>
    )
  }

  const SkillColumn = ({row}) => {
    return (
      <div className="table-user">
        {row.original.service &&
          row.original.service.map((skill) => {
            return <div>{skill}</div>
          })}
      </div>
    )
  }

  /* action column render */
  const ActionColumn = ({row}) => {
    return (
      <>
        <Link
          to={{
            pathname: "/support/ticket-details",
          }}
          state={{
            ticketId: row.original._id,
          }}
          className="action-icon"
        >
          {" "}
          <i className="mdi mdi-eye"></i>
        </Link>
      </>
    )
  }

  const ApartmentColumn = ({row}) => {
    return (
      <div>
        {row?.original?.bookingId?.addressId?.community_id?.name ? (
          <p className="m-0">
            {row?.original?.bookingId?.addressId?.community_id?.name}
          </p>
        ) : (
          <p className="m-0">--NA--</p>
        )}
      </div>
    )
  }
  const VehicleColumn = ({row}) => {
    return (
      <div>
        {row?.original?.vehicleNo ? (
          <p className="m-0">{row?.original?.vehicleNo}</p>
        ) : (
          <p className="m-0">--NA--</p>
        )}
      </div>
    )
  }

  const copyColumn = ({row}) => {
    return (
      <div
        style={{
          cursor: "pointer",
        }}
      >
        <div
          className="ms-2"
          onClick={() => {
            const textToCopy = `${
              row?.original?.date
                ? moment(row?.original?.date).utc().utcOffset(240).format("DD MMM YYYY")
                : ""
            }, ${
              row?.original?.date
                ? moment(row?.original?.date).utc().utcOffset(240).format("hh:mm")
                : ""
            }, ${row?.original?.name}, ${row?.original?.phone}, ${
              row?.original?.issueType
            }, ${row?.original?.bookingId?.addressId?.community_id?.name}, ${
              row?.original?.vehicleNo
            }`
            navigator.clipboard.writeText(textToCopy)
            swal({
              title: "Copied!",
              text: "",
              icon: "success",
              timer: 1500,
            })
          }}
        >
          <i className="mdi mdi-content-copy text-primary"></i>
        </div>
      </div>
    )
  }

  const columns = [
    {
      Header: "SNo",
      accessor: "serialNo",
      Cell: ({row}) => {
        return (
          <span>
            {openTicketPageSize * (openTicketPageCount - 1) + row.index + 1}
          </span>
        )
      },
      width: "20px",
    },
    {
      Header: "Ticket Details",
      accessor: "ticketId",
      Cell: ticketId,
    },
    {
      Header: "Created By",
      sort: true,
      Cell: AssigneePerson,
    },
    {
      Header: "Assigned To",
      accessor: "assigneeId",
      Cell: AssignedToPerson,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({row}) => {
        return (
          <span
            className={classNames("badge", {
              "bg-success": row.original.status === "OPEN",
              "bg-danger": row.original.status === "CLOSE",
            })}
          >
            {row.original.status === "OPEN" ? "Open" : "Closed"}
          </span>
        )
      },
    },
    {
      Header: "Copy",
      Cell: copyColumn,
      width: "6px",
    },
  ]

  if (state.showTicketDetails) {
    return (
      <TicketDetails
        ticketId={state.ticketDetailsId}
        onButtonClick={() => {
          setState({
            ...state,
            showTicketDetails: false,
          })
        }}
      />
    )
  } else {
    return (
      <>
        <PageTitle
          breadCrumbItems={[{label: "Support", path: "/support"}]}
          title=" Internal Tickets"
        />

        <Card>
          <Card.Body>
            <Col>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputGroup
                    style={{
                      display: "flex",
                      border: "1px solid #00000050",
                      borderRadius: 4,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Form.Control
                      placeholder="Search...."
                      aria-label="Search...."
                      aria-describedby="basic-addon2"
                      style={{border: 0}}
                      onChange={(e) => {
                        setSearchInput(e.target.value)
                      }}
                      id="search"
                      value={searchInput}
                      onKeyDown={(event) =>
                        event.key === "Enter" &&
                        getSearchedList(1, {search: searchInput})
                      }
                    ></Form.Control>
                    <div
                      style={{
                        cursor: "pointer",
                        marginRight: 10,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        setSearchInput("")
                        getSearchedList(1, {search: ""})
                      }}
                    >
                      <i className="mdi mr-1  dripicons-cross"></i>
                    </div>
                    <button
                      className="btn btn-block"
                      style={{backgroundColor: "#2A882A", color: "white"}}
                      onClick={() => getSearchedList(1, {search: searchInput})}
                    >
                      <i className="mdi mr-1"></i> Search
                    </button>
                  </InputGroup>
                  <span className="mt-2 mb-2">
                    Total Internal Tickets : {totalCount}{" "}
                  </span>
                </div>

                <button
                  style={styles.button}
                  onClick={() => {
                    setState({
                      ...state,
                      showCreateTicket: true,
                    })
                  }}
                >
                  {"  "}
                  <i className="mdi mdi-plus"></i>
                  Create Internal Ticket
                </button>
                {state.showCreateTicket && (
                  <CreateTicketInternal
                    model={state.showCreateTicket}
                    setModel={(val) => {
                      setState({
                        ...state,
                        showCreateTicket: val,
                      })
                      getData(1)
                    }}
                  />
                )}
              </div>
            </Col>
            {isLoading ? (
              // If loading, display the loader
              <Spinner
                animation="border"
                variant="primary"
                className="d-block mx-auto"
                size="lg"
              />
            ) : (
              <>
                {partners ? (
                  <>
                    {partners && (
                      <>
                        <Table
                          columns={columns}
                          data={partners}
                          pageSize={50}
                          sizePerPageList={[{text: "50", value: 50}]}
                          isSortable
                          theadClass="table-light"
                          searchBoxClass="mt-2 mb-3"
                          onClick={(row) => {
                            navigate({
                              pathname: "/support/ticket-details",
                              search: row._id,
                            })
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="d-block mx-auto"
                      size="lg"
                    />
                  </>
                )}

                <Row>
                  <Col lg={12} className="mt-3">
                    <Row>
                      {/* <Col lg={6}>
                            <div className="d-lg-flex align-items-center text-center pb-1">
                              <label className="me-1">Display :</label>
                              <select
                                onChange={(e) => {
                                  optionHandler(e)
                                }}
                                className="form-select d-inline-block w-auto"
                              >
                                <option value={50}>50</option>
                              </select>
                            </div>
                          </Col> */}
                      <Col lg={6}>
                        <div className="d-lg-flex align-items-center text-center pb-1">
                          <label className="me-1">Display :</label>
                          <select
                            onChange={(e) => {
                              optionHandler(e)
                            }}
                            className="form-select d-inline-block w-auto"
                            value={openTicketPageSize}
                          >
                            {[10, 20, 50, 100].map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <ReactPaginate
                          previousLabel="previous"
                          nextLabel="next"
                          breakLabel="..."
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageClick}
                          // initialPage={openTicketPageCount - 1}
                          forcePage={openTicketPageCount - 1}
                          containerClassName="pagination justify-content-center"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          activeClassName="active"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </>
    )
  }
}

const styles = {
  button: {
    marginLeft: 10,
    cursor: "pointer",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2A882A",
    // borderColor: "#2A882A",
    color: "#fff",
    borderRadius: 5,
    padding: "0px 10px",
    border: "none",
  },
}

export default InternalTickets
