// @flow
import React, {useEffect, useState} from "react"
import {Row, Col, Card, Table, Modal} from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import {FormInput} from "../../../components"

import classNames from "classnames"
import moment from "moment"
import callApi from "../../../utils/apiCaller"
import swal from "sweetalert"
import {setImagesToS3} from "../../../utils/helpers/imageUploadHelper"
import {useNavigate} from "react-router-dom"
const ComplaintsInTickets = (props) => {
  const navigate = useNavigate()
  const list = props?.data
  const [ticketData, setTicketData] = React.useState([])
  const [data, setData] = useState(null)
  const [complaint, setComplaint] = React.useState([])
  const [complaintImage, setComplaintImage] = React.useState(
    props?.ticketData?.images || []
  )
  const [isModalVisible, setModal] = useState(false)
  const [state, setState] = React.useState({
    complaintData: null,
  })

  useEffect(() => {
    getAllComplaints()
  }, [])

  const createComplaint = () => {
    if (props.bookingData._id && complaint) {
      const info = {
        servicebookingId: props.bookingData._id,
        infoBody: complaint.map((item) => {
          return item.value
        }),
        infoImages: complaintImage,
        ticketId: props.ticketData._id,
      }
      callApi("servicebooking/createcomplaint", "POST", info).then((res) => {
        if (res.success) {
          setModal(false)
          props.getTicketData(props.ticketData._id)
          swal("Success", "Complaint created successfully", "success")
        } else {
          setModal(false)
          swal("Error", res.message, "error")
        }
      })
    }
  }

  const setStartImages = async (e) => {
    let ima = await setImagesToS3(e)
    let list = [...complaintImage]
    list.push(ima)
    setComplaintImage(list)
  }

  const startUrl = (i) => {
    let i_list = [...complaintImage]
    i_list.splice(i, 1)
    setComplaintImage(i_list)
  }

  const getAllComplaints = () => {
    if (props?.ticketData?.customerId) {
      const info = {
        userId: props.ticketData.customerId,
      }
      callApi(`complaint/getall`, "post", info).then((res) => {
        if (res.success) {
          const newData = [...res.data]
          setState((prevState) => ({
            ...prevState,
            complaintData: newData,
          }))
        } else {
        }
      })
    }
  }

  return (
    <>
      <h4>Complaints</h4>
      {props.bookingData.isSubscriptionModel && (
        <button
          className="btn btn-block text-end bg-primary"
          style={{color: "white"}}
          onClick={() => {
            setModal(!isModalVisible)
            setComplaintImage(props?.ticketData?.images || [])
          }}
        >
          Create a Complaint
        </button>
      )}
      <Table responsive classNames="table table-borderless table-nowrap mb-0">
        <thead classNames="table-light">
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Partner Name</th>
            <th>Ticket Id</th>
            <th>Is Resolved</th>
            <th>Vehicle Number</th>
          </tr>
        </thead>
        <tbody>
          {props.complaintData?.map((i, j) => (
            <tr
              key={j}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("i._id", i._id)
                navigate({
                  pathname: `/jobs/complaint-jobs-details`,
                  search: `?id=${i._id}`,
                })
              }}
            >
              <td>{j + 1}</td>
              <td>
                {moment(i?.createdAt)
                  .utc()
                  .utcOffset(330)
                  .utc().utcOffset(240).format("DD-MM-YYYY (h:mm a)")}
              </td>
              <td>{i?.cleaner?.name}</td>
              <td>
                {" "}
                #{i?.ticketId?.slice(i?.ticketId?.length - 8).toUpperCase()}
              </td>
              <td>
                {i?.isResolved ? (
                  <span className="badge bg-success">Yes</span>
                ) : (
                  <span className="badge bg-danger">No</span>
                )}
              </td>
              <td>{i?.vehicle?.license_number}</td>
            </tr>
          ))}
        </tbody>
        <Modal
          show={isModalVisible}
          onHide={() => setModal(!isModalVisible)}
          size={"lg"}
        >
          <Modal.Body>
            <>
              <div className="text-center mt-2 mb-3">
                <h4>Add Complaint</h4>
                <div className="text-center mt-3">
                  <CreatableSelect
                    isMulti
                    onChange={(e) => {
                      setComplaint(e)
                    }}
                    value={complaint}
                  />
                </div>
              </div>
              <div className="mb-4">
                <Col>
                  <span>Upload complaint images</span>
                  <Col xs={4} className="text-center">
                    {complaintImage.length <= 3 && (
                      <input
                        class="form-control mb-3 mt-2"
                        id="formFileSm"
                        type="file"
                        onChange={(e) => {
                          setStartImages(e)
                        }}
                      />
                    )}
                  </Col>
                  {complaintImage &&
                    complaintImage.map((i) => {
                      return (
                        <>
                          <img
                            data-dz-thumbnail=""
                            className="avatar-sm rounded bg-light"
                            alt={"Profile pic"}
                            src={i}
                          />
                          <button
                            className="btn btn-link btn-lg text-muted shadow-none"
                            onClick={() => startUrl(i)}
                          >
                            X
                          </button>
                        </>
                      )
                    })}
                </Col>
              </div>
            </>
            <div className="mb-3 text-center mt-4">
              <button
                onClick={() => {
                  createComplaint()
                }}
                className="btn btn-rounded btn-primary"
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Table>
    </>
  )
}

export default ComplaintsInTickets
