import React from "react"
import { currencyConversion } from "../../../../helpers/currency"

const RefundCardSmall = ({data}) => {
  return (
    <div className="mt-3" style={styles.root}>
      <div class="container">
        <div class="row gx-2">
          <div class="col">
            <div style={styles.card}>
              <span
                className="fs-4"
                style={{
                  color: "#7239EA",
                }}
              >
                Total Refunds
              </span>
              <span class="fs-3 text-black fw-bold">
                 OMR{" "}
                {currencyConversion(data?.totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  currency: "INR",
                })) || 0} 

              </span>
            </div>
          </div>
          <div class="col">
            <div style={styles.card}>
              <span
                className="fs-4"
                style={{
                  color: "#16C653",
                }}
              >
                Jobs
              </span>
              <span class="fs-3 text-black fw-bold">
                {data?.totalTransactions.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  currency: "INR",
                }) || 0}
              </span>
            </div>
          </div>
          <div class="col">
            <div style={styles.card}>
              <span
                className="fs-4"
                style={{
                  color: "#FF0000",
                }}
              >
                Customers Claimed
              </span>
              <span class="fs-3 text-black fw-bold">
                {data?.task?.Customer?.count?.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  currency: "INR",
                }) || 0}
              </span>
            </div>
          </div>
          <div class="col">
            <div style={styles.card}>
              <span
                className="fs-4"
                style={{
                  color: "#2989FF",
                }}
              >
                Amount Claimed
              </span>
              <span class="fs-3 text-black fw-bold">
                 OMR{" "}
                {currencyConversion(data?.task?.Customer?.amount?.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  currency: "INR",
                })) || 0} 
              
  

              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <Row style={styles.card}>
        <Col className="d-flex" style={styles.card} md={3}>
          <b className="text-center">Refunds</b>
          <b>${data?.totalAmount}</b>
        </Col>
        <Col className="d-flex" style={{...styles.card, marginLeft: 10}} md={3}>
          <b className="text-center">Refunds</b>
          <b>${data?.totalAmount}</b>
        </Col>
        <Col className="d-flex" style={{...styles.card, marginLeft: 10}} md={3}>
          <b className="text-center">Refunds</b>
          <b>${data?.totalAmount}</b>
        </Col>
      </Row> */}
    </div>
  )
}

export default RefundCardSmall

const styles = {
  root: {
    marginLeft: -6,
    marginRight: -6,
  },
  card: {
    padding: 20,
    border: "2px dashed #00000026",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
}
