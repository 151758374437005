import React, { useEffect, useReducer } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "./Calendar";
const DailyCleaningCalender = (props) => {
  return (
    <>
      <Modal show={props.isModalVisible} onHide={() => props.setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Calendar
            calenderData={props.calenderData}
            getCalenderData={props.getCalenderData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DailyCleaningCalender;
