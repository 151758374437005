import React, {memo} from "react"
import {Col} from "react-bootstrap"
import { currencyConversion } from "../../../../helpers/currency"

const RefundCard = memo(({data, dataLoading}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={styles.card}>
        <span className="font-16">Total Refunds</span>
        {dataLoading ? (
          <div className="">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <h2 style={{color: "#00000099"}} className="mt-1 mb-0">
             OMR{" "} 
            
            {currencyConversion(data?.totalAmount.toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              currency: "INR",
            })) || 0} 

{/* {currencyConversion(row?.original?.max_autodebit_amount)} */}
          </h2>
        )}
        <span className="text-muted">
          for{" "}
          {data?.totalTransactions.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            currency: "INR",
          })}{" "}
          Jobs
        </span>
      </div>
      <div
        className=""
        style={{
          marginTop: 20,
        }}
      >
        <span className="">
          Refunds are issued to customers if the partner fails to clean the car.
          If the customer submits a claim within a day, the refund is credited
          to their wallet; otherwise, it is added to the partner's wallet.
        </span>
      </div>
    </div>
  )
})

export default RefundCard

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
  },
}
