import React, {useState, useEffect} from "react"
import {Col, Form, InputGroup, Modal, Spinner, Button} from "react-bootstrap"
import {Link} from "react-router-dom"
import callApi from "../../../utils/apiCaller"
import Table from "../../../components/Table"
import swal from "sweetalert"

function AssignApartmentComponentOndemand(props) {
  const data = props.data ? props.data : {}
  const [isModalVisible, setModal] = useState(true)
  const [apartments, setApartments] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("")

  const toggle = () => {
    setModal(!isModalVisible)
    window.location.reload()
  }
  useEffect(() => {
    getLiftOfApartment()
  }, [])

  const NameColumn = ({row}) => {
    return (
      <div className="table-user">
        <Link to="#" className="text-body fw-semibold">
          {row.original.name}
        </Link>
      </div>
    )
  }

  const ActionColumn = ({row}) => {
    return (
      <>
        <div
          style={{
            cursor: "pointer",
          }}
          className="action-icon"
          onClick={() => addApartment(row)}
        >
          {" "}
          <i
            className="mdi mdi-square-edit-outline btn btn-success"
            style={{fontSize: "16px"}}
          >
            ADD
          </i>
        </div>
      </>
    )
  }

  const addApartment = (row) => {
    setIsLoading(true)
    if (row.original._id) {
      callApi(`sp/ondemand/addlocation`, "POST", {
        servicepackageId: props.data._id,
        agencyId: props.data.agencyId,
        locationId: row.original._id,
      }).then((res) => {
        if (res.success) {
          setIsLoading(false)
          setModal(false)
          props.getData(props.data._id)
          props.getApartments(props.data._id)
          props.onHide()
        } else {
          setIsLoading(false)
          setModal(false)
          swal("Error", res.message, "error")
          props.onHide()
        }
      })
    } else {
      window.location.reload()
    }
  }

  const getLiftOfApartment = () => {
    setIsLoading(true)
    if (props.data._id) {
      callApi(`sp/ondemand/getlocationallowed`, "POST", {
        servicepackageId: props.data._id,
      }).then((res) => {
        setApartments(res.data)
        setIsLoading(false)
      })
    }
  }

  const sizePerPageList = [
    {
      text: "50",
      value: 50,
    },
  ]
  const columns = [
    {
      Header: "Name",
      accessor: "carCount",
      sort: true,
      Cell: NameColumn,
    },

    {
      Header: "Action",
      accessor: "action",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ]

  const getSearchedApartment = (searchText) => {
    if (searchText) {
      callApi("apartments/getall", "POST", {
        pageNum: 1,
        pageSize: 20,
        search: searchText,
      }).then((res) => {
        if (res.data) {
          setApartments(res.data)
        }
      })
    } else {
      getLiftOfApartment()
    }
  }
  return (
    <>
      <Modal
        show={isModalVisible}
        onHide={() => {
          setModal(!isModalVisible)
          props.onHide()
        }}
        size={"xl"}
      >
        <Modal.Body>
          <div>
            <div className="text-center mt-2 mb-3">
              <h4>Assign apartment</h4>
            </div>
            <div className="ps-3 pe-3">
              <div className="ps-3 pe-3">
                <Col md={4} className="mb-2">
                  <span className="d-flex align-items-center">
                    <InputGroup
                      style={{
                        display: "flex",
                        border: "1px solid #00000050",
                        borderRadius: 4,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Form.Control
                        placeholder="Search...."
                        aria-label="Search...."
                        aria-describedby="basic-addon2"
                        style={{border: 0}}
                        onChange={(e) => {
                          setSearchInput(e.target.value)
                        }}
                        id="search"
                        value={searchInput}
                        onKeyDown={(event) =>
                          event.key === "Enter" &&
                          getSearchedApartment(searchInput)
                        }
                      ></Form.Control>
                      <div
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                          paddingLeft: 10,
                        }}
                        onClick={() => {
                          setSearchInput("")
                          getSearchedApartment(searchInput)
                        }}
                      >
                        <i className="mdi mr-1  dripicons-cross"></i>
                      </div>
                      <button
                        className="btn btn-block"
                        style={{
                          backgroundColor: "#2A882A",
                          color: "white",
                        }}
                        onClick={() => getSearchedApartment(searchInput)}
                      >
                        <i className="mdi mr-1"></i> Search
                      </button>
                    </InputGroup>
                  </span>
                </Col>
                {isLoading ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="d-block mx-auto"
                      size="lg"
                    />
                  </>
                ) : (
                  <>
                    {apartments && (
                      <Table
                        columns={columns}
                        data={apartments}
                        pageSize={50}
                        sizePerPageList={sizePerPageList}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        tableClass="table-striped"
                        searchBoxClass="mt-2 mb-3"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AssignApartmentComponentOndemand
