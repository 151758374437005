// @flow
import React, {useEffect, useState} from "react"
import {Card, Table} from "react-bootstrap"
import classNames from "classnames"
import moment from "moment"
import {Link, useNavigate} from "react-router-dom"
import _ from "lodash"

const DoorstepOtherPartnersList = (props) => {
  const [searchText, setSearchText] = useState("")

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="">{props.name}</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="w-25">
              <input
                className="form-control rounded"
                placeholder="Enter search text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props?.handleSearch(searchText)
                  }
                }}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <button
              className="btn btn-primary ms-2"
              onClick={() => {
                props?.handleSearch(searchText)
              }}
            >
              Search
            </button>
          </div>
          {props?.data && props?.data?.length > 0 && (
            <Table
              responsive
              classNames="table table-borderless table-nowrap mb-0"
            >
              <thead classNames="table-light">
                <tr>
                  <th>#</th>
                  <th>Partner</th>
                  {/* <th>City</th>
                                    <th>Locations</th>
                                    <th>Apartments</th>
                                    <th>Services</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={
                        item?._id == props?.activePartner?._id
                          ? {
                              backgroundColor: "#4BB54320",
                            }
                          : {}
                      }
                      onClick={() => {}}
                    >
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/partners/partner-profile",
                            search: `?id=${item?._id}`,
                          }}
                          className="font-weight-bold"
                          target="_blank"
                        >
                          <p className="m-0">
                            {item?.owner_name}
                            {"  "}
                            {item?._id == props?.activePartner?._id ? (
                              <span className="badge bg-success ms-1">
                                assigned
                              </span>
                            ) : null}
                          </p>
                          <small className="m-0">
                            {item?.name}
                            {"  "}
                          </small>
                          {/* {item?.role === "INDEPENDENT" ? (
                                                        <span className="fw-bold me-6 badge badge-outline-primary">
                                                            Independent
                                                        </span>
                                                    ) : null}
                                                    {item?.role === "AGENCY" ? (
                                                        <span className="fw-bold me-2 d-block badge badge-outline-primary">
                                                            Agency
                                                        </span>
                                                    ) : null} */}
                        </Link>
                      </td>
                      {/* <td>{item?.city?.name}</td>
                                            <td>
                                                {item?.location?.map((i) => {
                                                    return <span>➣ {i.name}</span>
                                                })}
                                            </td>
                                            <td>
                                                {item?.location?.map((i) => {
                                                    return <span>➣ {i.name}</span>
                                                })}
                                            </td>
                                            <td>
                                                {item?.services?.map((i) => {
                                                    return <span>➣ {i.name}<br /></span>
                                                })}
                                            </td> */}
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => props.assignPartner(item._id)}
                        >
                          Assign
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() =>
                              props.updateStatus(item._id, "rejected")
                            }
                          >
                            Reject
                          </button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default DoorstepOtherPartnersList
