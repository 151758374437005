export const AuthActionTypes = {
  API_RESPONSE_SUCCESS: "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER: "@@auth/LOGIN_USER",
  LOGOUT_USER: "@@auth/LOGOUT_USER",
  SIGNUP_USER: "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD: "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE: "@@auth/FORGOT_PASSWORD_CHANGE",

  RESET: "@@auth/RESET",

  LOGIN: "login",
  // login user
  LOGIN_USER_TOKEN: "@@auth/LOGIN_USER_TOKEN",
  LOGIN_USER_DETAILS: "@@auth/LOGIN_USER_DETAILS",
  USER_LOGGED_IN_SUCCESS: "@@auth/USER_LOGGED_IN_SUCCESS",
}
