import React, {useState, useEffect} from "react"
import {Row, Col, Card, OverlayTrigger, Modal} from "react-bootstrap"
import callApi, {IMAGE_UPLOAD_URL} from "../../../utils/apiCaller"
import CreatableSelect from "react-select/creatable"
import swal from "sweetalert"
import Calendar from "./Calendar"
import {FormInput} from "../../../components"

const ComplaintsAndNotes = (props) => {
  const data = props.data ? props.data : {}
  const notes_list = props?.data?.info?.filter((complaint) => {
    return complaint.infoType === "Notes"
  })
  const complaints_list = props?.data?.info?.filter((complaint) => {
    return complaint.infoType === "Complaint"
  })
  const priority_list = props?.data?.info?.filter((complaint) => {
    return complaint.infoType === "Priority"
  })

  const [isModalVisible, setModal] = useState(false)
  const [notes, setNotes] = useState([])
  const [showWhichModal, setWhichModal] = useState(1)
  const [show, setShow] = React.useState(false)
  const [partners, setPartners] = React.useState(null)
  const [timeSlots, setTimeSlots] = React.useState(null)
  const [notesList, setNotesList] = React.useState(notes_list || [])
  const [complaintsList, setComplaintsList] = React.useState(
    complaints_list || []
  )
  const [complaint, setComplaint] = React.useState([])
  const [complaintImage, setComplaintImage] = React.useState("")
  const handleClose = () => setShow(false)
  const [priority, setPriority] = React.useState("")
  const [priorityList, setPriorityList] = React.useState(priority_list || [])

  const sizePerPageList = [
    {
      text: "50",
      value: 50,
    },
  ]

  const getLiftOfPartner = () => {
    if (props.data._id) {
      callApi(`servicebooking/getcleaner`, "POST", {
        servicebookingId: props.data._id,
      }).then((res) => {
        setPartners(res.data)
      })
    }
  }

  const getTimeSlots = () => {
    if (data.servicepackageId._id) {
      callApi(`servicebooking/gettimeslot`, "POST", {
        servicepackageId: data.servicepackageId._id,
      }).then((res) => {
        if (res.data) {
          setTimeSlots(res.data)
        }
      })
    }
  }

  useEffect(() => {
    getLiftOfPartner()
    getTimeSlots()
  }, [])

  const createNotes = () => {
    if (data._id && notes) {
      const info = {
        serviceBookingId: data._id,
        infoBody: notes.map((item) => {
          return item.value
        }),
      }
      callApi("servicebooking/createnotes", "POST", info).then((res) => {
        if (res.success) {
          setModal(false)
          props.getData(props.data._id)
          swal("Success", "Notes created successfully", "success")
        } else {
          setModal(false)
          swal("Error", res.message, "error")
        }
      })
    }
  }

  const createComplaint = () => {
    if (data._id && complaint) {
      const info = {
        servicebookingId: data._id,
        infoBody: complaint.map((item) => {
          return item.value
        }),
        infoImages: complaintImage,
      }
      callApi("servicebooking/createcomplaint", "POST", info).then((res) => {
        if (res.success) {
          setModal(false)
          props.getData(props.data._id)
          swal("Success", "Complaint created successfully", "success")
        } else {
          setModal(false)
          swal("Error", res.message, "error")
        }
      })
    }
  }

  const updatePriority = (e) => {
    if (props.data._id && priority) {
      const info = {
        serviceBookingId: data._id,
        infoBody: priority.map((item) => {
          return item.value
        }),
      }

      callApi("servicebooking/createpriority", "POST", info).then((res) => {
        if (res.success) {
          swal("Success", "Priority updated successfully", "success")
          setModal(false)
          props.getData(props.data._id)
        } else {
          setModal(false)
          swal("Error", res.message, "error")
        }
      })
    } else {
      swal("Error", "Please select priority", "error")
    }
  }

  const setImages = async (e, type) => {
    if (e.target.files && e.target.files[0]) {
      var formdata = new FormData()
      formdata.append("file", e.target.files[0], e.target.files[0].name)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      const images = await fetch(IMAGE_UPLOAD_URL, requestOptions)
        .then((response) => response.json().then((json) => ({json, response})))
        .then(({json, response}) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          return json
        })
        .then(
          (response) => response,
          (error) => error
        )

      if (images) {
        if (type === "complaintImage") {
          setComplaintImage(images.link)
        }
      }
    }
  }

  const renderUi = () => {
    // Notes
    if (showWhichModal === 1) {
      return (
        <div className="text-center mt-2 mb-4">
          <h4>Add Notes</h4>
          <div className="text-center">
            <CreatableSelect
              isMulti
              onChange={(e) => {
                setNotes(e)
              }}
              value={notes}
            />
          </div>
        </div>
      )
    } else if (showWhichModal === 2) {
      // Complaint
      return (
        <>
          <div className="text-center mt-2 mb-3">
            <h4>Add Complaint</h4>
            <div className="text-center mt-3">
              <CreatableSelect
                isMulti
                onChange={(e) => {
                  setComplaint(e)
                }}
                value={complaint}
              />
            </div>
          </div>
          <div className="mb-4">
            <Row>
              {complaintImage == "" ? (
                <Col md={6} className="mb-3">
                  <FormInput
                    label="Complaint Image"
                    type="file"
                    name="complaintImage"
                    placeholder="Complaint Image"
                    key="complaintImage"
                    onChange={(e) => {
                      setImages(e, "complaintImage")
                    }}
                  ></FormInput>
                </Col>
              ) : (
                <div>
                  <label className="mb-1">Complaint image</label> <br />
                  <img
                    data-dz-thumbnail=""
                    className="avatar-sm rounded bg-light"
                    alt={"Profile pic"}
                    src={complaintImage}
                  />
                  <button
                    className="btn btn-link btn-lg text-muted shadow-none"
                    onClick={() => setComplaintImage("")}
                  >
                    X
                  </button>
                </div>
              )}
            </Row>
          </div>
        </>
      )
    } else if (showWhichModal === 3) {
      // Priority
      return (
        <div className="text-center mt-2 mb-4">
          <h4>Priority Notes</h4>
          <div className="text-center">
            <CreatableSelect
              isMulti
              onChange={(e) => {
                setPriority(e)
              }}
              value={priority}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <Card className="">
      <Card.Body className="">
        <Row>
          <Col xl={6}>
            <Row>
              <h4 className="mt-1">Notes</h4>
              {notes_list &&
                notes_list.length > 0 &&
                notes_list.map((note, index) => {
                  return note?.infoBody?.map((item, index) => (
                    <div>
                      <i className="mdi mdi-circle font-13 text-info me-1"></i>
                      {item}
                    </div>
                  ))
                })}
            </Row>
            <Row>
              <h4 className="mt-3">Complaints</h4>
              {complaints_list &&
                complaints_list.length > 0 &&
                complaints_list.map((note, index) => {
                  return note?.infoBody?.map((item, index) => (
                    <div>
                      <i className="mdi mdi-circle font-13 text-info me-1"></i>
                      {item}
                      {note?.infoImages &&
                        note?.infoImages.map((item, index) => (
                          <div>
                            <i className="mdi mdi-circle font-13 text-info me-1"></i>
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm rounded bg-light"
                              alt={"Profile pic"}
                              src={item}
                            />
                          </div>
                        ))}
                    </div>
                  ))
                })}
            </Row>
            <Row>
              <h4 className="mt-3">Priority</h4>
              {priority_list &&
                priority_list.length > 0 &&
                priority_list.map((note, index) => {
                  return note?.infoBody?.map((item, index) => (
                    <div>
                      <i className="mdi mdi-circle font-13 text-info me-1"></i>
                      {item}
                      {note?.infoImages &&
                        note?.infoImages.map((item, index) => (
                          <div>
                            <i className="mdi mdi-circle font-13 text-info me-1"></i>
                            <img
                              data-dz-thumbnail=""
                              className="avatar-sm rounded bg-light"
                              alt={"Profile pic"}
                              src={item}
                            />
                          </div>
                        ))}
                    </div>
                  ))
                })}
            </Row>
          </Col>

          <Col>
            <Row>
              <Col className="text-end">
                <button
                  className="btn btn-primary btn-sm mr-2 me-2"
                  onClick={() => {
                    setModal(true)
                    setWhichModal(1)
                  }}
                >
                  <i className="mdi mdi-note-outline mr-1"></i> Add Notes
                </button>
                <button
                  className="btn btn-primary btn-sm mr-2 me-2"
                  onClick={() => {
                    setModal(true)
                    setWhichModal(2)
                  }}
                >
                  <i className="mdi mdi-note-outline mr-1"></i> Add Complaint
                </button>
                {priority_list && !priority_list.length > 0 && (
                  <button
                    className="btn btn-primary btn-sm mr-2 "
                    onClick={() => {
                      setModal(true)
                      setWhichModal(3)
                    }}
                  >
                    <i className="mdi mdi-note-outline mr-1"></i> Add Priority
                  </button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          show={isModalVisible}
          onHide={() => setModal(!isModalVisible)}
          size={"lg"}
        >
          <Modal.Body>
            {renderUi()}
            <div className="mb-3 text-center mt-4">
              <button
                onClick={() => {
                  if (showWhichModal === 1) {
                    createNotes()
                  } else if (showWhichModal === 2) {
                    createComplaint()
                  } else if (showWhichModal === 3) {
                    updatePriority()
                  }
                }}
                className="btn btn-rounded btn-primary"
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agenda</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Calendar />
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  )
}

export default ComplaintsAndNotes
