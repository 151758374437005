// @flow
import React, {useState} from "react"
import {Row, Col, Card, Spinner, Alert, InputGroup, Form,Modal,Button} from "react-bootstrap"
import classNames from "classnames"
import {Link} from "react-router-dom"
import PageTitle from "../../components/PageTitle"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import ReactPaginate from "react-paginate"
import callApi from "../../utils/apiCaller"
import { IoPersonSharp } from "react-icons/io5";
import { debounce } from 'lodash';
import swal from "sweetalert"
import Swal from "sweetalert2"
import {
  setAllPackagesPageCount,
  setFilterPartnerData,
  setAllpackagesPageSize,
} from "../../redux/appState/appActions"
import Table from "../../components/Table"
import {useEffect} from "react"
import _ from "lodash"
// import { BsPersonCircle } from "react-icons/bs"
// import { FiFilter } from "react-icons/fi"
import { BiPlus } from "react-icons/bi"

const DailyCleaningMasterPackages = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = React.useState([])
  const [pageCount, setpageCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState({})
  const [markabsentpartnerdata,setMarkAbsentPartnerData]=useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchInput, setSearchInput] = useState("")
  const [loading, setLoading] = useState(false)
  const {allPackagesPageCount} = useSelector((state) => ({
    allPackagesPageCount: state.AppState.allPackagesPageCount,
  }))
  const {allPackagesPageSize} = useSelector((state) => ({
    allPackagesPageSize: state.AppState.allPackagesPageSize,
  }))
  const partnerFilterData = useSelector(
    (state) => state?.AppState?.filterPartnerData
  )
  const [totalCount, setTotalCount] = useState(0)
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    let reqData = {}
    if (partnerFilterData && !_.isEmpty(partnerFilterData)) {
      if (partnerFilterData?.cityId) {
        reqData.cityId = [partnerFilterData?.cityId]
      }
      if (partnerFilterData?.apartments) {
        reqData.servicePlaceNameId = partnerFilterData?.apartments?.map(
          (item) => {
            return item._id
          }
        )
      }
      if (partnerFilterData?.location) {
        reqData.locationId = partnerFilterData?.location?.map((i) => {
          return i._id
        })
      }
      if (partnerFilterData?.status) {
        reqData.status = partnerFilterData?.status?.value
      }
      if (!_.isEmpty(partnerFilterData?.gender)) {
        reqData.gender = partnerFilterData?.gender?.value
          
        
      }
      if (partnerFilterData?.selectService) {
        reqData.serviceTypeId = partnerFilterData?.selectService?.map((i) => {
          return i._id
        })
      }
      if (partnerFilterData?.search) {
        reqData.search = partnerFilterData?.search
        setSearchInput(partnerFilterData?.search)
      }
    }
    setFilters(reqData)
    fetchData(allPackagesPageCount, reqData)
  }, [loading,allPackagesPageSize,allPackagesPageCount])
// useEffect(()=>
// {
//     fetchData()
// },[allPackagesPageCount,allPackagesPageSize])

// useEffect(() => {
//   if (searchInput) {
//     const debounceSearch = debounce(fetchData, 500);
//     dispatch(setAllPackagesPageCount(1));
//     debounceSearch();
//     return () => {
//       debounceSearch.cancel();
//     };
//   } else {
//     fetchData();
//   }
// }, [allPackagesPageCount,allPackagesPageSize]);

  const fetchData = (filters,currentPage) => {
    let info = {
      pageNum: allPackagesPageCount,
      pageSize: allPackagesPageSize,
      search:searchInput,
      packageType:"cleaning"
    }

   
    // if (filterType?.search) {
    //   let filters_all = filters
    //   filters_all.search = filterType?.search
    //   setFilters(filters_all)
    //   info.search = filterType.search.trim()
    // }


    //  setIsLoading(true)
    // dispatch(setAllPackagesPageCount(currentPage))
    callApi("masterpackage/getall", "POST", info).then((res) => {
      if (res.data) {
        console.log(res?.data?.data?.length)
        setData(res?.data?.data)
        console.log(data)
        setTotalCount(res?.data?.count)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res?.message || 'Something went wrong!',
        });
      }
    })
  }

 

  const removeFilters = () => {
    dispatch(setFilterPartnerData({}))
    // setShowFilter(false)
    dispatch(setAllPackagesPageCount(1))
    fetchData(1)
  }

  const NameColumn = ({row}) => {
    return (
      <>
        <div
          className="table-user"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
         
          <Link
            to="#"
            className="ms-2"
            // onClick={(event) => {
            //   event.preventDefault()
            //   navigate({
            //     pathname: "/partners/partner-profile",
            //     search: `?id=${row.original._id}`,
            //   })
            // }}
          >
            <p className="m-0">{row?.original?.name}</p>
         
            {/* <span
        className={classNames("badge", {
          "badge-danger-lighten": row.original.role === "INDEPENDENT",
        })}
      >
        {row.original.role}
      </span> */}
            {/* <p className="m-0">{row?.original?.role}</p> */}
           
          </Link>
        
        </div>
      </>
    )
  }
  const agencynamecolumn = ({row}) => {
    return <div className="table-user">{row.original?.agencyId?.name}</div>
  }
  const AgencyColumn = ({row}) => {
    return (
      <div className="table-user">
        <span className="fw-bold me-2 d-block badge badge-outline-primary">
          {row.original.role === "AGENCY_OWNER" ? "Agency" : "Independent"}
        </span>
      </div>
    )
  }
  const StatusColumn = ({row}) => {
    return (
      <>
        <span
          className={classNames("badge", {
            "badge-success-lighten": row.original.accountStatus === "NEW",
            "badge-info-lighten": row.original.accountStatus === "ACTIVE",
            "badge-danger-lighten": row.original.accountStatus === "INACTIVE",
          })}
        >
          {row.original.accountStatus}
        </span>
      </>
    )
  }
  const ActionColumn = ({row}) => {
    
    return (
      <>
        <Link
          to={{
            pathname: "/service-packages/package-details",
            search: `?id=${row?.original?._id}`,
          }}
          state={{
            packageId: row?.original?._id,
            code:row?.original?.code,
            service_type:row?.original?.service_type,
            description:row?.original?.description,
            products_used:row?.original?.products_used,
            duration:row?.original?.duration,
            vehicle_type_prices:row?.original?.vehicle_type_prices,
            workingDays:row?.original?.workingDays,
            created_at:row?.original?.created_at,
            vehicle_types:row?.orioginal?.vehicle_types,
            isPrepaidAllowed:row?.original?.isPrepaidAllowed,
            name:row?.original?.name,
            service_name :row?.original?.service_name,
            isTrialAttached: row?.original?.isTrialAttached,
            greatFor:row?.original?.greatFor

           

          }}
          className="action-icon"
        >
          {" "}
          <i className="mdi mdi-eye"></i>
        </Link>
      </>
    )
  }
  // const MarkPartnerAbsent = ({ name, phone, partnerId, salaried, onClose }) => {
  //   const [textareaValue, setTextareaValue] = useState("");
  //   const [penaltyAmount, setPenaltyAmount] = useState("");
  //   const [penaltyError, setPenaltyError] = useState(""); 
  
  //   const handleTextareaChange = (event) => {
  //     setTextareaValue(event.target.value);
  //   };
  
  //   const handlePenaltyChange = (event) => {
  //     const value = event.target.value;
  //     const numericValue = value.replace(/\D/g, ''); 
  //     setPenaltyAmount(numericValue);
      
    
  //     if (numericValue.trim() === "") {
  //       setPenaltyError("Please enter a penalty amount.");
  //     } else {
  //       setPenaltyError("");
  //     }
  //   };
    
  
  //   const handlePartnerAbsentClick = () => {
  //     if (penaltyAmount.trim() === "") {
  //       setPenaltyError("Please enter an amount.");
  //       return;
  //     }
  //     setIsLoading(true)
  //     onClose();
  //     swal({
  //       title: "Are you sure?",
  //       text: `Are you ready to mark ${name} as absent with a penalty of OMR${penaltyAmount}?`,
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: "Yes",
  //           value: true,
  //           visible: true,
  //           className: "bg-success text-white",
  //           closeModal: false,
  //         },
  //         cancel: {
  //           text: "No",
  //           value: false,
  //           visible: true,
  //           className: "bg-danger text-white",
  //           closeModal: true,
  //         }
        
  //       },
  //       dangerMode: true,
  //     })
  //     .then((willMarkAbsent) => {
  //       if (willMarkAbsent) {
  //         const info = {
  //           partnerId: partnerId,
  //           penalty: parseInt(penaltyAmount),
  //           taskIncompleteReason: textareaValue
  //         };
  //         callApi("partner/markabsent", "POST", info).then((res) => {
  //           if (res.success) {
  //             swal({
  //               icon: 'success',
  //               title: 'Success!',
  //               text: `You have successfully marked ${name} as absent with a penalty of OMR${penaltyAmount}.`,
  //               timer: 3000
  //             }).then(() => {
  //               fetchData(allPackagesPageCount, filters);
  //               setLoading(false);
  //             });
  //           } else {
  //             setLoading(false);
  //             swal("Error", "Something went wrong", "error");
  //           }
  //         });
  //       }
  //     });
  //   };
    
  
  //   return (
  //     <Modal show={true} onHide={onClose}>
  //       <Modal.Header closeButton>
  //         <Modal.Title>Partner Details</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <div className="row align-items-center">
  //           <Row>
  //             <Col lg={2} md={2} sm={2} xs={2}>
  //               <p className="m-0"><strong>Name:</strong></p>
  //             </Col>
  //             <Col lg={9} md={9} sm={9} xs={9}>
  //               <p className="m-0">{name}</p>
  //             </Col>
  //           </Row>
  //           <Row className="mt-2">
  //             <Col lg={2} md={2} sm={2} xs={2}>
  //               <p className="m-0"><strong>Phone:</strong></p>
  //             </Col>
  //             <Col lg={9} md={9} sm={9} xs={9}>
  //               <p className="m-0">{phone}</p>
  //             </Col>
  //           </Row>
  //           <Row className="mt-2">
  //             <Col lg={2} md={2} sm={2} xs={2}>
  //               <p className="m-0"><strong>Salaried:</strong></p>
  //             </Col>
  //             <Col lg={9} md={9} sm={9} xs={9}>
  //               <p className="m-0">{salaried}</p>
  //             </Col>
  //           </Row>
  //           <Row className="mt-2">
  //             <Col lg={2} md={2} sm={2} xs={2}>
  //               <p className="m-0"><strong>Penalty:</strong></p>
  //             </Col>
  //             <Col lg={9} md={9} sm={9} xs={9}>
  //               <div className="input-group">
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   placeholder="Enter amount"
  //                   value={penaltyAmount}
  //                   onChange={handlePenaltyChange}
  //                 />
  //                 <span className="input-group-text">OMR</span>
  //               </div>
  //               {penaltyError && <small className="text-danger">{penaltyError}</small>}
  //             </Col>
  //           </Row>
  //           <Row className="mt-2">
  //             <Col lg={2} md={2} sm={2} xs={2}>
  //               <span><strong>Reason:</strong></span>
  //             </Col>
  //             <Col lg={9} md={9} sm={9} xs={9}>
  //               <textarea
  //                 className="form-control"
  //                 rows="4"
  //                 placeholder="Enter here"
  //                 value={textareaValue}
  //                 onChange={handleTextareaChange}
  //               />
  //             </Col>
  //           </Row>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="primary" onClick={handlePartnerAbsentClick}>
  //           Submit
  //         </Button>
  //         <Button variant="secondary" onClick={onClose}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
  
  
  
  const CityColumn = ({row}) => {
    return <div className="table-user">{row.original?.city?.name}</div>
  }
  const LocationColumn = ({row}) => {
    return (
      <div
        className={`${
          row?.original?.servicePlaceNameIds?.length > 0
        }`}
      >
        {row?.original?.location?.map((i) => (
          <div className="table-user">{i.name}</div>
        ))}
      </div>
    )
  }

  const ApartmentColumn = ({row}) => {
    return (
      // <div
      //   className={`${
      //     row?.original?.servicePlaceNameIds?.length > 0 && "border-bottom"
      //   }`}
        <div
        className={`${
          row?.original?.servicePlaceNameIds?.length > 0 
        }`}
      >
        {row?.original?.servicePlaceNameIds?.map((i) => (
          <div className="table-user">{i.name}</div>
        ))}
      </div>
    )
  }

  const ProfileColumn = ({row}) => {
    return (
      <div className="table-user">
        {row.original.profileImage ? (
          <>
            <img
              src={row.original.profileImage}
              alt={
                <i className="mdi mdi-account-circle font-size-24 text-primary"></i>
              }
              className="me-2 rounded-circle"
            />
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="mdi mdi-account-circle"></i>
            </div>
          </>
        )}
      </div>
    )
  }

  const allotmentsCol = ({row}) => {
    return (
      <div>
        {!row.original?.city || row.original?.city == "" ? (
          <p className="m-0">--NA--</p>
        ) : (
          <div>
         
            {LocationColumn({row})}
            {CityColumn({row})}
          </div>
        )}
      </div>
    )
  }
  const polygonCol = ({row}) => {
    return (
      <div>
        {!row.original?.city || row.original?.city == "" ? (
          <p className="m-0">--NA--</p>
        ) : (
          <div>
            {ApartmentColumn({row})}
          
          </div>
        )}
      </div>
    )
  }

  const servicesColumn = ({row}) => {
    return (
      <div>
      
        <p>{row.original.service_type}</p>
      </div>
    )
  }

  const columns = [
    {
      Header: "SNo",
      accessor: "serialNo",
      Cell: ({row}) => {
        return (
          <span>
            {allPackagesPageSize * (allPackagesPageCount - 1) + row.index + 1}
          </span>
        )
      },
      width: "20px",
    },
    {
      Header: " Package Name",
      accessor: "name",
      Cell: NameColumn,
      classes: "table-user",
    },
    // {
    //   Header: "Business name",
    //   accessor: "agencyname",
    //   Cell: agencynamecolumn,
    //   classes: "table-user",
    // },
 
    // {
    //   Header: "Agency type",
    //   accessor: "role",
    //   sort: true,
    //   Cell: AgencyColumn,
    //   classes: "table-user",
    // },
   

    {
      Header: "Action",
      accessor: "Action",
      Cell: ActionColumn,
    },
  ]

  const handlePageClick = async (data) => {
    let locCurrentPage = data.selected;
    locCurrentPage = locCurrentPage + 1;
    dispatch(setAllPackagesPageCount(locCurrentPage));
  
    setIsLoading(true); // Set loading state to true when starting to fetch data
  
    fetchData(locCurrentPage, { ...filters, search: searchInput }).then(() => {
      setIsLoading(false); // Set loading state to false when data is fetched
    });
  };
  

  // const optionHandler = (e) => {
  //   setLimit(e.target.value)
  // }
  const optionHandler = (e) => {
    const selectedLimit = parseInt(e.target.value, 10)
    dispatch(setAllpackagesPageSize(selectedLimit))
    fetchData(1, {...filters, pageSize: selectedLimit,search:searchInput})
  }

  

  const applyAllFilters = (filterObject) => {
    setFilters(filterObject)
    dispatch(setAllPackagesPageCount(1))
    // fetchData(1, {...filterObject,search:searchInput})
  }

  return (
    <>
      <div style={{marginLeft: 26}}>
        <PageTitle
          breadCrumbItems={[
            {label: "eCommerce", path: "/apps/ecommerce/customers"},
            {
              label: "Customers",
              path: "/apps/ecommerce/customers",
              active: true,
            },
          ]}
          title={"Daily Cleaning Packages"}
        />
      </div>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Col className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="d-flex">
                    <span className="d-flex align-items-center">
                      <InputGroup
                        style={{
                          display: "flex",
                          border: "1px solid #00000050",
                          borderRadius: 4,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Control
                          placeholder="Search...."
                          aria-label="Search...."
                          aria-describedby="basic-addon2"
                          style={{border: 0}}
                          onChange={(e) => {
                            setSearchInput(e.target.value)
                            dispatch(
                              setFilterPartnerData({
                                ...partnerFilterData,
                                search: e.target.value,
                              })
                            )
                          }}
                          id="search"
                          value={searchInput}
                          onKeyDown={(event) =>
                            event.key === "Enter" &&
                            fetchData(1, {...filters,search: searchInput})
                          }
                        ></Form.Control>
                        <div
                          style={{
                            cursor: "pointer",
                            marginRight: 10,
                            paddingLeft: 10,
                          }}
                          onClick={() => {
                            
                            setLoading(!loading)
                            fetchData(1, {
                              ...filters,
                              search: "",
                            })
                            setSearchInput("")
                            dispatch(
                              setFilterPartnerData({
                                ...partnerFilterData,
                                search: "",
                              })
                            )
                          }}
                        >
                          <i className="mdi mr-1  dripicons-cross"></i>
                        </div>
                        <button
                          className="btn btn-block"
                          style={{backgroundColor: "#2A882A", color: "white"}}
                          onClick={() =>
                            fetchData(1, {
                              ...filters,
                              search: searchInput,
                            })
                          }
                          
                        >
                          <i className="mdi mr-1"></i> Search
                        </button>
                      </InputGroup>
                    </span>
                    {/* <div className="ms-2">
                    {partnerFilterData?.showFilter ? (
                    <Alert
                      variant="warning"
                      onClose={() => removeFilters()}
                      dismissible
                      className=""
                    >
                      Remove Filter
                    </Alert>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={
                        // () => setShowFilter(true)
                        () =>
                          dispatch(
                            setFilterPartnerData({
                              ...partnerFilterData,
                              showFilter: true,
                            })
                          )
                      }
                    >
                    <FiFilter/>  Add Filter
                    </button>
                  )}
                  </div> */}
                  </div>
                  <div className="d-flex align-items-center">
                  <Link to={{ pathname : "/service-packages/create-service-package"}} 
                  state ={{  data:data}}>
  <Button variant="success" className="me-1"> <BiPlus style={{ fontSize: '18px' }}/> Create Daily Cleaning Package</Button>
</Link>



              
                  </div>
                </div>
                <div className="mt-1 mx-1">
                  {/* <strong> Count : {totalCount ? totalCount : 0}</strong> */}
                </div>
                {/* {partnerFilterData?.showFilter && (
                  <div>
                    <PartnersFilterComponent
                      applyAllFilters={applyAllFilters}
                    />
                  </div>
                )} */}
              </Col>
              {isLoading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="d-block mx-auto"
                  size="lg"
                />
              ) : (
                <>
                  {data.length > 0 ? (
                    <>
                      {data.length && (
                        <>
                          <Table
                            columns={columns}
                            data={data}
                            pageSize={allPackagesPageSize}
                            sizePerPageList={[{text: "20", value: 20}]}
                            isSortable
                            theadClass="table-light"
                            searchBoxClass="mt-2 mb-3"
                            onClick={(row) => {
                              navigate({
                                pathname: "/partners/partner-profile",
                                search: `?id=${row?._id}`,
                              })
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner
                        animation="border"
                        variant="primary"
                        className="d-block mx-auto"
                        size="lg"
                      />
                    </>
                  )}
                  <Row>
                    <Col lg={12} className="mt-3">
                      <Row>
                        {/* <Col lg={6}>
                          <div className="d-lg-flex align-items-center text-center pb-1">
                            <label className="me-1">Display :</label>
                            <select
                              onChange={(e) => {
                                optionHandler(e)
                              }}
                              className="form-select d-inline-block w-auto"
                            >
                              <option value={50}>50</option>
                            </select>
                          </div>
                        </Col> */}
                        <Col lg={6}>
                          <div className="d-lg-flex align-items-center text-center pb-1">
                            <label className="me-1">Display :</label>
                            <select
                              onChange={(e) => {
                                optionHandler(e)
                              }}
                              className="form-select d-inline-block w-auto"
                              value={allPackagesPageSize} // Set the selected value based on the 'limit' state
                            >
                              {/* Options for the dropdown */}
                              {[10, 20, 50, 100].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>

                         <Col lg={6}>
                          <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel="..."
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            onPageChange={(data) => {
                              handlePageClick(data)
                            }}
                            forcePage={allPackagesPageCount - 1}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                          />
                        </Col> 
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
     
    </>
  )
}

export default DailyCleaningMasterPackages


