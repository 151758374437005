import _ from "lodash";
import React from "react";
import { Row, Col, Card } from "react-bootstrap";

const PackageTimeSlots = (props) => {
  const data = props.data;
  return (
    <Col className="border-bottom">
      <Row>
        <Col xl={6}>
          <div>
            <h4 className="font-15">Timeslots :</h4>
            {data?.timeslot?.map((i, index) => (
              <p key={index}>
                <i className="mdi "></i>➣ {i.display_name}
                <span className="float-end"> {i.name}</span>
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default PackageTimeSlots;
