// @flow
import React from "react"
import {Table} from "react-bootstrap"
import classNames from "classnames"
import moment from "moment"
import {Link, useNavigate} from "react-router-dom"

const PreviousTicketsTable = (props) => {
  const list = props?.data
  const navigate = useNavigate()

  const StatusColumn = (status) => {
    return (
      <>
        <span
          className={
            status === "OPEN" ? "bg-success badge" : "bg-secondary badge"
          }
        >
          {status === "CLOSE" ? "Closed" : "Open"}
        </span>
      </>
    )
  }

  return (
    <>
      <h4>All Tickets</h4>
      <Table responsive classNames="table table-borderless table-nowrap mb-0">
        <thead classNames="table-light">
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Ticket Id</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Issue Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {list?.map((project, index) => {
            return (
              <tr
                key={index}
                style={{
                  cursor: "pointer",
                  color:
                    props?.ticketData?._id === project._id ? "#00000040" : "",
                }}
                onClick={() => props.getTicketData(project._id)}
              >
                <td>{index + 1}</td>
                <td>{moment(project?.date).utc().utcOffset(240).format("DD-MM-YY (h:mm a)")}</td>
                <td>
                  {project._id?.slice(project._id.length - 8).toUpperCase()}
                </td>
                <td>{project?.name}</td>
                <td>{project?.phone}</td>
                <td>{project?.issueType}</td>
                <td>{StatusColumn(project.status)}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default PreviousTicketsTable
